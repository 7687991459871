import React, { useCallback, useEffect, useState } from "react";
import { Col, Divider, Input, Row, Skeleton, Space } from "antd";
import Appbar from "../../../Components/Appbar/Appbar";
import MyCard from "../../../Components/Card/MyCard";
import { VendorBreadCrumb } from "./Constants";
import MyTable from "../../../Components/Table/MyTable";
import VendorModal from "./Components/VendorModal";
import { useDispatch, useSelector } from "react-redux";
import { MODULE, OPERATION, PAGING } from "../../../Utils/Constants";
import { debounce, isValidElement } from "../../../Utils/helpers";
import { branchIdState, userState } from "../../../store/selectors";
import { crudBasedAuth } from "../../../Utils/Authorization";
import { deleteConfirmationModal } from "../../../Components/ConfirmModal/ConfirmModal";
import { COMMON_STRINGS } from "../../../Utils/Strings";

type Props = {};

function Vendor(props: Props) {
  let userSelector = userState();
  let branchIdSelector = branchIdState();
  let [userPermissionSelector] =
    isValidElement(userSelector) &&
    userSelector?.users_role?.users_permissions.filter(
      (permission) =>
        permission.moduleName === MODULE.INVENTORY &&
        permission.branchId === branchIdSelector
    );

  const dispatch = useDispatch();

  const { isLoading, vendors, total } = useSelector((state: any) => ({
    isLoading: state.vendors.isLoading,
    vendors: state.vendors.vendors,
    total: state.vendors.total,
  }));
  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [editForm, setEditForm] = useState(null);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (filter.length) {
      dispatch.vendors.getVendorsByPage({ page, limit, filter });
    } else {
      dispatch.vendors.getVendorsByPage({ page, limit });
    }
  }, [page, limit, filter]);

  const pageHandler = (page, pageSize) => {
    setPage(page);
    setLimit(pageSize);
  };
  const toggleModal = useCallback(() => {
    setModalVisible(!modalVisible);
  }, [modalVisible]);

  const editHandler = useCallback(
    (record) => {
      setModalVisible(true);
      setEditForm(record);
    },
    [modalVisible, editForm]
  );

  const deleteHandler = (record) => {
    deleteConfirmationModal({
      title: COMMON_STRINGS.MODEL_MODULE.SUPPLIER,
      content: COMMON_STRINGS.CRUD_MESSAGE.DELETE_CONFIRM,
      onOkHandler: () =>
        dispatch.vendors.deleteVendor({ data: record, page, limit }),
      onCancelHandler: () => {},
    });
  };

  const resetEditForm = () => {
    setEditForm(null);
  };

  const debouncedOnSearchText = useCallback(
    debounce((searchText) => {
      if (page !== PAGING.page) {
        setPage(PAGING.page);
        setLimit(PAGING.limit);
      }
      setFilter(searchText);
    }, 800),
    [page, limit]
  );

  const filterVendor = () => {
    return (
      <div>
        <h3>Filter</h3>
        <Space split={<Divider type="vertical" />}>
          <Input
            key="input-vendor"
            allowClear
            style={{ width: 200, marginRight: 20 }}
            placeholder="Search"
            onChange={(e) => debouncedOnSearchText(e.target.value)}
          />
        </Space>
      </div>
    );
  };

  const columns = [
    {
      title: "Supplier Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Contact",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space>
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.PUT
            ) && <a onClick={() => editHandler(record)}>Edit</a>}
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.DELETE
            ) && <a onClick={() => deleteHandler(record)}>Delete</a>}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <MyCard>
        <Appbar
          title="Vendor"
          onButtonHandler={toggleModal}
          breadcrumbs={VendorBreadCrumb}
        />
        {filterVendor()}
        <br />
        <Row>
          <Col span={24}>
            <Skeleton loading={false}>
              <MyTable
                rows={vendors}
                columns={columns}
                pagination={{
                  total,
                  current: page,
                  pageSize: limit,
                  onChange: pageHandler,
                }}
              />
            </Skeleton>
          </Col>
        </Row>
      </MyCard>
      {modalVisible && (
        <VendorModal
          visible={modalVisible}
          toggleModal={toggleModal}
          editForm={editForm}
          resetEditForm={resetEditForm}
          page={page}
          limit={limit}
        />
      )}
    </>
  );
}

export default Vendor;
