import { VendorsNetwork } from "../../../API/Networks"
import { errorToastr, successToastr } from "../../../Components/Toastr/MyToastr"
import { isValidString, jwtExpired } from "../../../Utils/helpers"
import { COMMON_STRINGS } from "../../../Utils/Strings"

const vendorModel = {
    state: {
        isLoading: false,
        crudLoading: false,
        error: null,
        vendors: null,
        vendorsFilter: null,
        total: 0
    },
    reducers: {
        loading: (state, { isLoading }) => {
            return { ...state, isLoading }
        },
        crudLoading: (state, { isLoading }) => {
            return { ...state, crudLoading: isLoading }
        },
        vendorsFilter: (state, { vendors }) => {
            return { ...state, vendorsFilter: vendors, isLoading: false, error: null, crudLoading: false, }
        },
        vendors: (state, { vendors, total }) => {
            return { ...state, vendors, total, isLoading: false, error: null, crudLoading: false, }
        },
        error: (state, { error }) => {
            return { ...state, vendors: null, vendorsFilter: null, total: 0, isLoading: false, crudLoading: false, error: error.message }
        }
    },
    effects: (dispatch) => ({
        getVendorsByPage: async ({ page, limit, filter }, state) => {
            try {
                let { branchId } = state.login;
                dispatch.vendors.loading({ isLoading: true });
                const response = await VendorsNetwork.getVendorsByPage(page, limit, branchId, filter);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.SUPPLIER,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                }
                const { rows: vendors, count: total } = response.data.data
                dispatch.vendors.vendors({ vendors, total });
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.vendors.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.PRODUCTS, error.response.data.message, 2)
            }
        },
        filterVendor: async ({ filter }, state) => {
            try {
                let { branchId } = state.login;
                let response;
                dispatch.vendors.loading({ isLoading: true });
                if (isValidString(filter) && filter.length > 0) {
                    response = await VendorsNetwork.filterVendors(filter, branchId);
                    if (response.status !== 200) {
                        return errorToastr(
                            COMMON_STRINGS.MODEL_MODULE.SUPPLIER,
                            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                            2)
                    };
                    return dispatch.vendors.vendorsFilter({ vendors: response.data.data })
                } else {
                    response = await VendorsNetwork.getVendorsByPage(1, 20, branchId);
                    if (response.status !== 200) {
                        return errorToastr(
                            COMMON_STRINGS.MODEL_MODULE.SUPPLIER,
                            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                            2)
                    }
                    return dispatch.vendors.vendorsFilter({ vendors: response.data.data.rows })
                }
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.vendors.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.PRODUCTS, error.response.data.message, 2)
            }
        },
        addVendor: async ({ data, page, limit }, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                dispatch.vendors.crudLoading({ isLoading: true });
                const response = await VendorsNetwork.addVendor(data);
                if (response.status != 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.SUPPLIER,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                dispatch.vendors.getVendorsByPage({ page, limit })
                return successToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLIER, COMMON_STRINGS.CRUD_MESSAGE.ADD_DATA("Supplier"), 2)
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.vendors.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLIER, error.response.data.message, 2)
            }
        },
        updateVendor: async ({ data, page, limit }, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                dispatch.vendors.crudLoading({ isLoading: true })
                const response = await VendorsNetwork.updateVendor(data);
                if (response.status != 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.SUPPLIER,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                dispatch.vendors.getVendorsByPage({ page, limit })
                return successToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLIER, COMMON_STRINGS.CRUD_MESSAGE.UPDATE_DATA("Supplier"), 2)
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.vendors.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLIER, error.response.data.message, 2)
            }
        },
        deleteVendor: async ({ data, page, limit }, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                const response = await VendorsNetwork.deleteVendor(data);
                if (response.status != 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.SUPPLIER,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                dispatch.vendors.getVendorsByPage({ page, limit });
                return successToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLIER, COMMON_STRINGS.CRUD_MESSAGE.DELETE_DATA("Supplier"), 2)

            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.products.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLIER, error?.response?.data.message, 2)
            }
        }
    })
};

export default vendorModel