import { SubStoreNetwork, SupplyNetwork } from "../../API/Networks";
import { errorToastr, successToastr } from "../../Components/Toastr/MyToastr";
import { jwtExpired } from "../../Utils/helpers";
import { COMMON_STRINGS } from "../../Utils/Strings";

const supplyModel = {
    state: {
        isLoading: false,
        crudLoading: false,
        error: null,
        supply: null,
        total: 0
    },
    reducers: {
        loading: (state, { isLoading }) => {
            return { ...state, isLoading }
        },
        crudLoading: (state, { isLoading }) => {
            return { ...state, crudLoading: isLoading }
        },
        supply: (state, { supply, total }) => {
            return { ...state, supply, total, isLoading: false, error: null, crudLoading: false, }
        },
        error: (state, { error }) => {
            return { ...state, supply: null, total: 0, isLoading: false, crudLoading: false, error: error.message }
        }
    },
    effects: (dispatch) => ({
        getSupplyByPage: async ({ page, limit }, state) => {
            try {
                let { branchId } = state.login;
                dispatch.supply.loading({ isLoading: true });
                const response = await SupplyNetwork.getAllSupplyByPage(page, limit, branchId);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.SUPPLY,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                }
                const { rows: supply, count: total } = response.data.data
                dispatch.supply.supply({ supply, total });
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.supply.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLY, error.response.data.message, 2)
            }
        },
        getSupplyById: async ({ id }, state) => {
            try {
                let { branchId } = state.login;
                dispatch.supply.loading({ isLoading: true });
                const response = await SupplyNetwork.getSupplyById(id, branchId);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.SUPPLY,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                dispatch.supply.loading({ isLoading: false });
                return response.data.data;
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.supply.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLY, error?.response?.data?.message, 2)
            }
        },
        addSupply: async ({ data }, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                dispatch.supply.crudLoading({ isLoading: true });
                const response = await SupplyNetwork.addSupply(data);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.SUPPLY,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                dispatch.supply.crudLoading({ isLoading: false });
                return successToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLY, COMMON_STRINGS.CRUD_MESSAGE.ADD_DATA("Supply"), 2);
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.supply.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLY, error.response.data.message, 2)
            }
        },
        updateSupply: async ({ data }, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                dispatch.supply.crudLoading({ isLoading: true });
                const response = await SupplyNetwork.updateSupply(data);
                dispatch.supply.crudLoading({ isLoading: false });
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.SUPPLY,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                return successToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLY, COMMON_STRINGS.CRUD_MESSAGE.UPDATE_DATA("Supply"), 2);
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.supply.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLY, error.response.data.message, 2)
            }
        },
        deleteSupplyTransaction: async (data, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                dispatch.supply.crudLoading({ isLoading: true });
                const response = await SupplyNetwork.deleteTransactionById(data);
                if (response.status !== 200) {
                    dispatch.supply.crudLoading({ isLoading: false });
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.SUPPLY,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                dispatch.supply.crudLoading({ isLoading: false });
                return successToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLY, COMMON_STRINGS.CRUD_MESSAGE.DELETE_DATA("Supply"), 2)
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.supply.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLY, error?.response?.data?.message, 2)
            }
        },
        deleteSupply: async ({ data, page, limit }, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                const response = await SupplyNetwork.deleteSupplyById(data);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.SUPPLY,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                dispatch.supply.getSupplyByPage({ page, limit });
                return successToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLY, COMMON_STRINGS.CRUD_MESSAGE.DELETE_DATA("Supply"), 2)
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.supply.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLY, error.response.data.message, 2)
            }
        },
        getSubStorePurchaseBySupply: async (id, state) => {
            try {
                let response: any = await SubStoreNetwork.getSubStorePurchaseBySupply(id);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.SUPPLY,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                return response.data.data
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.supply.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.SUPPLY, error.response.data.message, 2)
            }
        }
    })
};

export default supplyModel;