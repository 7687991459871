import { routes } from "../../Container/Navigation/Constants";

export const UsersRolesBreadCrumb = [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: "Users Role", path: routes.USERS_ROLES_ROUTE }
];

export const UsersPermissionsBreadCrumb = [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: "Users Role", path: routes.USERS_ROLES_ROUTE },
    { label: "Users Permissions", path: routes.USERS_PERMISSIONS_ROUTE }
];

export const USER_ROLES = [
    {id: 1, label: 'Viewer', value:'viewer'},
    {id: 2, label: 'Editor', value:'editor'},
    {id: 3, label: 'Manager', value:'manager'},
    {id: 4, label: 'Admin', value:'admin'},
    {id: 5, label: 'Super', value:'super'},
];

export const DEPARTMENT_LIST = [
    {id: 1, label: 'Medical', value:'medical'},
    {id: 2, label: 'Surgical', value:'surgical'},
    {id: 3, label: 'Sub Surgical Store', value:'sub_surgical_store'}
]