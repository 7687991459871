import { routes } from "../../../Container/Navigation/Constants";

export const OgrBreadCrumb = [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: "TNMSC", path: routes.OGR_ROUTE }
];

export const OgrNewBreadCrumb = (isEdit) => [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: "TNMSC", path: routes.OGR_ROUTE },
    { label: isEdit ? "Update Stock" : "New Stock", path: !isEdit && routes.ADD_OGR_ROUTE }
];