export const routes = {
    DASHBOARD: '/',
    APP_DECIDER: "/app-decider",
    BASE_ROUTE: "/",
    DEPARTMENT_ROUTE: "/department",
    DEPARTMENT_USERS_ROUTE: "/department-users",
    PRODUCT_ROUTE: "/product",
    PRODUCT_META_ROUTE: "/product/:id",
    PRODUCT_FILTER: '/product-filter',
    CATEGORY_ROUTE: "/category",
    PURCHASE_ROUTE: "/local-purchase",
    ADD_PURCHASE_ROUTE: "/local-purchase/add",
    EDIT_PURCHASE_ROUTE: "/local-purchase/edit/:id",
    OGR_ROUTE: "/tnmsc",
    MAIN_STORE_PENDING: '/mainstore-pending',
    MAIN_STORE_PENDING_DETAIL: '/mainstore-pending/:id',
    MAIN_STORE_APPROVED: '/mainstore-approved',
    MAIN_STORE_APPROVED_DETAIL: '/mainstore-approved/:id',
    SUB_STORE_SUPPLY: '/store/supply',
    SUB_STORE_SUPPLY_FORM_ADD: '/store/supply/add',
    SUB_STORE_SUPPLY_FORM_EDIT: '/store/supply/view/:id',
    ADD_OGR_ROUTE: "/tnmsc/add",
    EDIT_OGR_ROUTE: "/tnmsc/edit/:id",
    VENDOR_ROUTE: "/local-supplier",
    DISPATCH_ROUTE: "/supply",
    ADD_DISPATCH_ROUTE: "/supply/add",
    EDIT_DISPATCH_ROUTE: "/supply/edit/:id",
    USERS_ROUTE: "/users",
    USERS_ROLES_ROUTE: "/users-roles",
    USERS_PERMISSIONS_ROUTE: "/users-permissions",
    LOGIN_ROUTE: "/login",
    RESET_PASSWORD_ROUTE: "/reset-password/:id",
    FORGOT_PASSWORD_ROUTE: "/forgot-password",
    PROFILE_ROUTE: "/profile",
    SUBSTORE_PRODUCTS: "/substore/products",
    REPORTS: "/reports"
}