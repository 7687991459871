import { baseURL } from "../../API/api";
import { errorToastr, successToastr } from "../../Components/Toastr/MyToastr";
import { routes } from "../../Container/Navigation/Constants";
import { COMMON_STRINGS } from "../../Utils/Strings";

const loginModel = {
    state: {
        isLoading: false,
        crudLoading: false,
        user: null,
        jwt: null,
        error: null,
        branchId: "",
        branchName: "",
        departmentId: ""
    },
    reducers: {
        loading: (state, { isLoading }) => {
            return { ...state, isLoading }
        },
        crudLoading: (state, { isLoading }) => {
            return { ...state, crudLoading: isLoading }
        },
        user: (state, { user, jwt }) => {
            return { ...state, user, jwt, isLoading: false, error: null }
        },
        setBranch: (state, { branchId, branchName,departmentId }) => {
            return { ...state, branchId, branchName, departmentId }
        },
        logout: (state, { }) => {
            return { ...state, user: null, jwt: null, isLoading: false, error: null }
        },
        error: (state, { error }) => {
            return { ...state, user: null, jwt: null, isLoading: false, crudLoading: false, error: error.message }
        }
    },
    effects: (dispatch) => ({
        login: async ({ data, navigate }, state) => {
            try {
                dispatch.login.loading({ isLoading: true })
                const response = await baseURL.post(`/login`, data);
                if (response.status !== 200) {
                    return errorToastr(COMMON_STRINGS.MODEL_MODULE.LOGIN, COMMON_STRINGS.API_ERROR.SERVER_FAILED, 2)
                }
                const { user, jwt } = response.data;
                dispatch.login.user({ user, jwt });
                if (Array.isArray(user.users_transactions) && user.users_transactions.length == 1) {
                    let { id: branchId, name: branchName } = user.users_transactions[0].branch;
                    dispatch.login.setBranch({ branchId, branchName,departmentId: user?.departmentId });
                    navigate(routes.BASE_ROUTE)
                } else if (Array.isArray(user.users_transactions) && user.users_transactions.length > 1) {
                    navigate(routes.APP_DECIDER)
                }
                successToastr(COMMON_STRINGS.MODEL_MODULE.LOGIN, "Success", 2)
            } catch (error) {
                dispatch.login.error({ error });
                if (error?.response?.data === "Unauthorized") {
                    return errorToastr(COMMON_STRINGS.MODEL_MODULE.LOGIN,"Email or password is incorrect", 2)
                }
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.LOGIN, error.message, 2)
            }
        },
        resetPassword: async ({ data, navigate }, state) => {
            try {
                dispatch.login.crudLoading({ isLoading: true });
                const response = await baseURL.put("/login/reset-password", data)
                if (response.status !== 200) {
                    dispatch.login.crudLoading({ isLoading: false });
                    return errorToastr(COMMON_STRINGS.MODEL_MODULE.RESET_PASSWORD, COMMON_STRINGS.API_ERROR.SERVER_FAILED, 2)
                }
                dispatch.login.crudLoading({ isLoading: false });
                successToastr(COMMON_STRINGS.MODEL_MODULE.RESET_PASSWORD, "Password reset done", 2);
                navigate(routes.LOGIN_ROUTE)
            } catch (error) {
                dispatch.login.error({ error })
                if (error?.response?.data?.message) {
                    return errorToastr(COMMON_STRINGS.MODEL_MODULE.RESET_PASSWORD, error.response.data.message, 2)
                }
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.RESET_PASSWORD, error.message, 2)
            }
        },
        forgotPassword: async ({ data, navigate }, state) => {
            try {
                dispatch.login.crudLoading({ isLoading: true });
                const response = await baseURL.post('/login/reset-password-link', data);
                if (response.status !== 200) {
                    dispatch.login.crudLoading({ isLoading: false });
                    return errorToastr(COMMON_STRINGS.MODEL_MODULE.FORGOT_PASSWORD, COMMON_STRINGS.API_ERROR.SERVER_FAILED, 2)
                }
                dispatch.login.crudLoading({ isLoading: false });
                successToastr(COMMON_STRINGS.MODEL_MODULE.FORGOT_PASSWORD, response.data.message, 2);
                navigate(routes.LOGIN_ROUTE)
            } catch (error) {
                dispatch.login.error({ error })
                if (error?.response?.data.message) {
                    return errorToastr(COMMON_STRINGS.MODEL_MODULE.FORGOT_PASSWORD, error.response.data.message, 2)
                }
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.FORGOT_PASSWORD, error.message, 2)
            }
        }
    }),
    persist: {
        whitelist: ['jwt', 'user', 'branchId', 'branchName'],
        blacklist: ['isLoading', 'error']
    },
};

export default loginModel;