import React, { useCallback, useEffect, useState } from "react";
import { Col, Divider, Input, Row, Skeleton, Space } from "antd";
import Appbar from "../../../Components/Appbar/Appbar";
import MyCard from "../../../Components/Card/MyCard";
import { PurchaseBreadCrumb } from "./Constants";
import MyTable from "../../../Components/Table/MyTable";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Container/Navigation/Constants";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { branchIdState, userState } from "../../../store/selectors";
import { debounce, isValidArray, isValidElement } from "../../../Utils/helpers";
import { MODULE, OPERATION, PAGING } from "../../../Utils/Constants";
import { crudBasedAuth } from "../../../Utils/Authorization";
import { deleteConfirmationModal } from "../../../Components/ConfirmModal/ConfirmModal";
import { COMMON_STRINGS } from "../../../Utils/Strings";
import _ from "lodash";

type Props = {};

function LocalPurchase(props: Props) {
  let userSelector = userState();
  let branchIdSelector = branchIdState();
  let [userPermissionSelector] =
    isValidElement(userSelector) &&
    userSelector?.users_role?.users_permissions.filter(
      (permission) =>
        permission.moduleName === MODULE.INVENTORY &&
        permission.branchId === branchIdSelector
    );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, localPurchase, total, insurance } = useSelector(
    (state: any) => ({
      isLoading: state.localPurchase.isLoading,
      localPurchase: state.localPurchase.localPurchase,
      total: state.localPurchase.total,
      insurance: state.localPurchase.insurance,
    })
  );

  let groupedInsurance =
    isValidArray(insurance) && _.groupBy(insurance, "value");

  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    dispatch.products.filterProducts({});
    dispatch.localPurchase.getAllInsurance();
  }, []);

  useEffect(() => {
    if (filter.length) {
      dispatch.localPurchase
        .getLocalPurchaseByPage({ page, limit, filter })
        .then((r) => {});
    } else {
      dispatch.localPurchase
        .getLocalPurchaseByPage({ page, limit })
        .then((r) => {});
    }
  }, [page, limit, filter]);

  const pageHandler = useCallback(
    (page, pageSize) => {
      setPage(page);
      setLimit(pageSize);
    },
    [page, limit]
  );

  const onDeleteHandler = (data: any) => {
    return deleteConfirmationModal({
      title: COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
      content: COMMON_STRINGS.CRUD_MESSAGE.DELETE_CONFIRM,
      onOkHandler: () =>
        dispatch.internalPurchase.deletePurchase({ id: data?.id, page, limit }),
      onCancelHandler: () => {},
    });
  };

  const debouncedOnSearchText = useCallback(
    debounce((searchText) => {
      if (page !== PAGING.page) {
        setPage(PAGING.page);
        setLimit(PAGING.limit);
      }
      setFilter(searchText);
    }, 800),
    [page, limit]
  );

  const filterPurchase = () => {
    return (
      <div>
        <h3>Filter</h3>
        <Space split={<Divider type="vertical" />}>
          <Input
            key="input-category"
            allowClear
            style={{ width: 200, marginRight: 20 }}
            placeholder="Search"
            onChange={(e) => debouncedOnSearchText(e.target.value)}
          />
        </Space>
      </div>
    );
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <span>{moment(record.date).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "Purchase Invoice No.",
      dataIndex: "purchaseInvoiceNo",
      key: "purchaseInvoiceNo",
    },
    {
      title: "Purchase Order No.",
      dataIndex: "purchaseOrderNo",
      key: "purchaseOrderNo",
    },
    {
      title: "D.C No.",
      dataIndex: "dcNo",
      key: "dcNo",
    },
    {
      title: "Insurance.",
      dataIndex: "indentNo",
      key: "indentNo",
      render: (text, record) => {
        return (
          <span>
            {record?.insuranceId
              ? groupedInsurance[record?.insuranceId][0].name
              : "-"}
          </span>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space>
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.GET
            ) && (
              <a
                onClick={() =>
                  navigate(`edit/${record.id}`, { state: { data: record } })
                }
              >
                View
              </a>
            )}
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.PUT
            ) && (
              <a
                onClick={() =>
                  navigate(`edit/${record.id}`, { state: { data: record } })
                }
              >
                Edit
              </a>
            )}
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.DELETE
            ) && <a onClick={() => onDeleteHandler(record)}>Delete</a>}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <MyCard>
        <Appbar
          title="Local Purchase"
          onButtonHandler={() => navigate(routes.ADD_PURCHASE_ROUTE)}
          breadcrumbs={PurchaseBreadCrumb}
        />
        {filterPurchase()}
        <br />
        <Row>
          <Col span={24}>
            <Skeleton loading={isLoading}>
              <MyTable
                rows={localPurchase}
                columns={columns}
                pagination={{
                  total,
                  current: page,
                  pageSize: limit,
                  onChange: pageHandler,
                }}
              />
            </Skeleton>
          </Col>
        </Row>
      </MyCard>
    </>
  );
}

export default LocalPurchase;
