export const COMMON_STRINGS = {
    MODEL_MODULE: {
        LOGIN: 'Login',
        RESET_PASSWORD: 'Reset password',
        CATEGORIES: 'Categories',
        PRODUCTS: 'Products',
        DASHBORAD: 'Dashboard',
        DEPARTMENTS: 'Departments',
        FORGOT_PASSWORD: 'Forgot password',
        SUPPLIER: 'Supplier',
        SUPPLY: 'Supply',
        LOCAL_PURCHASE: 'Local Purchase',
        INTERNAL_PURCHASE: 'TNMSC',
        USERS: "Users",
        REPORTS: "Reports",
        USERS_ROLES: "User Roles",
        USERS_PERMISSIONS: "User Permissions",
        PROFILE: "Profile",
        SUBSTORE: "Sub Store",
        SUBSTORE_PRODUCTS: "Sub Store Products"
    },
    API_ERROR: {
        SERVER_FAILED: "Server failed to respond",
        SOMETHING_WW: "Something went wrong",
        SUCCESS: "Success",
        FAILED: "Failed",
        JWT_EXPIRED: "jwt expired",
    },
    ERROR: {
        ITEM_EXISTS: "Item has been added to the list"
    },
    CRUD_MESSAGE: {
        ADD_DATA: (module) => `${module} added`,
        UPDATE_DATA: (module) => `${module} updated`,
        DELETE_DATA: (module) => `${module} deleted`,
        DELETE_CONFIRM: "Do you want to delete"
    },
}