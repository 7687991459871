import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Radio, Select } from 'antd';
import MyError from '../../../../../Components/MyError/MyError'
import { formItemLayout } from '../../../../../Utils/Constants'
import { useSelector, useDispatch } from 'react-redux';
import { isValidElement, isValidString } from '../../../../../Utils/helpers';
const { Option } = Select;

type Props = {
    formik: any
}

function ProductForm(props: Props) {
    const { formik } = props;
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState("");

    const { categoriesFilter } = useSelector((state: any) => ({
        categoriesFilter: state.categories.categoriesFilter
    }));

    useEffect(() => {
        if (isValidString(searchText) && searchText.length > 0) {
            dispatch.categories.filterCategories({ filter: searchText })
        } else {
            dispatch.categories.filterCategories({})
        };
    }, [searchText]);

    useEffect(() => {
        if (Array.isArray(categoriesFilter) && categoriesFilter.length > 0 && isValidElement(formik.values.category)) {
            const { category } = formik.values
            const found = categoriesFilter.find((categoryfilter) => categoryfilter.id === formik.values.category.id);
            if (!found) {
                dispatch.categories.categoriesFilter({ categoriesFilter: [...categoriesFilter, category] })
            }
        }
    }, [formik.values.category]);

    const onSelectHandler = useCallback((field, value) => {
        formik.setFieldValue(field, value)
    }, []);

    return (
        <>
            <Form {...formItemLayout} name="register" scrollToFirstError>
                <Form.Item label="Select Category">
                    <Select
                        showSearch
                        placeholder="Select Category"
                        value={formik.values.categoryId}
                        optionFilterProp="children"
                        onBlur={formik.handleBlur}
                        onChange={(value) => onSelectHandler("categoryId", value)}
                        onSearch={(search) => setSearchText(search)}
                    >
                        {Array.isArray(categoriesFilter) && categoriesFilter.length > 0 &&
                            categoriesFilter.map(category => {
                                return <Option key={category.id} value={category.id}>{category.name}</Option>
                            })}
                    </Select>
                    {formik.touched.categoryId && formik.errors.categoryId ? (
                        <MyError error={formik.errors.categoryId} />
                    ) : null}
                </Form.Item>
                <Form.Item label="Code No.">
                    <Input
                        type="text"
                        name="codeNo"
                        value={formik.values.codeNo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Code No."
                    />
                    {formik.touched.codeNo && formik.errors.codeNo ? (
                        <MyError error={formik.errors.codeNo} />
                    ) : null}
                </Form.Item>
                <Form.Item label="Item Name">
                    <Input
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Item Name"
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <MyError error={formik.errors.name} />
                    ) : null}
                </Form.Item>
                <Form.Item label="Status">
                    <Radio.Group name="isActive" value={formik.values.isActive} onChange={formik.handleChange} >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </>
    )
};

export default React.memo(ProductForm);