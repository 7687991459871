import React from "react";
import { isValidElement } from "../../Utils/helpers";
import "./style.css";
function EditableTable({ rows, columns }) {
  return (
    <div className="scrollbar" style={{ width: "100%", overflow: "scroll" }}>
      {rows.length > 0 ? (
        <table style={{ borderCollapse: "collapse" }}>
          <thead>
            <tr>
              {columns.map((column, index) => {
                return (
                  <td
                    key={index}
                    style={{
                      backgroundColor: "#EFF2F5",
                      minWidth: column.minWidth,
                      textAlign: column.textAlign,
                    }}
                  >
                    {column.label}
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 &&
              rows.map((row, rowIndex) => {
                return (
                  <>
                    <tr key={row.id}>
                      {columns.map((column, colIndex) => {
                        return (
                          <td key={colIndex}>
                            <>
                              {column.render(row, rowIndex)}
                              {column.id === "productsMetaId" &&
                                isValidElement(row.error) && (
                                  <>
                                    <span style={{ color: "red" }}>
                                      {row.error}
                                    </span>
                                  </>
                                )}
                            </>
                          </td>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>No data found!</p>
        </div>
      )}
    </div>
  );
}

export default React.memo(EditableTable);
