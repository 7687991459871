import React from 'react';
import { Table } from 'antd';
import './styles.css';

type Props = {
    rows: any;
    columns: any;
    pagination: any;
    size: any;
    scroll?: any;
}

const MyTable = (props: Props) => {
    const { rows, columns, pagination, size, ...rest } = props;
    return (
        <Table
            rowKey="id"
            dataSource={rows}
            columns={columns}
            pagination={pagination}
            size={size}
            scroll={{ x: window.innerWidth < 900 ? 1300 : 0 }}
            {...rest}
        />
    )
};

MyTable.defaultProps = {
    size: "small",
    pagination: false
};

export default MyTable;
