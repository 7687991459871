import { Col, Row, Skeleton, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Appbar from "../../Components/Appbar/Appbar";
import MyCard from "../../Components/Card/MyCard";
import MyTable from "../../Components/Table/MyTable";
import { routes } from "../../Container/Navigation/Constants";
import { branchIdState, userState } from "../../store/selectors";
import { crudBasedAuth } from "../../Utils/Authorization";
import { MODULE, OPERATION } from "../../Utils/Constants";
import { isValidElement } from "../../Utils/helpers";
import { OgrBreadCrumb } from "../Inventory/TNMSC/Constants";
import { SubStoresBreadCrumb, SUBSTORE_STATUS } from "./Constants";

export default function SubStores() {
    const dispatch = useDispatch();
    let location = useLocation();
    let navigate = useNavigate();

    let userSelector = userState();
    let branchIdSelector = branchIdState();

    let [userPermissionSelector] = isValidElement(userSelector) && userSelector?.users_role?.users_permissions.filter(permission => permission.moduleName === MODULE.MASTER && permission.branchId === branchIdSelector);


    const { isLoading, subStorePurchases, error, total } = useSelector((state: any) => ({
        isLoading: state.subStores.isLoading,
        subStorePurchases: state.subStores.subStorePurchases,
        error: state.subStores.error,
        total: state.subStores.total
    }))

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [status, setStatus] = useState(
        location.pathname === routes.MAIN_STORE_PENDING ?
            SUBSTORE_STATUS.PENDING :
            SUBSTORE_STATUS.APPROVED
    );

    useEffect(() => {
        dispatch.subStores.getSubStorePurchase({ page, limit, status })
        return function cleanUp() {
            dispatch.subStores.resetPurchaseDetails({})
        }
    }, [page, limit, status]);

    const pageHandler = (page, pageSize) => {
        setPage(page);
        setLimit(pageSize);
    };

    const columns = [
        {
            title: 'Indent No',
            dataIndex: 'indentNo',
            key: 'indentNo',
            render: (text, record) => {
                return <span>{record?.supply?.indentNo}</span>
            }
        },
        {
            title: 'Issued On',
            dataIndex: 'assignedDate',
            key: 'assignedDate',
            render: (text, record) => {
                return <span>{moment(record?.assignedDate).format("DD-MM-YYYY")}</span>
            }
        },
        {
            title: 'Assigned By',
            dataIndex: 'assignedBy',
            key: 'assignedBy',
            render: (text, record) => {
                return (
                <>
                    {record?.assignedUser?.name}
                    <br/>
                    {record?.assignedUser?.email}
                    <br/>
                    {record?.assignedUser?.mobile}
                </>)
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <Space>
                        {crudBasedAuth(userSelector?.users_role, userPermissionSelector, OPERATION.GET) && <a onClick={() => navigate(`${record.id}`)}>View</a>}
                    </Space>
                )
            }
        }
    ];

    return (
        <>
            <MyCard>
                <Appbar
                    showAddButton={false}
                    breadcrumbs={SubStoresBreadCrumb(status)}
                />
                <br />
                <Row>
                    <Col span={24}>
                        <Skeleton loading={isLoading}>
                            <MyTable rows={subStorePurchases} columns={columns}
                                pagination={{
                                    total,
                                    current: page,
                                    pageSize: limit,
                                    onChange: pageHandler,
                                }} />
                        </Skeleton>
                    </Col>
                </Row>
            </MyCard>
        </>
    );
};