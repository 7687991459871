import React, { useCallback, useEffect, useState } from 'react'
import { Col, Divider, Input, Row, Skeleton, Space } from "antd";
import Appbar from '../../../Components/Appbar/Appbar';
import MyCard from '../../../Components/Card/MyCard';
import { DepartmentBreadCrumb } from './Constants';
import MyTable from '../../../Components/Table/MyTable';
import { useDispatch, useSelector } from 'react-redux';
import MyIcon from '../../../Components/MyIcon/MyIcon';
import Palette from '../../../Utils/Palette';
import DepartmentModal from './Components/DepartmentModal';
import { crudBasedAuth } from '../../../Utils/Authorization';
import { MODULE, OPERATION } from '../../../Utils/Constants';
import { branchIdState, userState } from '../../../store/selectors';
import { isValidElement } from '../../../Utils/helpers';
import { COMMON_STRINGS } from '../../../Utils/Strings';
import { deleteConfirmationModal } from '../../../Components/ConfirmModal/ConfirmModal';

type Props = {}

function Department(props: Props) {
  let userSelector = userState()
  let branchIdSelector = branchIdState();
  let [userPermissionSelector] = isValidElement(userSelector) && userSelector?.users_role?.users_permissions.filter(permission => permission.moduleName === MODULE.MASTER && permission.branchId === branchIdSelector);

  const dispatch = useDispatch()
  const { isLoading, departments, total } = useSelector((state: any) => ({
    isLoading: state.departments.isLoading,
    departments: state.departments.departments,
    total: state.departments.total
  }));

  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [editForm, setEditForm] = useState(null);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (filter.length) {
      dispatch.departments.getDepartmentsByPage({ page, limit, filter })
    } else {
      dispatch.departments.getDepartmentsByPage({ page, limit })
    }
  }, [page, limit, filter]);


  const pageHandler = (page, pageSize) => {
    setPage(page);
    setLimit(pageSize);
  };

  const toggleModal = useCallback(() => {
    setModalVisible(!modalVisible);
  }, [modalVisible]);

  const editHandler = useCallback((record) => {
    setModalVisible(true);
    setEditForm({ ...record });
  }, [modalVisible, editForm]);

  const deleteHandler = (record) => {
    deleteConfirmationModal({
      title: COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
      content: COMMON_STRINGS.CRUD_MESSAGE.DELETE_CONFIRM,
      onOkHandler: () => dispatch.departments.deleteDepartment({ data: record, page, limit }),
      onCancelHandler: () => { }
    });
  };

  const resetEditForm = () => {
    setEditForm(null);
  }

  const columns = [
    {
      title: 'Department / Ward Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Department / Ward No.',
      dataIndex: 'departmentNo',
      key: 'departmentNo',
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      render: (text, record) => {
        return record.isActive ?
          <MyIcon color={Palette.green} iconName='fal fa-check-circle' /> :
          <MyIcon color={Palette.red} iconName='fal fa-times-circle' />
      }
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <Space>
            {crudBasedAuth(userSelector?.users_role, userPermissionSelector, OPERATION.PUT) && <a onClick={() => editHandler(record)}>Edit</a>}
            {crudBasedAuth(userSelector?.users_role, userPermissionSelector, OPERATION.PUT) && <a onClick={() => deleteHandler(record)}>Delete</a>}
          </Space>
        )
      }
    }
  ];

  const filterDepartment = () => {
    return (
      <div>
        <h3>Filter</h3>
        <Space split={<Divider type="vertical" />}>
          <Input
            key="input-department"
            allowClear
            style={{ width: 200, marginRight: 20 }}
            placeholder="Search"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Space>
      </div>
    )
  }

  return (
    <>
      <MyCard>
        <Appbar
          title="Department"
          onButtonHandler={toggleModal}
          breadcrumbs={DepartmentBreadCrumb}
        />
        {filterDepartment()}
        <br />
        <Row>
          <Col span={24}>
            <Skeleton loading={isLoading}>
              <MyTable rows={departments} columns={columns}
                pagination={{
                  total,
                  current: page,
                  pageSize: limit,
                  onChange: pageHandler,
                }} />
            </Skeleton>
          </Col>
        </Row>
      </MyCard>
      {
        modalVisible &&
        <DepartmentModal
          visible={modalVisible}
          toggleModal={toggleModal}
          editForm={editForm}
          resetEditForm={resetEditForm}
          page={page}
          limit={limit}
        />
      }
    </>

  )
}

export default Department;