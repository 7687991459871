import axios from "axios";
import { isValidString } from "../Utils/helpers";
import { baseURL } from "./api";

export const CategoriesNetwork = {
  getCategoriesByPage: (page, limit, branchId, filter = null) =>
    baseURL.get(
      `categories?page=${page}&limit=${limit}&branchId=${branchId}&filter=${filter}`
    ),
  filterCategories: (filter, branchId) =>
    baseURL.get(`categories/filter?filter=${filter}&branchId=${branchId}`),
  getAllCategories: () => baseURL.get(`categories/all`),
  addCategory: (data) => baseURL.post(`categories`, data),
  updateCategory: (data) => baseURL.put(`categories/${data.id}`, data),
  deleteCategory: (data) =>
    baseURL.delete(`categories/${data.id}/${data.branchId}`),
};

export const ProductsNetwork = {
  getProductsByPage: (page, limit, branchId, filter, categoryId) => {
    if (!filter) {
      return baseURL.get(
        `products?page=${page}&limit=${limit}&branchId=${branchId}&categoryId=${categoryId}`
      );
    } else {
      return baseURL.get(
        `products?page=${page}&limit=${limit}&branchId=${branchId}&filter=${filter}&categoryId=${categoryId}`
      );
    }
  },
  filterProducts: (filter, branchId, categoryId = null) =>
    baseURL.get(
      `products/filter?filter=${filter}&branchId=${branchId}&categoryId=${categoryId}`
    ),
  productById: (id, branchId) => baseURL.get(`products/${id}/${branchId}`),
  addProduct: (data) => baseURL.post(`products`, data),
  updateProduct: (data) => baseURL.put(`products/${data.id}`, data),
  productMetaByFilter: (data) =>
    baseURL.get(
      `product-meta/product?filter=${data.filter}&branchId=${data.branchId}`
    ),
  productMetaByPage: (page, limit, branchId) =>
    baseURL.get(
      `product-meta?page=${page}&limit=${limit}&branchId=${branchId}`
    ),
  productMetaById: (data) => baseURL.get(`product-meta/${data.id}`),
  productMetaByProduct: (id, page, limit, branchId, data) =>
    baseURL.post(
      `product-meta/product/${id}/${branchId}?page=${page}&limit=${limit}`,
      data
    ),
  stockFilter: (page, limit, branchId, data) =>
    baseURL.post(
      `products/filter?page=${page}&limit=${limit}&branchId=${branchId}`,
      data
    ),
  deleteProduct: (data) =>
    baseURL.delete(`products/${data.id}/${data.branchId}`),
  getProductsFromAllStore: (productId, branchId) =>
    baseURL.get(
      `products/all/stores/items?productId=${productId}&branchId=${branchId}`
    ),
  addProductMeta: (data) => baseURL.post(`product-meta`, data),
  updateProductMeta: (data) =>
    baseURL.put(`product-meta/${data?.productsMetaId}`, data),
};

export const VendorsNetwork = {
  getVendorsByPage: (page, limit, branchId, filter = null) =>
    baseURL.get(
      `supplier?page=${page}&limit=${limit}&branchId=${branchId}&filter=${filter}`
    ),
  filterVendors: (filter, branchId) =>
    baseURL.get(`supplier/filter?filter=${filter}&branchId=${branchId}`),
  addVendor: (data) => baseURL.post(`supplier`, data),
  updateVendor: (data) => baseURL.put(`supplier/${data.id}`, data),
  deleteVendor: (data) =>
    baseURL.delete(`supplier/${data.id}/${data.branchId}`),
};

export const DepartmentNetwork = {
  getDepartmentsByPage: (page, limit, branchId, filter = null) =>
    baseURL.get(
      `departments?page=${page}&limit=${limit}&branchId=${branchId}&filter=${filter}`
    ),
  getAllDepartment: () => baseURL.get(`departments/all`),
  deleteDepartment: (data) =>
    baseURL.delete(`departments/${data.id}/${data.branchId}`),
  addDepartment: (data) => baseURL.post(`departments`, data),
  updateDepartment: (data) => baseURL.put(`departments/${data.id}`, data),
  filterDepartment: (filter, branchId) =>
    baseURL.get(`departments/filter?filter=${filter}&branchId=${branchId}`),
};

export const LocalPurchaseNetwork = {
  getAllLPByPage: (page, limit, branchId, purchaseTypeId, filter = null) =>
    baseURL.get(
      `purchase?page=${page}&limit=${limit}&purchaseTypeId=${purchaseTypeId}&branchId=${branchId}&filter=${filter}`
    ),
  addLP: (data) => baseURL.post(`purchase`, data),
  updateLP: (data) => baseURL.put(`purchase/${data.id}`, data),
  getPurchaseById: (id, branchId, purchaseTypeId) =>
    baseURL.get(`purchase/${id}/${branchId}/${purchaseTypeId}`),
  deleteTransaction: (id, branchId) =>
    baseURL.delete(`purchase/transaction/${id}/${branchId}`),
  getAllInsuranceList: () =>
    axios.get(
      `https://medical-inventory.s3.amazonaws.com/files/insurance.json`
    ),
};

export const InternalPurchaseNetwork = {
  getAllIPByPage: (page, limit, branchId, purchaseTypeId, filter = null) =>
    baseURL.get(
      `purchase?page=${page}&limit=${limit}&purchaseTypeId=${purchaseTypeId}&branchId=${branchId}&filter=${filter}`
    ),
  addIP: (data) => baseURL.post(`purchase`, data),
  updateIP: (data) => baseURL.put(`purchase/${data.id}`, data),
  getPurchaseById: (id, branchId, purchaseTypeId) =>
    baseURL.get(`purchase/${id}/${branchId}/${purchaseTypeId}`),
  deleteTransaction: (id, branchId) =>
    baseURL.delete(`purchase/transaction/${id}/${branchId}`),
  deletePurchaseById: (id, branchId) =>
    baseURL.delete(`purchase/${id}/${branchId}`),
};

export const SupplyNetwork = {
  getAllSupplyByPage: (page, limit, branchId) =>
    baseURL.get(`supply?page=${page}&limit=${limit}&branchId=${branchId}`),
  getAllSupply: () => baseURL.get(`supply/all`),
  addSupply: (data) => baseURL.post(`supply`, data),
  updateSupply: (data) => baseURL.put(`supply/${data.id}`, data),
  getSupplyById: (id, branchId) => baseURL.get(`supply/${id}/${branchId}`),
  deleteTransactionById: (data) =>
    baseURL.delete(`supply/transaction/${data.id}/${data.branchId}`),
  deleteSupplyById: (data) =>
    baseURL.delete(`supply/${data.id}/${data.branchId}`),
};

export const UsersNetwork = {
  getUsersByPage: (page, limit, branchId, departmentId) =>
    baseURL.get(
      `users?page=${page}&limit=${limit}&branchId=${branchId}&departmentId=${departmentId}`
    ),
  addUser: (data) => baseURL.post(`users/add`, data),
  updateUser: (data) => baseURL.put(`users/${data.id}`, data),
  getUserById: (data) => baseURL.get(`users/${data.id}`),
  updatePassword: (data) => baseURL.put(`users/update-password`, data),
};

export const ReportsNetwork = {
  getReportByPage: (page, limit, branchId) =>
    baseURL.get(`reports?page=${page}&limit=${limit}&branchId=${branchId}`),
  addReports: (data) => baseURL.post(`reports`, data),
};

export const UsersRolesNetwork = {
  getAllUserRoles: () => baseURL.get(`users-roles/all`),
  addUserRole: (data) => baseURL.post(`users-roles`, data),
  updateUserRole: (data) => baseURL.put(`users-roles/${data.id}`, data),
};

export const UsersPermissionsNetwork = {
  getAllUsersPermissions: (data) =>
    baseURL.get(
      `users-roles/permissions?roleId=${data.usersRoleId}&branchId=${data.branchId}`
    ),
  updateUsersPermissions: (data) =>
    baseURL.put(
      `users-roles/permissions/${data.id}/${data.usersRoleId}/${data.branchId}`,
      data
    ),
};

export const SubStoreNetwork = {
  getSubStoreByPage: (page, limit, branchId, status, departmentId) =>
    baseURL.get(
      `main-store?page=${page}&limit=${limit}&branchId=${branchId}&status=${status}&departmentId=${departmentId}`
    ),
  getSubStoreById: (id, branchId, departmentId) =>
    baseURL.get(
      `main-store/${id}?departmentId=${departmentId}&branchId=${branchId}`
    ),
  makeApproval: (id, data, departmentId, branchId) =>
    baseURL.put(
      `main-store/${id}?departmentId=${departmentId}&branchId=${branchId}`,
      data
    ),
  getSubStoreSuppliesByPage: (page, limit, departmentId, branchId) =>
    baseURL.get(
      `main-store/substores/supply?page=${page}&limit=${limit}&branchId=${branchId}&departmentId=${departmentId}`
    ),
  getSubStoresSupplyId: (id, departmentId, branchId) =>
    baseURL.get(
      `main-store/substores/supply/${id}?departmentId=${departmentId}&branchId=${branchId}`
    ),
  subStoreProductFilter: (data, departmentId, branchId) =>
    baseURL.post(
      `main-store/filter?departmentId=${departmentId}&branchId=${branchId}`,
      data
    ),
  subStorePurchaseTransactionById: (data, departmentId, branchId) =>
    baseURL.get(
      `main-store/purchasetransaction/${data.id}?departmentId=${departmentId}&branchId=${branchId}`
    ),
  addSubStoreSupplyTransaction: (data, departmentId, branchId) =>
    baseURL.post(
      `main-store/substores/supply?departmentId=${departmentId}&branchId=${branchId}`,
      data
    ),
  updateSubStoreSupplyTransaction: (data, departmentId, branchId) =>
    baseURL.put(
      `main-store/substores/supply/${data.id}?departmentId=${departmentId}&branchId=${branchId}`,
      data
    ),
  deleteSubStoreSupplyTransaction: (data, departmentId, branchId) =>
    baseURL.delete(
      `main-store/substores/supply/transaction/${data.id}?departmentId=${departmentId}&branchId=${branchId}`
    ),
  deleteSubStoresSupply: (data, departmentId, branchId) =>
    baseURL.delete(
      `main-store/substores/supply/${data.id}?departmentId=${departmentId}&branchId=${branchId}`
    ),
  getAllSubStoreProducts: (page, limit, filter, departmentId, branchId) => {
    if (isValidString(filter)) {
      return baseURL.get(
        `sub-store?page=${page}&limit=${limit}&branchId=${branchId}&departmentId=${departmentId}&filter=${filter}`
      );
    } else {
      return baseURL.get(
        `sub-store?page=${page}&limit=${limit}&branchId=${branchId}&departmentId=${departmentId}`
      );
    }
  },
  adjustStock: (data) => baseURL.put(`sub-store/${data.id}`, data),
  getSubStorePurchaseBySupply: (id) => baseURL.get(`sub-store/${id}`),
};

// export const VendorNetwork = {
//     getAllVendorByPage: (page, limit) => baseURL.get(`vendors?page=${page}&limit=${limit}`),
//     getAllVendor: () => baseURL.get(`vendors/all`),
//     addVendor: (data) => baseURL.post(`vendors`, data),
//     updateVendor: (data, id) => baseURL.put(`vendors/${id}`, data)
// }
