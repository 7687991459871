import React, { useCallback, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import MyModal from "../../../../Components/Modal/MyModal";
import CategoryForm from "./MicroComponents/CategoryForm";
import { categoryValidations } from "../../../../Utils/FormValidations";
import { useDispatch, useSelector } from "react-redux";
import { isValidElement } from "../../../../Utils/helpers";

type Props = {
  visible: boolean;
  toggleModal: () => void;
  resetEditForm: any;
  editForm: any;
  page?: Number;
  limit?: Number;
};

function CategoryModal(props: Props) {
  const { visible, toggleModal, page, limit, resetEditForm, editForm } = props;
  const dispatch = useDispatch();

  const { crudLoading } = useSelector((state: any) => ({
    crudLoading: state.categories.crudLoading,
  }));

  const formik = useFormik({
    initialValues: {
      name: "",
      isActive: true,
      isEdit: false,
    },
    validationSchema: Yup.object(categoryValidations),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  function cleanUp() {
    toggleModal();
    formik.resetForm();
    resetEditForm();
  }

  useEffect(() => {
    if (isValidElement(editForm)) {
      formik.setValues({ ...editForm, isEdit: true });
    }
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (formik.values.isEdit) {
        dispatch.categories
          .updateCategory({ data: formik.values, page, limit })
          .then((r) => {
            cleanUp();
          })
          .catch((error) => {
            cleanUp();
          });
      } else {
        dispatch.categories
          .addCategory({ data: formik.values, page, limit })
          .then((r) => {
            cleanUp();
          })
          .catch((error) => {
            cleanUp();
          });
      }
    },
    [formik.values]
  );

  return (
    <MyModal
      title="Category"
      visible={visible}
      onSubmitHandler={handleSubmit}
      onCancelHandler={toggleModal}
      okButtonDisabled={!(formik.isValid && formik.dirty)}
      buttonLoading={crudLoading}
    >
      <CategoryForm formik={formik} />
    </MyModal>
  );
}

export default React.memo(CategoryModal);
