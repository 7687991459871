import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
} from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import Appbar from "../../../../Components/Appbar/Appbar";
import MyCard from "../../../../Components/Card/MyCard";
import { internalPurchaseValidations } from "../../../../Utils/FormValidations";
import {
  debounce,
  isValidArray,
  isValidElement,
  isValidForm,
} from "../../../../Utils/helpers";
import { OgrNewBreadCrumb } from "../Constants";
import EditableTable from "../../../../Components/EditableTable/EditableTable";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import MyError from "../../../../Components/MyError/MyError";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../../Container/Navigation/Constants";
import { MODULE, PURCHASE_TYPES } from "../../../../Utils/Constants";
import { branchIdState, userState } from "../../../../store/selectors";
import {
  errorToastr,
  successToastr,
} from "../../../../Components/Toastr/MyToastr";
import { COMMON_STRINGS } from "../../../../Utils/Strings";

const { Option } = Select;

export default function InternalPuchaseForm() {
  const location = useLocation();

  const dispatch = useDispatch();
  let userSelector = userState();
  let branchIdSelector = branchIdState();
  let [userPermissionSelector] =
    isValidElement(userSelector) &&
    userSelector?.users_role?.users_permissions.filter(
      (permission) =>
        permission.moduleName === MODULE.INVENTORY &&
        permission.branchId === branchIdSelector
    );

  const { productsFilter } = useSelector((state: any) => ({
    productsFilter: state.products.productsFilter,
  }));

  const { crudLoading, isLoading } = useSelector((state: any) => ({
    crudLoading: state.internalPurchase.crudLoading,
    isLoading: state.internalPurchase.isLoading,
  }));

  const formik = useFormik({
    initialValues: {
      purchaseTypeId: PURCHASE_TYPES.INTERNAL_PURCHASE,
      ogrNo: "",
      date: moment(),
      pbNo: "",
      pbName: "",
      purchaseItems: [].concat(Object.assign({ itemNameRef: null })),
    },
    validationSchema: Yup.object(internalPurchaseValidations),
    onSubmit: (values) => {},
  });

  const [isEdit, setIsEdit] = useState(
    isValidElement(location.state) ? true : false
  );

  const [addEditResponse, setAddEditResponse] = useState(null);

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (searchText.length > 0) {
      dispatch.products.filterProducts({ filter: searchText });
    } else {
      dispatch.products.filterProducts({});
    }

    return function cleanUp() {};
  }, [searchText]);

  useEffect(() => {
    const data = !!location.state
      ? JSON.parse(JSON.stringify(location.state))
      : null;
    if (isValidElement(data) && isValidElement(data.data)) {
      dispatch.internalPurchase
        .getInternalPurchaseById({ id: data.data.id })
        .then((data) => {
          formik.setValues({
            purchaseTypeId: PURCHASE_TYPES.INTERNAL_PURCHASE,
            date: moment(data.date),
            ogrNo: data.ogrNo,
            pbName: data.pbName,
            pbNo: data.pbNo,
            purchaseItems: data.purchase_transactions.map((transaction) => {
              let data = {};
              let isExits =
                Array.isArray(productsFilter) &&
                productsFilter.length > 0 &&
                productsFilter.find((product, index) => {
                  return product.id === transaction.productId;
                });

              if (!isExits) {
                dispatch.products.appendProductsFilter({
                  id: transaction.productId,
                  name: transaction.product.name,
                  codeNo: transaction.product.codeNo,
                  branchId: transaction.product.branchId,
                  isActive: transaction.product.isActive,
                  categoryId: transaction.product.categoryId,
                  price: transaction.products_metum.price,
                });
              }

              data["codeNo"] = transaction.product.codeNo;
              data["name"] = transaction.product.name;
              data["purchaseTransactionId"] = transaction.id;
              data["productMetaId"] = transaction.productsMetumId;
              data["qty"] = transaction.qty;
              data["productId"] = transaction.productId;
              data["batchNo"] = transaction.products_metum.batchNo;
              data["manufacturerName"] =
                transaction.products_metum.manufacturerName;
              data["manufacturerDate"] = moment(
                transaction.products_metum.manufacturerDate
              );
              data["expiryDate"] = moment(
                transaction.products_metum.expiryDate
              );
              data["price"] = transaction.products_metum.price;
              data["itemNameRef"] = null;
              return data;
            }),
          });
          formik.setFieldValue("id", data.id);
        });
    }
  }, [isEdit, crudLoading]);

  useEffect(() => {
    if (
      Array.isArray(formik.values.purchaseItems) &&
      formik.values.purchaseItems.length > 0
    ) {
      if (
        isValidElement(
          formik.values.purchaseItems[formik.values.purchaseItems.length - 1]
            .itemNameRef
        )
      ) {
        formik.values.purchaseItems[
          formik.values.purchaseItems.length - 1
        ].itemNameRef.focus();
      }
    } else {
      formik.setFieldValue(
        "purchaseItems",
        [].concat(Object.assign({ itemNameRef: null }))
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.purchaseItems.length]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message = "Changes that you made may not be saved.";
    e.returnValue = message;
    return message;
  };

  const onSelectHandler = (field, value, index) => {
    formik.setFieldValue(`purchaseItems.${index}.${field}`, value);
    dispatch.products.filterProducts({});
  };

  const onDateHanlder = (date, dateString, field, index) => {
    if (!index) {
      formik.setFieldValue(field, moment(date));
    }
    formik.setFieldValue(
      `purchaseItems.${index}.${field}`,
      date.startOf("month")
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      addRow();
    }
  };

  const addRow = () => {
    formik.setFieldValue(
      "purchaseItems",
      formik.values.purchaseItems.concat(Object.assign({}))
    );
  };

  const removeRow = (index) => {
    const originalState = formik.values.purchaseItems;
    if (isValidArray(originalState) && originalState.length > 1) {
      originalState.splice(index, 1);
    }
    formik.setFieldValue("purchaseItems", originalState);
  };

  const renderPurchaseDetails = () => {
    return (
      <>
        <Row>
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ marginTop: 5 }}>
            <h5>Date</h5>
            <DatePicker
              format="DD-MM-YYYY"
              style={{ width: "100%" }}
              value={formik.values.date}
              onChange={(date, dateString) =>
                onDateHanlder(date, dateString, "date", null)
              }
              onBlur={formik.handleBlur}
            />
            {formik.touched.date && formik.errors.date ? (
              <MyError error={JSON.stringify(formik.errors.date)} />
            ) : null}
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ marginTop: 5 }}>
            <h5>OGR No</h5>
            <Input
              type="text"
              name="ogrNo"
              value={formik.values.ogrNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="OGR No"
            />
            {formik.touched.ogrNo && formik.errors.ogrNo ? (
              <MyError error={JSON.stringify(formik.errors.ogrNo)} />
            ) : null}
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ marginTop: 5 }}>
            <h5>P.B No</h5>
            <Input
              type="text"
              name="pbNo"
              value={formik.values.pbNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="P.B No"
            />
            {formik.touched.pbNo && formik.errors.pbNo ? (
              <MyError error={JSON.stringify(formik.errors.pbNo)} />
            ) : null}
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ marginTop: 5 }}>
            <h5>P.B Name</h5>
            <Input
              type="text"
              name="pbName"
              value={formik.values.pbName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="PB Name"
            />
            {formik.touched.pbName && formik.errors.pbName ? (
              <MyError error={JSON.stringify(formik.errors.pbName)} />
            ) : null}
          </Col>
        </Row>
      </>
    );
  };

  const onSaveHandler = (e) => {
    e.preventDefault();
    if (!userPermissionSelector?.post || !userPermissionSelector?.put) {
      return errorToastr(
        COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE,
        "Invalid operation",
        2
      );
    }
    if (!isEdit) {
      dispatch.internalPurchase
        .addInternalPurchase({ data: formik.values })
        .then((response) => {
          if (isValidElement(response?.id)) {
            setIsEdit(true);
            setAddEditResponse(response);
            dispatch.internalPurchase.getInternalPurchaseById({
              id: response?.id,
            });
            return successToastr(
              COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE,
              COMMON_STRINGS.CRUD_MESSAGE.ADD_DATA("TNMSC Purchase"),
              2
            );
          }
        });
    } else {
      const data: any = isValidElement(location?.state)
        ? JSON.parse(JSON.stringify(location?.state?.data))
        : isValidElement(addEditResponse)
        ? addEditResponse
        : null;

      let formData = { ...formik.values };
      if (isValidElement(addEditResponse)) {
        formData["id"] = addEditResponse.id;
      }
      dispatch.internalPurchase
        .updateInternalPurchase({ data: formData })
        .then((r) => {
          dispatch.internalPurchase.getInternalPurchaseById({
            id: data?.id || addEditResponse?.id,
          });
        });
    }
  };

  const debouncedOnSearchText = useCallback(
    debounce((searchText) => {
      setSearchText(searchText);
    }, 800),
    [searchText]
  );

  const column = [
    {
      id: "sNo",
      label: "No.",
      render: (row, index) => {
        return index + 1;
      },
    },
    {
      id: "products",
      label: "Item Name",
      minWidth: 200,
      render: (row, index) => {
        return (
          <>
            <Select
              ref={(e) => {
                row["itemNameRef"] = e;
              }}
              placeholder="Select Item"
              value={formik.values.purchaseItems[index].productId}
              style={{
                minWidth: "100%",
                borderColor: isValidForm(formik, "productId", index) && "red",
              }}
              onBlur={formik.handleBlur}
              optionFilterProp="children"
              showSearch
              onChange={(value) => onSelectHandler("productId", value, index)}
              onSelect={(value, option) => {
                formik.setFieldValue(
                  `purchaseItems.${index}.codeNo`,
                  option.children[0]
                );
                formik.setFieldValue(
                  `purchaseItems.${index}.name`,
                  option.children[2]
                );
              }}
              onSearch={(search) => debouncedOnSearchText(search)}
            >
              {Array.isArray(productsFilter) &&
                productsFilter.length > 0 &&
                productsFilter.map((product) => {
                  return (
                    <Option key={product.id} value={product.id}>
                      {product.codeNo} - {product.name}
                    </Option>
                  );
                })}
            </Select>
          </>
        );
      },
    },
    {
      id: "batchNo",
      label: "Batch No",
      minWidth: 100,
      render: (row, index) => {
        return (
          <>
            <Input
              placeholder="Batch No."
              name={`purchaseItems.${index}.batchNo`}
              value={formik.values.purchaseItems[index].batchNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                borderColor: isValidForm(formik, "batchNo", index) && "red",
              }}
              onKeyPress={handleKeyPress}
            />
          </>
        );
      },
    },
    {
      id: "Price",
      label: "Price",
      minWidth: 100,
      render: (row, index) => {
        return (
          <>
            <Input
              placeholder="Price"
              name={`purchaseItems.${index}.price`}
              value={formik.values.purchaseItems[index].price}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                borderColor: isValidForm(formik, "price", index) && "red",
              }}
              onKeyPress={handleKeyPress}
            />
          </>
        );
      },
    },
    {
      id: "manufacturer",
      label: "Manufacturer",
      minWidth: 200,
      render: (row, index) => {
        return (
          <>
            <Input
              placeholder="Manufacturer"
              name={`purchaseItems.${index}.manufacturerName`}
              value={formik.values.purchaseItems[index].manufacturerName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                borderColor:
                  isValidForm(formik, "manufacturerName", index) && "red",
              }}
              onKeyPress={handleKeyPress}
            />
          </>
        );
      },
    },
    {
      id: "manfDate",
      label: "Man. Date",
      minWidth: 150,
      render: (row, index) => {
        return (
          <>
            <DatePicker
              placeholder="Man. date"
              format="MM-YYYY"
              picker="month"
              allowClear={false}
              value={formik.values.purchaseItems[index].manufacturerDate}
              onChange={(date, dateString) =>
                onDateHanlder(date, dateString, "manufacturerDate", index)
              }
              onBlur={formik.handleBlur}
              style={{
                borderColor:
                  isValidForm(formik, "manufacturerDate", index) && "red",
                width: 150,
              }}
            />
          </>
        );
      },
    },
    {
      id: "expiryDate",
      label: "Exp. Date",
      minWidth: 150,
      render: (row, index) => {
        return (
          <DatePicker
            placeholder="Expiry date"
            picker="month"
            format="MM-YYYY"
            allowClear={false}
            value={formik.values.purchaseItems[index].expiryDate}
            onChange={(date, dateString) =>
              onDateHanlder(date, dateString, "expiryDate", index)
            }
            onBlur={formik.handleBlur}
            style={{
              borderColor: isValidForm(formik, "expiryDate", index) && "red",
              width: 150,
            }}
          />
        );
      },
    },

    {
      id: "qty",
      label: "Qty",
      minWidth: 100,
      render: (row, index) => {
        return (
          <>
            <Input
              placeholder="Qty"
              type="number"
              name={`purchaseItems.${index}.qty`}
              value={formik.values.purchaseItems[index].qty}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                borderColor: isValidForm(formik, "qty", index) && "red",
              }}
              onKeyPress={handleKeyPress}
            />
          </>
        );
      },
    },
    {
      id: "action",
      label: "Action",
      textAlign: "center",
      render: (row, index) => {
        return (
          <>
            <Space size={2}>
              <Button type="primary" onClick={addRow}>
                +
              </Button>
              <Button
                type="primary"
                danger
                onClick={() => {
                  if (!!row.purchaseTransactionId) {
                    const { data } = isValidElement(location.state)
                      ? JSON.parse(JSON.stringify(location.state))
                      : null;
                    dispatch.internalPurchase
                      .deleteTransaction({ id: row.purchaseTransactionId })
                      .then((r) => {
                        dispatch.internalPurchase.getInternalPurchaseById({
                          id: data.id,
                        });
                      });
                  } else {
                    removeRow(index);
                  }
                }}
              >
                x
              </Button>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <>
      <MyCard>
        <Appbar
          title={"Save"}
          onButtonHandler={(e) => onSaveHandler(e)}
          buttonLoading={crudLoading}
          showAddButton={formik.dirty && formik.isValid}
          breadcrumbs={OgrNewBreadCrumb(isEdit)}
        />
        {renderPurchaseDetails()}
        <Row style={{ marginTop: "5%" }}>
          <Col span={24}>
            <Skeleton loading={isLoading || crudLoading}>
              {Array.isArray(formik.values.purchaseItems) &&
              formik.values.purchaseItems.length > 0 ? (
                <Skeleton loading={isLoading || crudLoading}>
                  <EditableTable
                    columns={column}
                    rows={formik.values.purchaseItems}
                  />
                </Skeleton>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>No Items found!</h3>
                  <Button onClick={addRow} style={{ width: "20%" }}>
                    Add
                  </Button>
                </div>
              )}
            </Skeleton>
          </Col>
        </Row>
      </MyCard>
    </>
  );
}
