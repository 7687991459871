import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { routes } from '../../Container/Navigation/Constants';
import appDeciderStyles from './appDeciderStyles';
import { TN_LOGO } from '../../Utils/Constants';
import { Divider } from 'antd';
// import { errorToastr, successToastr } from '../../Components/Toastr/MyToastr';

export default function AppDecider() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector((state) => ({
        user: state.login.user
    }));

    const onDeciderHandler = (e, usersTransaction, index) => {
        e.preventDefault();
        let { name: branchName, id: branchId } = usersTransaction.branch;
        dispatch.login.setBranch({ branchId, branchName })
        navigate(routes.BASE_ROUTE)
    };

    const renderHeader = () => {
        return (
            <div style={appDeciderStyles.headerContainer}>
                <img src={TN_LOGO} height={"50"} />
                <h3>Medical Inventory</h3>
            </div>
        )
    }
    const renderLayout = () => {
        return (
            <div style={appDeciderStyles.childContainer}>
                <h3> Select the department</h3>
                <Divider />
                {user.users_transactions.map((usersTransaction, index) => {
                    return (
                        <a key={index} onClick={(e) => onDeciderHandler(e, usersTransaction, index)}>
                            <h4>{index + 1}. {usersTransaction.branch.name}</h4>
                            <Divider />
                        </a>
                    )
                })}
            </div>
        )
    }

    return (
        <div style={appDeciderStyles.mainContainer}>
            {renderHeader()}
            {renderLayout()}
        </div>
    )
};