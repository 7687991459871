import { routes } from "../../../Container/Navigation/Constants";

export const ProductBreadCrumb = [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: "Product", path: routes.PRODUCT_ROUTE }
];

export const ProductMetaBreadCrumb = [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: "Product", path: routes.PRODUCT_ROUTE },
    { label: "Product Detail", path: "" },
];

export const ProductFilterBreadCrumb = [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: "Product", path: routes.PRODUCT_ROUTE },
    { label: "Product Filter", path: "" },
]

export const StockType = [
    { label: "Unit", value: "unit" },
    { label: "Percent", value: "percent" }
];

export const PostDays = [
    { label: '30 Days', value: 30 },
    { label: '60 Days', value: 60 },
    { label: '90 Days', value: 90 },
    { label: '120 Days', value: 120 },
    { label: '150 Days', value: 150 },
    { label: '180 Days', value: 180 },
]

