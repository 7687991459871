import React, { useCallback, useState } from 'react'
import { Button, Form, Input, Radio } from 'antd'
import MyError from '../../../../../Components/MyError/MyError'
import { formItemLayout } from '../../../../../Utils/Constants'

type Props = {
    formik: any
}

function CategoryForm(props: Props) {
    const { formik } = props;
    // const [subCategories, setSubCategories] = useState([].concat(Object.assign({})));

    // const onRemoveLabel = useCallback((subCategoryData, index) => {
    //     const filteredState = subCategories.filter(subCategory => subCategoryData.id !== subCategory.id);
    //     return setSubCategories(filteredState)
    // }, [subCategories]);

    // const onAddLabel = useCallback(() => {
    //     return setSubCategories((prevState) => {
    //         return [...prevState, { id: prevState.length > 0 ? prevState[prevState.length - 1].id + 1 : 1, categoryName: "" }]
    //     })
    // }, []);

    // const onChangeSubCategoryText = useCallback((e) => {
    //     const { name, value } = e.target;
    //     let originalState = subCategories.slice();
    //     originalState[parseInt(name)].categoryName = value
    //     setSubCategories(originalState)
    // },[subCategories]);

    // const renderSubCategoryAddButton = () => {
    //     return (
    //         <span style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: 5 }}>
    //             <Button type='primary' onClick={onAddLabel}>Add</Button>
    //         </span>

    //     )
    // };
    return (
        <>
            <Form {...formItemLayout} name="register" scrollToFirstError>
                <Form.Item label="Category name">
                    <Input
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Category Name"
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <MyError error={formik.errors.name} />
                    ) : null}
                </Form.Item>
                <Form.Item label="Status">
                    <Radio.Group name="isActive" value={formik.values.isActive} onChange={formik.handleChange} >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                    {formik.touched.isActive && formik.errors.isActive ? (
                        <MyError error={formik.errors.isActive} />
                    ) : null}
                </Form.Item>
                {/* {formik.values.isSub && (
                    <>
                        {renderSubCategoryAddButton()}
                        {
                            subCategories.map((subCategory, index) => {
                                return (
                                    <Form.Item label="Sub Category Name" key={index}>
                                        <span style={{ display: 'flex' }}>
                                            <Input
                                                type="text"
                                                name={index.toString()}
                                                value={subCategory.categoryName}
                                                onChange={onChangeSubCategoryText}
                                                placeholder="Label Name"
                                            />
                                            <Button danger type='primary' style={{ marginLeft: 5 }} onClick={() => onRemoveLabel(subCategory, index)}>Remove</Button>
                                        </span>
                                    </Form.Item>
                                )
                            })
                        }
                    </>
                )} */}
            </Form>
        </>
    )
};

export default React.memo(CategoryForm);

