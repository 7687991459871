import React, { useEffect, useState } from "react";
import { Col, Row, Skeleton, Space } from "antd";
import { DispatchBreadCrumb, SUBSTORE_STATUS } from "./Constants";
import MyCard from "../../Components/Card/MyCard";
import Appbar from "../../Components/Appbar/Appbar";
import MyTable from "../../Components/Table/MyTable";
import { useNavigate } from "react-router-dom";
import { routes } from "../../Container/Navigation/Constants";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { isValidElement } from "../../Utils/helpers";
import { branchIdState, userState } from "../../store/selectors";
import { MODULE, OPERATION } from "../../Utils/Constants";
import { crudBasedAuth } from "../../Utils/Authorization";
import { deleteConfirmationModal } from "../../Components/ConfirmModal/ConfirmModal";
import { COMMON_STRINGS } from "../../Utils/Strings";
import { errorToastr } from "../../Components/Toastr/MyToastr";

type Props = {};

function Supply(props: Props) {
  let userSelector = userState();
  let branchIdSelector = branchIdState();
  let [userPermissionSelector] =
    isValidElement(userSelector) &&
    userSelector?.users_role?.users_permissions.filter(
      (permission) =>
        permission.moduleName === MODULE.SUPPLY &&
        permission.branchId === branchIdSelector
    );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, supply, total } = useSelector((state: any) => ({
    isLoading: state.supply.isLoading,
    supply: state.supply.supply,
    total: state.supply.total,
  }));

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch.supply.getSupplyByPage({ page, limit });

    dispatch.products.getProductsMetaByFilter({}).then((r) => {});
  }, [page, limit]);

  const pageHandler = (page, pageSize) => {
    setPage(page);
    setLimit(pageSize);
  };

  const deleteHandler = (record) => {
    return deleteConfirmationModal({
      title: COMMON_STRINGS.MODEL_MODULE.SUPPLY,
      content: COMMON_STRINGS.CRUD_MESSAGE.DELETE_CONFIRM,
      onOkHandler: () =>
        dispatch.supply.deleteSupply({ data: record, page, limit }),
      onCancelHandler: () => {},
    });
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => {
        return <span>{moment(record.date).format("DD-MM-YYYY")}</span>;
      },
    },
    {
      title: "Indent No.",
      dataIndex: "indentNo",
      key: "indentNo",
    },
    {
      title: "Department",
      dataIndex: "departmentId",
      key: "departmentId",
      render: (text, record) => {
        return (
          <>
            <span>
              {record?.department?.name} - {record?.department?.departmentNo}
            </span>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space>
            {isValidElement(userPermissionSelector) &&
              crudBasedAuth(
                userSelector?.users_role,
                userPermissionSelector,
                OPERATION.GET
              ) && (
                <a
                  onClick={() =>
                    navigate(`edit/${record.id}`, { state: { data: record } })
                  }
                >
                  View
                </a>
              )}
            {isValidElement(userPermissionSelector) &&
              crudBasedAuth(
                userSelector?.users_role,
                userPermissionSelector,
                OPERATION.PUT
              ) && (
                <a
                  onClick={() =>
                    navigate(`edit/${record.id}`, { state: { data: record } })
                  }
                >
                  Edit
                </a>
              )}
            {isValidElement(userPermissionSelector) &&
              crudBasedAuth(
                userSelector?.users_role,
                userPermissionSelector,
                OPERATION.DELETE
              ) && (
                <a
                  onClick={() => {
                    if (
                      record?.sub_stores_purchases[0]?.status ===
                      SUBSTORE_STATUS.APPROVED
                    ) {
                      return errorToastr(
                        "Supply",
                        "Indent has been approved already",
                        2
                      );
                    }
                    deleteHandler(record);
                  }}
                >
                  Delete
                </a>
              )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <MyCard>
        <Appbar
          title="Supply"
          showAddButton={true}
          onButtonHandler={() => navigate(routes.ADD_DISPATCH_ROUTE)}
          breadcrumbs={DispatchBreadCrumb}
        />
        <br />
        <Row>
          <Col span={24}>
            <Skeleton loading={isLoading}>
              <MyTable
                rows={supply}
                columns={columns}
                pagination={{
                  total,
                  current: page,
                  pageSize: limit,
                  onChange: pageHandler,
                }}
              />
            </Skeleton>
          </Col>
        </Row>
      </MyCard>
    </>
  );
}

export default Supply;
