import React, { useCallback, useEffect, useState } from "react";
import { Col, Divider, Input, Row, Select, Skeleton, Space } from "antd";
import Appbar from "../../../Components/Appbar/Appbar";
import MyCard from "../../../Components/Card/MyCard";
import { ProductBreadCrumb } from "./Constants";
import MyTable from "../../../Components/Table/MyTable";
import ProductModal from "./Components/ProductModal";
import { useDispatch, useSelector } from "react-redux";
import MyIcon from "../../../Components/MyIcon/MyIcon";
import Palette from "../../../Utils/Palette";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Container/Navigation/Constants";
import {
  debounce,
  isValidElement,
  isValidString,
  lowStock,
} from "../../../Utils/helpers";
import { branchIdState, userState } from "../../../store/selectors";
import { MODULE, OPERATION, PAGING } from "../../../Utils/Constants";
import { crudBasedAuth } from "../../../Utils/Authorization";
import { deleteConfirmationModal } from "../../../Components/ConfirmModal/ConfirmModal";
import { COMMON_STRINGS } from "../../../Utils/Strings";
import { useDebounceEffect } from "../../../hooks/useDebounce";

type Props = {};

function Product(props: Props) {
  let userSelector = userState();
  let branchIdSelector = branchIdState();
  let [userPermissionSelector] =
    isValidElement(userSelector) &&
    userSelector?.users_role?.users_permissions.filter(
      (permission) =>
        permission.moduleName === MODULE.MASTER &&
        permission.branchId === branchIdSelector
    );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, products, total } = useSelector((state: any) => ({
    isLoading: state.products.isLoading,
    products: state.products.products,
    total: state.products.total,
  }));

  const { categories } = useSelector((state: any) => ({
    categories: state.categories.categories,
  }));

  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = useState(PAGING.page);
  const [limit, setLimit] = useState(PAGING.limit);
  const [editForm, setEditForm] = useState(null);
  const [stockType, setStockType] = useState("unit");
  const [stockThreshold, setStockThreshold] = useState(15);
  const [search, setSearch] = useState("");
  const [categoryId, setCategoryId] = useState(null);

  useEffect(() => {
    dispatch.categories.getCategoriesByPage({ page: 1, limit: 100 });

    return function cleanUp() {
      dispatch.categories.resetState();
    };
  }, []);

  // useDebounceEffect(() => {
  // if (isValidString(search) && search.length > 0) {
  //   dispatch.products.getProductsByPage({ page, limit, filter: search, categoryId });
  // } else {
  //   dispatch.products.getProductsByPage({ page, limit, filter: null, categoryId });
  // }
  // }, 800, [page, limit, search, categoryId]);

  useEffect(() => {
    if (isValidString(search) && search.length > 0) {
      dispatch.products.getProductsByPage({
        page,
        limit,
        filter: search,
        categoryId,
      });
    } else {
      dispatch.products.getProductsByPage({
        page,
        limit,
        filter: null,
        categoryId,
      });
    }
  }, [page, limit, categoryId]);

  const pageHandler = useCallback(
    (page, pageSize) => {
      setPage(page);
      setLimit(pageSize);
    },
    [page, limit]
  );

  const toggleModal = useCallback(() => {
    setModalVisible(!modalVisible);
  }, [modalVisible]);

  const editHandler = useCallback(
    (record) => {
      let data = JSON.parse(JSON.stringify(record));
      setModalVisible(true);
      setEditForm(data);
    },
    [modalVisible, editForm]
  );

  const deleteHandler = (record) => {
    deleteConfirmationModal({
      title: COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
      content: COMMON_STRINGS.CRUD_MESSAGE.DELETE_CONFIRM,
      onOkHandler: () =>
        dispatch.products.deleteProduct({ data: record, page, limit }),
      onCancelHandler: () => {},
    });
  };

  const resetEditForm = () => {
    setEditForm(null);
  };

  const debouncedOnSearchText = useCallback(
    debounce((searchText) => {
      setSearch(searchText);
      if (isValidString(searchText) && searchText.length > 0) {
        dispatch.products.getProductsByPage({
          page,
          limit,
          filter: searchText,
          categoryId,
        });
      }
    }, 800),
    [page, limit, categoryId]
  );

  const columns = [
    {
      title: "Code No.",
      dataIndex: "codeNo",
      key: "codeNo",
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      onCell: (record, rowIndex) => {
        return {
          style: {
            backgroundColor:
              lowStock(record.qty, stockThreshold, stockType) && "red",
          },
        };
      },
    },
    {
      title: "Category",
      dataIndex: "categoryname",
      key: "categoryname",
      render: (text, record) => <span>{record.category.name}</span>,
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      // onCell: (record, rowIndex) => {
      //   return {
      //     style: {
      //       backgroundColor: record.qty <= 0 && 'red'
      //     }
      //   }
      // },
      render: (text, record) => {
        return record.isActive ? (
          <MyIcon color={Palette.green} iconName="fal fa-check-circle" />
        ) : (
          <MyIcon color={Palette.red} iconName="fal fa-times-circle" />
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space>
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.GET
            ) && (
              <a onClick={() => navigate(`${record.id}`, { state: record })}>
                View
              </a>
            )}
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.PUT
            ) && <a onClick={() => editHandler(record)}>Edit</a>}
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.DELETE
            ) && <a onClick={() => deleteHandler(record)}>Delete</a>}
          </Space>
        );
      },
    },
  ];

  const filterProduct = () => {
    return (
      <div>
        <h3>Filter</h3>
        <Space split={<Divider type="vertical" />}>
          <Input
            allowClear
            style={{ width: 200, marginRight: 20 }}
            placeholder="Search"
            onChange={(e) => {
              if (page !== PAGING.page) {
                setPage(PAGING.page);
              }
              debouncedOnSearchText(e.target.value);
            }}
          />
          {Array.isArray(categories) && (
            <Select
              placeholder="Categories"
              value={categoryId}
              onChange={(value) => setCategoryId(value)}
              style={{ width: 200, marginRight: 20 }}
            >
              {categories.map((category) => (
                <Select.Option key={category.id}>{category.name}</Select.Option>
              ))}
            </Select>
          )}
          {/* <Space>
            <Radio.Group
              options={StockType}
              onChange={(e) => setStockType(e.target.value)}
              value={stockType}
              optionType="button"
              buttonStyle="solid"
            />
            <InputNumber style={{ width: 100 }} placeholder="Enter value" value={stockThreshold} onChange={(value) => setStockThreshold(value)} />
          </Space> */}
        </Space>
      </div>
    );
  };

  return (
    <>
      <MyCard>
        <Appbar
          title="Product"
          onButtonHandler={toggleModal}
          breadcrumbs={ProductBreadCrumb}
          secondaryTitle="Filter"
          showSecondayButton={true}
          secondaryButtonHandler={() => navigate(routes.PRODUCT_FILTER)}
        />
        {filterProduct()}
        <br />
        <Row>
          <Col span={24}>
            <Skeleton loading={isLoading}>
              <MyTable
                rows={products}
                columns={columns}
                pagination={{
                  total,
                  current: page,
                  pageSize: limit,
                  onChange: pageHandler,
                }}
              />
            </Skeleton>
          </Col>
        </Row>
      </MyCard>
      {modalVisible && (
        <ProductModal
          visible={modalVisible}
          toggleModal={toggleModal}
          editForm={editForm}
          resetEditForm={resetEditForm}
          page={page}
          limit={limit}
        />
      )}
    </>
  );
}

export default Product;
