import { Col, Form, Input, Row, Skeleton, Space } from "antd";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Appbar from "../../../Components/Appbar/Appbar";
import MyCard from "../../../Components/Card/MyCard";
import MyTable from "../../../Components/Table/MyTable";
import { branchIdState, userState } from "../../../store/selectors";
import { crudBasedAuth, isAdmin, isSuperAdmin } from "../../../Utils/Authorization";
import { MODULE, OPERATION } from "../../../Utils/Constants";
import { isValidElement } from "../../../Utils/helpers";
import SubStoreStockAdjModal from "../Components/SubStoreStockAdjModal";
import { SubStoreProductsBreadCrumb, SubStoresBreadCrumb } from "../Constants";



export default function SubStoreProducts() {
    const dispatch = useDispatch();
    let userSelector = userState();

    const { subStoresProducts, isLoading } = useSelector((state: any) => ({
        subStoresProducts: state.subStoresProducts.subStoresProducts,
        isLoading: state.subStoresProducts.isLoading
    }));

    const [filter, setFilter] = useState("");
    const [showStockAdjModal, setShowStockAdjModal] = useState(false);
    const [editForm, setEditForm] = useState(null)

    useEffect(() => {
        dispatch.subStoresProducts.getAllSubStoreProducts({ page: 1, limit: 250, filter })
    }, [filter]);

    const toggleModal = useCallback(() => {
        setShowStockAdjModal(!showStockAdjModal);
    }, [showStockAdjModal]);

    const resetEditForm = () => {
        setEditForm(null);
    };

    const renderFilter = () => {
        return (
            <>
                <Form
                    layout="inline"
                    className="components-table-demo-control-bar"
                    style={{ marginBottom: 16 }}
                >
                    <Form.Item label="Search">
                        <Input
                            placeholder='Search'
                            name="filter"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        />
                    </Form.Item>
                </Form>
            </>
        )
    }

    const editHandler = (row) => {
        let data = {
            id: row?.id,
            productId: row?.productId,
        }
        setShowStockAdjModal(true);
        setEditForm(data);
    }

    const columns = [
        {
            title: 'Product No',
            dataIndex: 'productName',
            key: 'productName',
            render: (text, record) => {
                return <span>{record?.product?.name}</span>
            }
        },
        {
            title: 'Code No',
            dataIndex: 'codeNo',
            key: 'codeNo',
            render: (text, record) => {
                return <span>{record?.product?.codeNo}</span>
            }
        },
        {
            title: 'Qty',
            dataIndex: 'total',
            key: 'total',
            render: (text, record) => {
                return <span>{record?.total}</span>
            }
        }
    ];

    if (isSuperAdmin(userSelector?.users_role?.name) && isAdmin(userSelector?.users_role?.name)) {
        let data: any = {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <Space>
                        <a onClick={() => editHandler(record)}>Adj. Stocks</a>
                    </Space>
                )
            }
        };
        columns.push(data)
    }

    return (
        <>
            <MyCard>
                <Appbar
                    showAddButton={false}
                    breadcrumbs={SubStoreProductsBreadCrumb}
                />
                <br />
                <Row>
                    <Col span={24}>
                        {renderFilter()}
                        <Skeleton loading={isLoading}>
                            <MyTable rows={subStoresProducts} columns={columns} />
                        </Skeleton>
                    </Col>
                </Row>
            </MyCard>
            {showStockAdjModal &&
                <SubStoreStockAdjModal
                    visible={showStockAdjModal}
                    onCancelHandler={toggleModal}
                    editForm={editForm}
                    resetEditForm={resetEditForm}
                    crudLoading={false}
                    filter={filter}
                />}
        </>
    );
}
