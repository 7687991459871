import { init } from "@rematch/core";
import persistPlugin from "@rematch/persist";
import storage from "redux-persist/lib/storage";
// model 
import loginModel from "../Pages/Login/loginModel";
import categoriesModel from "../Pages/Master/Category/categoriesModel";
import productsModel from "../Pages/Master/Products/productsModel";
import vendorModel from "../Pages/Inventory/Supplier/vendorModel";
import departmentModel from "../Pages/Master/Department/departmentModel";
import localpurchaseModel from "../Pages/Inventory/LocalPurchase/localpurchaseModel";
import internalPurchaseModel from "../Pages/Inventory/TNMSC/tnmscModel";
import supplyModel from "../Pages/Supply/supplyModel";
import usersModel from "../Pages/Users/userModel";
import usersRoleModel from "../Pages/UsersRoles/usersRolesModel";
import subStoreModel from "../Pages/SubStores/subStoreModel";
import subStoreProductsModel from "../Pages/SubStores/SubStoreProducts/subStoreProductModel";
import reportsModel from "../Pages/Reports/reportsModel";

const persistConfig = {
  key: "root",
  storage,
};

let models: any = {
  login: loginModel,
  categories: categoriesModel,
  products: productsModel,
  vendors: vendorModel,
  departments: departmentModel,
  localPurchase: localpurchaseModel,
  internalPurchase: internalPurchaseModel,
  supply: supplyModel,
  users: usersModel,
  reports: reportsModel,
  userRoles: usersRoleModel,
  subStores: subStoreModel,
  subStoresProducts: subStoreProductsModel
}

export const store = init({
  plugins: [persistPlugin(persistConfig)],
  models
});
