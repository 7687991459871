import { Col, Row, Skeleton } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Appbar from "../../../Components/Appbar/Appbar";
import MyCard from "../../../Components/Card/MyCard";
import { submitConfirmationModal } from "../../../Components/ConfirmModal/ConfirmModal";
import MyTable from "../../../Components/Table/MyTable";
import { routes } from "../../../Container/Navigation/Constants";
import { COMMON_STRINGS } from "../../../Utils/Strings";
import { SubStoresDetailBreadCrumb, SUBSTORE_STATUS } from "../Constants";
export default function SubStoreProductDetails() {
    let { id } = useParams();
    let location = useLocation();
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const { isLoading, subStorePurchaseDetails } = useSelector((state: any) => ({
        isLoading: state.subStores.isLoading,
        subStorePurchaseDetails: state.subStores.subStorePurchaseDetails
    }));

    const [status, setStatus] = useState(
        location.pathname.includes("pending") ?
            SUBSTORE_STATUS.PENDING :
            SUBSTORE_STATUS.APPROVED
    );
    const [modalVisible, setModalVisible] = useState(false)

    useEffect(() => {
        dispatch.subStores.getSubStoreById({ id })
        return function cleanUp() {
            dispatch.subStores.resetPurchaseDetails({});
        }
    }, [id, status]);

    const approvalHandler = () => {
        let data = {
            status: SUBSTORE_STATUS.APPROVED
        }
        submitConfirmationModal({
            title: COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
            content: "Are you sure do you want to approve",
            onOkHandler: () => {
                dispatch.subStores.makeApprovalStatus({ id, data })
                    .then(r => {
                        navigate('/mainstore-approved')
                    })
            },
            onCancelHandler: () => { }
        })
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return <span>{record?.products_metum?.name}</span>
            }
        },
        {
            title: 'Qty',
            dataIndex: 'supplyQty',
            key: 'supplyQty',
            render: (text, record) => {
                return <span>{record?.supplyQty}</span>
            }
        },
        {
            title: 'Batch No',
            dataIndex: 'batchNo',
            key: 'batchNo',
            render: (text, record) => {
                return <span>{record?.products_metum?.batchNo}</span>
            }
        },
        {
            title: 'Code No',
            dataIndex: 'codeNo',
            key: 'codeNo',
            render: (text, record) => {
                return <span>{record?.products_metum?.codeNo}</span>
            }
        },
        {
            title: 'Manufacturer Name',
            dataIndex: 'manufacturerName',
            key: 'manufacturerName',
            render: (text, record) => {
                return <span>{record?.products_metum?.manufacturerName}</span>
            }
        },
        {
            title: 'Manufacturer Date',
            dataIndex: 'manufacturerDate',
            key: 'manufacturerDate',
            render: (text, record) => {
                return <span>{moment(record?.products_metum?.manufacturerDate).format("DD-MM-YYYY")}</span>
            }
        },
        {
            title: 'Expiry Date',
            dataIndex: 'expiryDate',
            key: 'expiryDate',
            render: (text, record) => {
                return <span>{moment(record?.products_metum?.expiryDate).format("DD-MM-YYYY")}</span>
            }
        },
    ];

    return (
        <>
            <MyCard>
                <Appbar
                    showAddButton={status === SUBSTORE_STATUS.PENDING}
                    title="Approve"
                    breadcrumbs={SubStoresDetailBreadCrumb(status)}
                    onButtonHandler={() => approvalHandler()}
                />
                <br />
                <Row>
                    <Col span={24}>
                        <Skeleton loading={isLoading}>
                            {Array.isArray(subStorePurchaseDetails?.subStorePurchaseTransactions) &&
                                <MyTable rows={subStorePurchaseDetails?.subStorePurchaseTransactions} columns={columns} />}
                        </Skeleton>
                    </Col>
                </Row>
            </MyCard>
        </>
    );
}
