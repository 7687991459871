import React, { useEffect } from 'react'
//@ts-ignore
import { Button, Col, Row, Skeleton, Space } from "antd";
import { UsersRolesBreadCrumb } from './Constants';
import MyCard from '../../Components/Card/MyCard';
import Appbar from '../../Components/Appbar/Appbar';
import MyTable from '../../Components/Table/MyTable';
import MyIcon from '../../Components/MyIcon/MyIcon';
import Palette from '../../Utils/Palette';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../Container/Navigation/Constants';
import { USER_ROLE } from '../../Utils/Constants';
import { isSuperAdmin } from '../../Utils/Authorization';
import { userState } from '../../store/selectors';

type Props = {}

function UsersRoles(props: Props) {

  let userSelector = userState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, userRoles } = useSelector((state: any) => ({
    isLoading: state.userRoles.isLoading,
    userRoles: state.userRoles.userRoles
  }));



  useEffect(() => {
    dispatch.userRoles.getAllUserRole({})
  }, []);

  const onPermissionHandler = (record) => {
    let data = JSON.parse(JSON.stringify(record))
    navigate(routes.USERS_PERMISSIONS_ROUTE, { state: data })
  }

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      render: (text, record) => {
        return record.isActive ?
          <MyIcon color={Palette.green} iconName='fal fa-check-circle' /> :
          <MyIcon color={Palette.red} iconName='fal fa-times-circle' />
      }
    },
  ];

  if (isSuperAdmin(userSelector?.users_role?.name)) {
    let data: any = {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <Space>
            {(record?.name !== USER_ROLE.SUPER && isSuperAdmin(userSelector?.users_role?.name)) ?
              <Button type="link" onClick={() => onPermissionHandler(record)}>Permissions</Button> :
              null}
          </Space>
        )
      }
    }
    columns.push(data)
  }

  if (!isSuperAdmin(userSelector?.users_role?.name)) {
    return (
      <div>Page restricted</div>
    )
  }

  return (
    <>
      <MyCard>
        <Appbar
          title="Users Roles"
          showAddButton={false}
          breadcrumbs={UsersRolesBreadCrumb}
        />
        <br />
        <Row>
          <Col span={24}>
            <Skeleton loading={isLoading}>
              <MyTable rows={userRoles} columns={columns} />
            </Skeleton>
          </Col>
        </Row>
      </MyCard>
    </>

  )
}

export default UsersRoles;