import React from 'react'
import { Modal } from 'antd';

type Props = {
    title: string,
    visible: boolean,
    onSubmitHandler: (e:any) => void,
    onCancelHandler: () => void,
    okButtonDisabled?: boolean,
    buttonLoading?: boolean,
    okText?: string,
    children?: JSX.Element | JSX.Element[]
}

function MyModal(props: Props) {
    const
        {
            title,
            visible,
            onSubmitHandler,
            onCancelHandler,
            okButtonDisabled,
            buttonLoading,
            children,
            okText
        } = props;

    return (
        <Modal
            title={title}
            style={{ top: 20 }}
            open={visible}
            onOk={onSubmitHandler}
            onCancel={onCancelHandler}
            okButtonProps={{ disabled: okButtonDisabled }}
            maskClosable={false}
            okText={okText}
            confirmLoading={buttonLoading}
            {...props}
        >
            {children}
        </Modal>
    )
};

MyModal.defaultProps = {
    onSubmitLoading: false,
    submitButtonDisabled: false,
    okText: "Submit",
    okButtonDisabled: false
};

export default MyModal;


