import { Col, Form, Row, Skeleton } from 'antd'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Appbar from '../../Components/Appbar/Appbar';
import MyCard from '../../Components/Card/MyCard';
import { routes } from '../../Container/Navigation/Constants';
import { profileValidations } from '../../Utils/FormValidations';
import ProfileForm from './Components/ProfileForm'
import { ProfileBreadCrumb } from './Constants'

export default function Profile() {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { user } = useSelector((state: any) => ({
        user: state.login.user
    }));

    const { profile,crudLoading } = useSelector((state: any) => ({
        profile: state.users.profile,
        crudLoading: state.users.crudLoading
    }));

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            mobile: "",
            isActive: "",
            id: ""
        },
        validationSchema: Yup.object(profileValidations),
        onSubmit: (values) => {
            console.log(values)
        }
    });

    useEffect(() => {
        dispatch.users.getUserById(user)
            .then((data) => {
                formik.setValues(data)
            });
    }, []);

    const onSubmitHandler = (e) => {
      e.preventDefault();
      dispatch.users.updateProfile(formik.values)
    }
    const renderProfileForm = () => {
        return (
            <>
                <Row>
                    <Col span={24}>
                        <Skeleton loading={false}>
                            <ProfileForm formik={formik} />
                        </Skeleton>
                    </Col>
                </Row>
            </>
        )
    }
    return (
        <>
            <MyCard>
                <Appbar
                    title="Save"
                    showSecondayButton={true}
                    secondaryTitle="Change Password"
                    secondaryButtonHandler={() => {
                        // dispatch.login.logout({});
                        navigate(routes.FORGOT_PASSWORD_ROUTE,{ state: { data: formik.values }})
                    }}
                    showAddButton={(formik.dirty && formik.isValid)}
                    buttonLoading={crudLoading}
                    onButtonHandler={onSubmitHandler}
                    breadcrumbs={ProfileBreadCrumb}
                />
                <br />
                {renderProfileForm()}
            </MyCard>
        </>
    )
}
