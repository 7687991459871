import { routes } from "../../../Container/Navigation/Constants";

export const DepartmentBreadCrumb = [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: "Department", path: routes.DEPARTMENT_ROUTE }
];

export const Department = [
    {
        title: "Medical",
        value: 'medical_store',
        sub_dept: [
            { title: "Speciality", value: "speciality" },
            { title: "Saline", value: "saline" }
        ]
    },
    {
        title: "OP Pharmacy",
        value: 'op_pharmacy',
        sub_dept: [
            { title: "General OP Pharmacy", value: "general_op_pharmacy" },
            { title: "Cardiology OP", value: "cardiology_op" },
            { title: "Cardio Thoracic OP", value: "cardio_thoracic_op" },
            { title: "Ortho OP", value: "ortho_thoracic_op" },
            { title: "Nephrology OP", value: "nephrology_op" },
            { title: "Rheumatology OP", value: "rheumatology_op" },
            { title: "Neuro OP", value: "neuro_op" },
            { title: "Gastro OP", value: "gastro_op" },
            { title: "Oncology OP", value: "oncology_op" },
            { title: "Ent OP", value: "ent_op" },
            { title: "Diabetology OP", value: "diabetology_op" },
            { title: "Hyper tension OP", value: "hyper_tension_op" },
            { title: "Skin OP", value: "skin_op" },
            { title: "Geriatric OP", value: "geriatric_op" },
            { title: "Psychiatric OP", value: "psychiatric_op" },
            { title: "EMC 24", value: "emc_24_hrs" }
        ]
    },
    {
        title: "Zonal Pharmacy",
        value: 'zonal_pharmacy',
        sub_dept: [
            { title: "Zone 1", value: "zone1" },
            { title: "Zone 2", value: "zone2" },
            { title: "Zone 3", value: "zone3" },
            { title: "Zone 4", value: "zone4" },
            { title: "Zone 5", value: "zone5" },
            { title: "Zone 6", value: "zone6" },
            { title: "Zone 7", value: "zone7" },
            { title: "Zone 8", value: "zone8" },
        ]
    }
]