import { Form, Input } from 'antd'
import React from 'react'
import MyError from '../../../Components/MyError/MyError'
import { fullFormItemLayout } from '../../../Utils/Constants'

type Props = {
    formik: any
}
export default function ProfileForm(props: Props) {
    const { formik } = props;
    return (
        <>
            <Form {...fullFormItemLayout} name="register" scrollToFirstError>
                <Form.Item label="Name">
                    <Input
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Name"
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <MyError error={formik.errors.name} />
                    ) : null}
                </Form.Item>
                <Form.Item label="Email">
                    <Input
                        type="text"
                        name="email"
                        placeholder="Email"
                        disabled={true}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <MyError error={formik.errors.email} />
                    ) : null}
                </Form.Item>
                <Form.Item label="Mobile">
                    <Input
                        type="text"
                        name="mobile"
                        placeholder="Mobile"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.mobile && formik.errors.mobile ? (
                        <MyError error={formik.errors.mobile} />
                    ) : null}
                </Form.Item>
                <Form.Item label="Status">
                    {formik.values.isActive ? "Yes" : "No"}
                </Form.Item>
            </Form>
        </>
    )
}
