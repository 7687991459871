import React from "react";
import MyModal from "../../../../Components/Modal/MyModal";
import ReportsForm from "../ReportsForm";

export default function ReportsModal({
  formik,
  visible,
  handleSubmit,
  toggleModal,
}) {
  return (
    <MyModal
      title="Reports"
      visible={visible}
      onSubmitHandler={handleSubmit}
      onCancelHandler={toggleModal}
    >
      <ReportsForm formik={formik} />
    </MyModal>
  );
}
