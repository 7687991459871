import { Col, Form, Input, Row, Select, Skeleton, Space, Switch } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Appbar from "../../../Components/Appbar/Appbar";
import MyCard from "../../../Components/Card/MyCard";
import MyTable from "../../../Components/Table/MyTable";
import {
  debounce,
  findExpiryProduct,
  isValidElement,
  lowStock,
  onlyValidKeyValuesFromObj,
} from "../../../Utils/helpers";
import { PostDays, ProductMetaBreadCrumb } from "./Constants";
import ProductMetaModal from "./Components/ProductMetaModal";
import { crudBasedAuth } from "../../../Utils/Authorization";
import { branchIdState, userState } from "../../../store/selectors";
import { MODULE, OPERATION, PAGING } from "../../../Utils/Constants";
const { Option } = Select;
function ProductMeta() {
  const dispatch = useDispatch();
  const location = useLocation();
  let userSelector = userState();
  let branchIdSelector = branchIdState();

  const { isLoading, productsMeta, total } = useSelector((state: any) => ({
    isLoading: state.products.isLoading,
    productsMeta: state.products.productsMeta,
    total: state.products.total,
  }));

  let [userPermissionSelector] =
    isValidElement(userSelector) &&
    userSelector?.users_role?.users_permissions.filter(
      (permission) =>
        permission.moduleName === MODULE.MASTER &&
        permission.branchId === branchIdSelector
    );

  const formik = useFormik({
    initialValues: {
      postDay: null,
      filter: null,
      qtyStatus: false,
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const [page, setPage] = useState(PAGING.page);
  const [limit, setLimit] = useState(PAGING.limit);
  const [modalVisible, setModalVisible] = useState(false);
  const [editForm, setEditForm] = useState(null);

  const resetEditForm = () => {
    setEditForm(null);
  };

  useEffect(() => {
    let data = {};
    if (formik.values.filter) {
      data["filter"] = formik.values.filter;
    }
    if (formik.values.postDay) {
      data["postDay"] = formik.values.postDay;
    }
    if (formik.values.qtyStatus) {
      data["qtyStatus"] = formik.values.qtyStatus;
    }
    dispatch.products.getProductMetaByProduct({
      id: location.pathname.split("/")[2],
      page,
      limit,
      data,
    });

    return function cleanUp() {
      dispatch.products.resetProductMeta();
    };
  }, [
    page,
    limit,
    formik.values.postDay,
    formik.values.filter,
    formik.values.qtyStatus,
  ]);

  const pageHandler = (page, pageSize) => {
    setPage(page);
    setLimit(pageSize);
  };

  const onSelectHandler = (value, type) => {
    if (page !== PAGING.page) {
      setPage(PAGING.page);
      setLimit(PAGING.limit);
    }
    formik.setFieldValue(type, value);
  };

  const toggleModal = useCallback(() => {
    setModalVisible(!modalVisible);
  }, [modalVisible]);

  const editHandler = useCallback(
    (record) => {
      let data = JSON.parse(JSON.stringify(record));
      setModalVisible(true);
      setEditForm(data);
    },
    [modalVisible, editForm]
  );

  const renderFilter = () => {
    return (
      <>
        <Form
          layout="inline"
          className="components-table-demo-control-bar"
          style={{ marginBottom: 16 }}
        >
          {/* <Form.Item label="Hide Stocks">
                        <Switch
                            defaultChecked={false}
                            onChange={(value) => onSelectHandler(value, "qtyStatus")}
                        />
                    </Form.Item> */}
          <Form.Item label="Search">
            <Input
              placeholder="Search"
              name="filter"
              onChange={(e) => debouncedOnSearchText(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Post Expiry">
            <Select
              placeholder="Select Post Expiry"
              value={formik.values.postDay}
              allowClear
              onChange={(value) => onSelectHandler(value, "postDay")}
            >
              {PostDays.map((day) => {
                return <Option value={day.value}>{day.label}</Option>;
              })}
            </Select>
          </Form.Item>
        </Form>
      </>
    );
  };

  const debouncedOnSearchText = useCallback(
    debounce((searchText) => {
      if (page !== PAGING.page) {
        setPage(PAGING.page);
        setLimit(PAGING.limit);
      }
      formik.setFieldValue("filter", searchText);
    }, 800),
    [formik.values.filter]
  );

  const columns = [
    {
      title: "Purchase Type",
      dataIndex: "purchaseType",
      key: "purchaseType",
      width: 150,
      render: (text, record) => {
        return <span>{record?.purchase_type?.name}</span>;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (text, record) => {
        return (
          <span>
            {record.codeNo} - {record.name}
          </span>
        );
      },
    },
    {
      title: "Batch No.",
      dataIndex: "batchNo",
      key: "batchNo",
      width: 100,
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: 100,
      onCell: (record, rowIndex) => {
        return {
          style: {
            backgroundColor: lowStock(record.qty) && "red",
          },
        };
      },
    },
    {
      title: "Manufacturer Name",
      dataIndex: "manufacturerName",
      key: "manufacturerName",
      width: 150,
    },
    {
      title: "Date of entry",
      dataIndex: "createdAt",
      key: "created At",
      width: 150,
      render: (text, record) => {
        return <span>{moment(record.createdAt).format("DD-MM-YYYY")}</span>;
      },
    },
    {
      title: "Manufacturer Date",
      dataIndex: "manufacturerDate",
      key: "manufacturerDate",
      width: 200,
      onCell: (record, rowIndex) => {
        return {
          style: {
            backgroundColor: findExpiryProduct(record.expiryDate) && "red",
          },
        };
      },
      render: (text, record) => {
        return (
          <span>{moment(record.manufacturerDate).format("DD-MM-YYYY")}</span>
        );
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      width: 200,
      onCell: (record, rowIndex) => {
        return {
          style: {
            backgroundColor: findExpiryProduct(record.expiryDate) && "red",
          },
        };
      },
      render: (text, record) => {
        return <span>{moment(record.expiryDate).format("DD-MM-YYYY")}</span>;
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space>
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.PUT
            ) && <a onClick={() => editHandler(record)}>Edit</a>}
            {/* {crudBasedAuth(userSelector?.users_role, userPermissionSelector, OPERATION.DELETE) && <a onClick={() => deleteHandler(record)}>Delete</a>} */}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <MyCard>
        <Appbar
          title="Add Stock"
          showAddButton={true}
          onButtonHandler={() => toggleModal()}
          breadcrumbs={ProductMetaBreadCrumb}
        />
        <br />
        <Row>
          <Col span={24}>
            {renderFilter()}
            <Skeleton loading={isLoading}>
              <MyTable
                rows={productsMeta}
                columns={columns}
                pagination={{
                  total,
                  current: page,
                  pageSize: limit,
                  onChange: pageHandler,
                }}
              />
            </Skeleton>
          </Col>
        </Row>
      </MyCard>
      {modalVisible ? (
        <ProductMetaModal
          visible={modalVisible}
          toggleModal={toggleModal}
          editForm={editForm}
          resetEditForm={resetEditForm}
          page={page}
          limit={limit}
          filter={onlyValidKeyValuesFromObj(formik.values)}
          state={location?.state}
        />
      ) : null}
    </>
  );
}

export default ProductMeta;
