import React from 'react'
import { Card } from 'antd'

export default function MyCard(props) {
    return (
        <Card {...props}>
            {props.children}
        </Card>
    )
}
