import React, { useCallback, useEffect, useState } from "react";
import { Col, Divider, Input, Row, Skeleton, Space } from "antd";
import Appbar from "../../../Components/Appbar/Appbar";
import MyCard from "../../../Components/Card/MyCard";
import { CategoryBreadCrumb } from "./Constants";
import MyTable from "../../../Components/Table/MyTable";
import CategoryModal from "./Components/CategoryModal";
import { useDispatch, useSelector } from "react-redux";
import MyIcon from "../../../Components/MyIcon/MyIcon";
import Palette from "../../../Utils/Palette";
import { crudBasedAuth } from "../../../Utils/Authorization";
import { branchIdState, userState } from "../../../store/selectors";
import { isValidElement } from "../../../Utils/helpers";
import { MODULE, OPERATION, PAGING } from "../../../Utils/Constants";
import { deleteConfirmationModal } from "../../../Components/ConfirmModal/ConfirmModal";
import { COMMON_STRINGS } from "../../../Utils/Strings";

type Props = {};

function Category(props: Props) {
  let userSelector = userState();
  let branchIdSelector = branchIdState();
  let [userPermissionSelector] =
    isValidElement(userSelector) &&
    userSelector?.users_role?.users_permissions.filter(
      (permission) =>
        permission.moduleName === MODULE.MASTER &&
        permission.branchId === branchIdSelector
    );

  const dispatch = useDispatch();
  const { isLoading, categories, total } = useSelector((state: any) => ({
    isLoading: state.categories.isLoading,
    categories: state.categories.categories,
    total: state.categories.total,
  }));

  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = useState(PAGING.page);
  const [limit, setLimit] = useState(PAGING.limit);
  const [editForm, setEditForm] = useState(null);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (filter.length) {
      dispatch.categories.getCategoriesByPage({ page, limit, filter });
    } else {
      dispatch.categories.getCategoriesByPage({ page, limit });
    }
  }, [page, limit, filter]);

  const toggleModal = useCallback(() => {
    setModalVisible(!modalVisible);
  }, [modalVisible]);

  const pageHandler = (page, pageSize) => {
    setPage(page);
    setLimit(pageSize);
  };

  const editHandler = useCallback(
    (record) => {
      setModalVisible(true);
      setEditForm(record);
    },
    [modalVisible, editForm]
  );

  const deleteHandler = (record) => {
    deleteConfirmationModal({
      title: COMMON_STRINGS.MODEL_MODULE.CATEGORIES,
      content: COMMON_STRINGS.CRUD_MESSAGE.DELETE_CONFIRM,
      onOkHandler: () =>
        dispatch.categories.deleteCategory({ data: record, page, limit }),
      onCancelHandler: () => {},
    });
  };

  const resetEditForm = useCallback(() => {
    setEditForm(null);
  }, [editForm]);

  const columns = [
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (text, record) => {
        return record.isActive ? (
          <MyIcon color={Palette.green} iconName="fal fa-check-circle" />
        ) : (
          <MyIcon color={Palette.red} iconName="fal fa-times-circle" />
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space>
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.PUT
            ) && <a onClick={() => editHandler(record)}>Edit</a>}
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.DELETE
            ) && <a onClick={() => deleteHandler(record)}>Delete</a>}
          </Space>
        );
      },
    },
  ];

  const filterCategory = () => {
    return (
      <div>
        <h3>Filter</h3>
        <Space split={<Divider type="vertical" />}>
          <Input
            key="input-category"
            allowClear
            style={{ width: 200, marginRight: 20 }}
            placeholder="Search"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Space>
      </div>
    );
  };

  return (
    <>
      <MyCard>
        <Appbar
          title="Category"
          onButtonHandler={toggleModal}
          breadcrumbs={CategoryBreadCrumb}
        />
        {filterCategory()}
        <br />
        <Row>
          <Col span={24}>
            <Skeleton loading={isLoading}>
              <MyTable
                rows={categories}
                columns={columns}
                pagination={{
                  total,
                  current: page,
                  pageSize: limit,
                  onChange: pageHandler,
                }}
              />
            </Skeleton>
          </Col>
        </Row>
      </MyCard>
      {modalVisible ? (
        <CategoryModal
          visible={modalVisible}
          toggleModal={toggleModal}
          editForm={editForm}
          resetEditForm={resetEditForm}
          page={page}
          limit={limit}
        />
      ) : null}
    </>
  );
}

export default Category;
