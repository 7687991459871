import React from 'react';

type Props = {
    error: string
}

const MyError = ({ error }: Props) => {
    return (
        <span style={{ color: 'red' }}>{error}</span>
    )
}

export default MyError;
