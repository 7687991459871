import { SubStoreNetwork } from "../../../API/Networks";
import { errorToastr, successToastr } from "../../../Components/Toastr/MyToastr";
import { jwtExpired } from "../../../Utils/helpers";
import { COMMON_STRINGS } from "../../../Utils/Strings";

const subStoreProductsModel = {
    state: {
        isLoading: false,
        crudLoading:false,
        total: 0,
        error: null,
        subStoresProducts: null,
    },
    reducers: {
        loading: (state, { isLoading }) => {
            return { ...state, isLoading }
        },
        crudLoading: (state, { crudLoading }) => {
            return { ...state, crudLoading }
        },
        subStoresProducts: (state, { subStoresProducts }) => {
            return { ...state, subStoresProducts, isLoading: false, error: null }
        },
        error: (state, { error }) => {
            return { ...state, isLoading: false,crudLoading:false, error, subStoresProducts: null }
        }
    },
    effects: (dispatch) => ({
        getAllSubStoreProducts: async ({ page, limit, filter }, state) => {
            try {
                let { branchId, departmentId } = state.login;
                dispatch.subStoresProducts.loading({ isLoading: true })
                const response = await SubStoreNetwork.getAllSubStoreProducts(page, limit, filter, departmentId, branchId);
                if (response.status !== 200) {
                    dispatch.subStoresProducts.error({ error: 'Server failed to load' });
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.SUBSTORE_PRODUCTS,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                const subStoresProducts = response.data.data;
                dispatch.subStoresProducts.subStoresProducts({ subStoresProducts })
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.subStoresProducts.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.SUBSTORE_PRODUCTS, error.response.data.message, 2)
            }
        },
        adjustSubStoreStock: async (data, state) => {
            try {
                dispatch.subStoresProducts.crudLoading({ crudLoading: true });
                const response = await SubStoreNetwork.adjustStock(data);
                if (response.status !== 200) {
                    dispatch.subStoresProducts.error({ error: 'Server failed to load' });
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.SUBSTORE_PRODUCTS,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                dispatch.subStoresProducts.getAllSubStoreProducts({ page: 1,limit: 250,filter: data?.filter });
                dispatch.subStoresProducts.crudLoading({ crudLoading: false });
                return successToastr(COMMON_STRINGS.MODEL_MODULE.SUBSTORE_PRODUCTS, "Stock Adj. done", 2)

            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.subStoresProducts.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.SUBSTORE_PRODUCTS, error.response.data.message, 2)
            }
        }
    })
};

export default subStoreProductsModel;