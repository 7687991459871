import { ProductsNetwork } from "../../../API/Networks";
import {
  errorToastr,
  successToastr,
} from "../../../Components/Toastr/MyToastr";
import { isValidString, jwtExpired } from "../../../Utils/helpers";
import { COMMON_STRINGS } from "../../../Utils/Strings";

const productsModel = {
  state: {
    isLoading: false,
    crudLoading: false,
    error: null,
    products: null,
    product: null,
    productsFilter: null,
    productsMeta: null,
    productMetaFilter: [],
    productMeta: null,
    total: 0,
    stockFilter: null,
  },
  reducers: {
    loading: (state, { isLoading }) => {
      return { ...state, isLoading };
    },
    crudLoading: (state, { isLoading }) => {
      return { ...state, crudLoading: isLoading };
    },
    product: (state, { product, index }) => {
      product["index"] = index;
      return {
        ...state,
        product,
        isLoading: false,
        error: null,
        crudLoading: false,
      };
    },
    productsMeta: (state, { productsMeta, total }) => {
      return {
        ...state,
        isLoading: false,
        crudLoading: false,
        error: null,
        productsMeta,
        total,
      };
    },
    productMetaFilter: (state, { productMetaFilter }) => {
      return {
        ...state,
        isLoading: false,
        crudLoading: false,
        error: null,
        productMetaFilter,
      };
    },
    appendProductMetaFilter: (state, productMetaFilter) => {
      let updatedProductMetaFilter = state.productMetaFilter.concat({
        ...productMetaFilter,
      });
      return {
        ...state,
        isLoading: false,
        crudLoading: false,
        error: null,
        productMetaFilter: updatedProductMetaFilter,
      };
    },
    productMeta: (state, { productMeta }) => {
      return {
        ...state,
        productMeta,
        isLoading: false,
        crudLoading: false,
        error: null,
      };
    },
    products: (state, { products, total }) => {
      return {
        ...state,
        products,
        total,
        isLoading: false,
        error: null,
        crudLoading: false,
      };
    },
    productsFilter: (state, { products }) => {
      return {
        ...state,
        productsFilter: products,
        error: null,
        isLoading: false,
        crudLoading: false,
      };
    },
    appendProductsFilter: (state, products) => {
      let updatedProductFilter = state.productsFilter.concat({ ...products });
      return {
        ...state,
        productsFilter: updatedProductFilter,
        error: null,
        isLoading: false,
        crudLoading: false,
      };
    },
    error: (state, { error }) => {
      return {
        ...state,
        products: null,
        product: null,
        total: 0,
        isLoading: false,
        crudLoading: false,
        error: error.message,
      };
    },
    resetProducts: (state) => {
      return { ...state, products: null, total: 0 };
    },
    resetProduct: (state, {}) => {
      return { ...state, product: null };
    },
    resetProductMeta: (state) => {
      return {
        productMeta: null,
        productsMeta: null,
        productMetaFilter: null,
        product: null,
        products: null,
      };
    },
    stockFilter: (state, { stockFilter, total }) => {
      return {
        ...state,
        stockFilter,
        total,
        isLoading: false,
        crudLoading: false,
        error: null,
      };
    },
  },
  effects: (dispatch) => ({
    getProductsByPage: async ({ page, limit, filter, categoryId }, state) => {
      try {
        let { branchId } = state.login;
        dispatch.products.loading({ isLoading: true });
        const response = await ProductsNetwork.getProductsByPage(
          page,
          limit,
          branchId,
          filter,
          categoryId
        );
        if (response.status !== 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        const { rows: products, count: total } = response.data.data;
        dispatch.products.products({ products, total });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          error.response.data.message,
          2
        );
      }
    },
    productById: async ({ id, index }, state) => {
      try {
        let { branchId } = state.login;
        dispatch.products.loading({ isLoading: true });
        const response = await ProductsNetwork.productById(id, branchId);
        if (response.status !== 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.products.product({ product: response.data.data, index });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          error.response.data.message,
          2
        );
      }
    },
    filterProducts: async ({ filter }, state) => {
      try {
        let { branchId } = state.login;
        let response;
        dispatch.products.loading({ isLoading: true });
        if (isValidString(filter) && filter.length > 0) {
          response = await ProductsNetwork.filterProducts(filter, branchId);
          if (response.status !== 200) {
            return errorToastr(
              COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
              COMMON_STRINGS.API_ERROR.SERVER_FAILED,
              2
            );
          }
          dispatch.products.productsFilter({ products: response.data.data });
        } else {
          response = await ProductsNetwork.getProductsByPage(
            1,
            20,
            branchId,
            null,
            null
          );
          if (response.status !== 200) {
            return errorToastr(
              COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
              COMMON_STRINGS.API_ERROR.SERVER_FAILED,
              2
            );
          }
          dispatch.products.productsFilter({
            products: response.data.data.rows,
          });
        }
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          error.response.data.message,
          2
        );
      }
    },
    addProducts: async ({ data, page, limit }, state) => {
      try {
        let { branchId } = state.login;
        data["branchId"] = branchId;
        dispatch.products.crudLoading({ isLoading: true });
        const response = await ProductsNetwork.addProduct(data);
        if (response.status != 200) {
          dispatch.products.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.CATEGORIES,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.products.getProductsByPage({ page, limit });
        return successToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          COMMON_STRINGS.CRUD_MESSAGE.ADD_DATA("Products"),
          2
        );
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          error.response.data.message,
          2
        );
      }
    },
    updateProducts: async ({ data, page, limit }, state) => {
      try {
        let { branchId } = state.login;
        data["branchId"] = branchId;
        dispatch.products.crudLoading({ isLoading: true });
        const response = await ProductsNetwork.updateProduct(data);
        if (response.status != 200) {
          dispatch.products.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.products.getProductsByPage({ page, limit });
        return successToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          COMMON_STRINGS.CRUD_MESSAGE.UPDATE_DATA("Products"),
          2
        );
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          error.response.data.message,
          2
        );
      }
    },
    getProductMetaByPage: async ({ page, limit }, state) => {
      try {
        let { branchId } = state.login;
        dispatch.products.loading({ isLoading: true });
        const response = await ProductsNetwork.productMetaByPage(
          page,
          limit,
          branchId
        );
        if (response.status !== 200) {
          dispatch.products.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        const { rows: productsMeta, count: total } = response.data.data;
        dispatch.products.productsMeta({ productsMeta, total });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          error?.response?.data.message,
          2
        );
      }
    },
    getProductsMetaByFilter: async (data, state) => {
      try {
        let { branchId } = state.login;
        data["branchId"] = branchId;
        let response;
        dispatch.products.loading({ isLoading: true });
        if (isValidString(data.filter)) {
          response = await ProductsNetwork.productMetaByFilter(data);
          if (response.status != 200) {
            dispatch.products.error({ error: "Server failed to load" });
            return errorToastr(
              COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
              COMMON_STRINGS.API_ERROR.SERVER_FAILED,
              2
            );
          }
          dispatch.products.productMetaFilter({
            productMetaFilter: response.data.data,
          });
        } else {
          response = await ProductsNetwork.productMetaByPage(1, 100, branchId);
          if (response.status != 200) {
            dispatch.products.error({ error: "Server failed to load" });
            return errorToastr(
              COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
              COMMON_STRINGS.API_ERROR.SERVER_FAILED,
              2
            );
          }
          dispatch.products.productMetaFilter({
            productMetaFilter: response.data.data.rows,
          });
        }
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          error?.response?.data.message,
          2
        );
      }
    },
    getProductMetaById: async (data, state) => {
      try {
        dispatch.products.loading({ isLoading: true });
        const response = await ProductsNetwork.productMetaById(data);
        if (response.status != 200) {
          dispatch.products.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.products.loading({ isLoading: false });
        response.data.data["index"] = data.index;
        // dispatch.products.productMeta({ productMeta: response.data.data })
        return response.data.data;
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          error?.response?.data.message,
          2
        );
      }
    },
    getProductMetaByProduct: async ({ id, page, limit, data }, state) => {
      try {
        let { branchId } = state.login;
        dispatch.products.loading({ isLoading: true });
        const response = await ProductsNetwork.productMetaByProduct(
          id,
          page,
          limit,
          branchId,
          data
        );
        if (response.status !== 200) {
          dispatch.products.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        const { rows: productsMeta, count: total } = response.data.data;
        dispatch.products.productsMeta({ productsMeta, total });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          error?.response?.data.message,
          2
        );
      }
    },
    stockFilterByPage: async ({ page, limit, data }, state) => {
      try {
        let { branchId } = state.login;
        dispatch.products.loading({ isLoading: true });
        const response = await ProductsNetwork.stockFilter(
          page,
          limit,
          branchId,
          data
        );
        if (response.status !== 200) {
          dispatch.products.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        const { rows: stockFilter, count: total } = response.data.data;
        dispatch.products.stockFilter({ stockFilter, total });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          error?.response?.data.message,
          2
        );
      }
    },
    deleteProduct: async ({ data, page, limit }, state) => {
      try {
        let { branchId } = state.login;
        data["branchId"] = branchId;
        const response = await ProductsNetwork.deleteProduct(data);
        if (response.status != 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.products.getProductsByPage({ page, limit, filter: null });
        return successToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          COMMON_STRINGS.CRUD_MESSAGE.DELETE_DATA("Product"),
          2
        );
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          error?.response?.data.message,
          2
        );
      }
    },
    getProductsFromAllStore: async ({ productId }, state) => {
      try {
        let { branchId } = state.login;
        const response = await ProductsNetwork.getProductsFromAllStore(
          productId,
          branchId
        );
        if (response.status != 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.DASHBORAD,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        return response.data;
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.DASHBORAD,
          error?.response?.data.message,
          2
        );
      }
    },
    addProductsMeta: async ({ data, page, limit, filter }, state) => {
      try {
        dispatch.products.crudLoading({ isLoading: true });
        const response = await ProductsNetwork.addProductMeta(data);
        dispatch.products.crudLoading({ isLoading: false });
        if (response.status != 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }

        dispatch.products.getProductMetaByProduct({
          id: data?.productId,
          page,
          limit,
          data: filter,
        });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          error?.response?.data.message,
          2
        );
      }
    },
    updateProductsMeta: async ({ data, page, limit, filter }, state) => {
      try {
        dispatch.products.crudLoading({ isLoading: true });
        const response = await ProductsNetwork.updateProductMeta(data);
        dispatch.products.crudLoading({ isLoading: false });
        if (response.status != 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.products.getProductMetaByProduct({
          id: data?.productId,
          page,
          limit,
          data: filter,
        });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          error?.response?.data.message,
          2
        );
      }
    },
    filterProductForReports: async (
      { filter = null, categoryId = null },
      state
    ) => {
      try {
        let { branchId } = state.login;
        let response = await ProductsNetwork.filterProducts(
          filter,
          branchId,
          categoryId
        );
        if (response.status != 200) {
          return [];
        }
        return response.data.data;
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.PRODUCTS,
          error?.response?.data.message,
          2
        );
        return [];
      }
    },
  }),
};

export default productsModel;
