import { Col, Row, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Appbar from "../../../Components/Appbar/Appbar";
import MyCard from "../../../Components/Card/MyCard";
import MyTable from "../../../Components/Table/MyTable";
import { SubStoresSupplyBreadCrumb } from "../Constants";
import { crudBasedAuth } from "../../../Utils/Authorization";
import { isValidElement } from "../../../Utils/helpers";
import { branchIdState, userState } from "../../../store/selectors";
import { MODULE, OPERATION } from "../../../Utils/Constants";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Container/Navigation/Constants";
import { deleteConfirmationModal } from "../../../Components/ConfirmModal/ConfirmModal";
import { COMMON_STRINGS } from "../../../Utils/Strings";

export default function SubStoreSupply() {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    let userSelector = userState();
    let branchIdSelector = branchIdState();

    let [userPermissionSelector] = isValidElement(userSelector) && userSelector?.users_role?.users_permissions.filter(permission => permission.moduleName === MODULE.MASTER && permission.branchId === branchIdSelector);


    const { isLoading, subStoreSupplies, total } = useSelector((state: any) => ({
        isLoading: state.subStores.isLoading,
        subStoreSupplies: state.subStores.subStoreSupplies,
        total: state.subStores.total
    }));

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        dispatch.subStores.getSubStoreProductsForDropdown({});
    }, []);

    useEffect(() => {
        dispatch.subStores.getSubStoreSupplies({ page, limit });
        return function cleanUp() {
            dispatch.subStores.resetSubStoresSupplies({})
        }
    }, [page, limit]);

    const pageHandler = (page, pageSize) => {
        setPage(page);
        setLimit(pageSize);
    };

    const onDeleteHandler = (data) => {
        return deleteConfirmationModal({
            title:  COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
            content: COMMON_STRINGS.CRUD_MESSAGE.DELETE_CONFIRM,
            onOkHandler: () => dispatch.subStores.deleteSubStoreSupply({ id: data.id, page, limit }),
            onCancelHandler: () => { }
        })
    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => {
                return <span>{moment(record.date).format('DD-MM-YYYY')}</span>
            }
        },
        {
            title: 'Pin No',
            dataIndex: 'pinNo',
            key: 'pinNo',
        },
        {
            title: 'Department',
            dataIndex: 'departmentName',
            key: 'departmentName',
            render: (text, record) => {
                return <span>{record?.department?.name}</span>
            }
        },
        {
            title: 'Assigned By',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return (
                    <>
                        <span>Name: {record?.assignedUser?.name}</span>
                        <br />
                        <span>Email: {record?.assignedUser?.email}</span>
                        <br />
                        <span>Mobile: {record?.assignedUser?.mobile}</span>
                    </>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <Space>
                        {crudBasedAuth(userSelector?.users_role, userPermissionSelector, OPERATION.GET) && <a onClick={() => navigate(`view/${record.id}`, { state: { data: record } })}>Edit</a>}
                        {crudBasedAuth(userSelector?.users_role, userPermissionSelector, OPERATION.DELETE) && <a onClick={() => onDeleteHandler(record)}>Delete</a>}
                    </Space>
                )
            }
        }
    ];


    return (
        <>
            <MyCard>
                <Appbar
                    showAddButton={true}
                    title="Add Supply"
                    onButtonHandler={() => navigate(routes.SUB_STORE_SUPPLY_FORM_ADD)}
                    breadcrumbs={SubStoresSupplyBreadCrumb}
                />
                <br />
                <Row>
                    <Col span={24}>
                        <Skeleton loading={isLoading}>
                            <MyTable rows={subStoreSupplies} columns={columns}
                                pagination={{
                                    total,
                                    current: page,
                                    pageSize: limit,
                                    onChange: pageHandler,
                                }}
                            />
                        </Skeleton>
                    </Col>
                </Row>
            </MyCard>
        </>
    )
}
