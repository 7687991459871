import { routes } from "../../../Container/Navigation/Constants";

export const PurchaseBreadCrumb = [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: "Local Purchase", path: routes.PURCHASE_ROUTE }
];

export const PurchaseNewBreadCrumb = (isEdit) => [
        { label: "Dashboard", path: routes.BASE_ROUTE },
        { label: "Local Purchase", path: routes.PURCHASE_ROUTE },
        { label: !!isEdit ? "Update Stock" : "New Stock", path: !isEdit && routes.ADD_PURCHASE_ROUTE }
    ];