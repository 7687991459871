import { LocalPurchaseNetwork } from "../../../API/Networks";
import {
  errorToastr,
  successToastr,
} from "../../../Components/Toastr/MyToastr";
import { PURCHASE_TYPES } from "../../../Utils/Constants";
import { jwtExpired } from "../../../Utils/helpers";
import { COMMON_STRINGS } from "../../../Utils/Strings";

const localpurchaseModel = {
  state: {
    isLoading: false,
    crudLoading: false,
    error: null,
    localPurchase: null,
    total: 0,
    purchase: null,
    insurance: null,
  },
  reducers: {
    loading: (state, { isLoading }) => {
      return { ...state, isLoading };
    },
    crudLoading: (state, { isLoading }) => {
      return { ...state, crudLoading: isLoading };
    },
    localPurchase: (state, { localPurchase, total }) => {
      return {
        ...state,
        localPurchase,
        total,
        isLoading: false,
        error: null,
        crudLoading: false,
      };
    },
    purchase: (state, { purchase }) => {
      return {
        ...state,
        purchase,
        isLoading: false,
        error: null,
        crudLoading: false,
      };
    },
    insurance: (state, { insurance }) => {
      return { ...state, insurance };
    },
    error: (state, { error }) => {
      return {
        ...state,
        localPurchase: null,
        total: 0,
        isLoading: false,
        crudLoading: false,
        error: error.message,
      };
    },
    resetState: (state, {}) => {
      return {
        isLoading: false,
        crudLoading: false,
        error: null,
        localPurchase: null,
        total: 0,
        purchase: null,
      };
    },
  },
  effects: (dispatch) => ({
    getLocalPurchaseByPage: async ({ page, limit, filter }, state) => {
      try {
        let { branchId } = state.login;
        dispatch.localPurchase.loading({ isLoading: true });
        const response = await LocalPurchaseNetwork.getAllLPByPage(
          page,
          limit,
          branchId,
          PURCHASE_TYPES.EXTERNAL_PURCHASE,
          filter
        );
        if (response.status !== 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        const { rows: localPurchase, count: total } = response.data.data;
        dispatch.localPurchase.localPurchase({ localPurchase, total });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.localPurchase.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
          error.response.data.message,
          2
        );
      }
    },
    addLocalPurchase: async ({ data }, state) => {
      try {
        let { branchId } = state.login;
        data["branchId"] = branchId;
        dispatch.localPurchase.crudLoading({ isLoading: true });
        const response = await LocalPurchaseNetwork.addLP(data);
        if (response.status !== 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.localPurchase.crudLoading({ isLoading: false });
        return response?.data?.data;
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.localPurchase.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
          error.response.data.message,
          2
        );
      }
    },
    updateLocalPurchase: async ({ data }, state) => {
      try {
        let { branchId } = state.login;
        data["branchId"] = branchId;
        dispatch.localPurchase.crudLoading({ isLoading: true });
        const response = await LocalPurchaseNetwork.updateLP(data);
        if (response.status !== 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.localPurchase.crudLoading({ isLoading: false });
        return successToastr(
          COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
          COMMON_STRINGS.CRUD_MESSAGE.UPDATE_DATA("Local Purchase"),
          2
        );
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.localPurchase.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
          error.response.data.message,
          2
        );
      }
    },
    getLocalPurchaseById: async ({ id }, state) => {
      try {
        let { branchId } = state.login;
        dispatch.localPurchase.loading({ isLoading: true });
        const response = await LocalPurchaseNetwork.getPurchaseById(
          id,
          branchId,
          PURCHASE_TYPES.EXTERNAL_PURCHASE
        );
        if (response.status !== 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.localPurchase.purchase({ purchase: response.data.data });
        return response.data.data;
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.localPurchase.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
          error.response.data.message,
          2
        );
      }
    },
    deleteTransaction: async ({ id }, state) => {
      try {
        let { branchId } = state.login;
        dispatch.localPurchase.crudLoading({ isLoading: true });
        const response = await LocalPurchaseNetwork.deleteTransaction(
          id,
          branchId
        );
        if (response.status !== 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.localPurchase.crudLoading({ isLoading: false });
        return successToastr(
          COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
          COMMON_STRINGS.CRUD_MESSAGE.DELETE_DATA("Transaction"),
          2
        );
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.localPurchase.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
          error.response.data.message,
          2
        );
      }
    },
    getAllInsurance: async (data, state) => {
      try {
        let response = await LocalPurchaseNetwork.getAllInsuranceList();
        if (response.status !== 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.localPurchase.insurance({ insurance: response.data });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.localPurchase.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
          error.response.data.message,
          2
        );
      }
    },
  }),
};

export default localpurchaseModel;
