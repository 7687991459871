import React, { useCallback, useEffect, useState } from "react";
//@ts-ignore
import { Col, Divider, Input, Row, Skeleton, Space } from "antd";
import Appbar from "../../../Components/Appbar/Appbar";
import MyCard from "../../../Components/Card/MyCard";
import { OgrBreadCrumb } from "./Constants";
import MyTable from "../../../Components/Table/MyTable";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Container/Navigation/Constants";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { branchIdState, userState } from "../../../store/selectors";
import {
  debounce,
  isValidElement,
  isValidString,
} from "../../../Utils/helpers";
import { MODULE, OPERATION, PAGING } from "../../../Utils/Constants";
import { crudBasedAuth } from "../../../Utils/Authorization";
import { deleteConfirmationModal } from "../../../Components/ConfirmModal/ConfirmModal";
import { COMMON_STRINGS } from "../../../Utils/Strings";

type Props = {};

function InternalPurchase(props: Props) {
  let userSelector = userState();
  let branchIdSelector = branchIdState();
  let [userPermissionSelector] =
    isValidElement(userSelector) &&
    userSelector?.users_role?.users_permissions.filter(
      (permission) =>
        permission.moduleName === MODULE.INVENTORY &&
        permission.branchId === branchIdSelector
    );

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, internalPurchase, total } = useSelector((state: any) => ({
    isLoading: state.internalPurchase.isLoading,
    internalPurchase: state.internalPurchase.internalPurchase,
    total: state.internalPurchase.total,
  }));

  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    dispatch.products.filterProducts({});
  }, []);

  useEffect(() => {
    if (filter.length) {
      dispatch.internalPurchase
        .getInternalPurchaseByPage({ page, limit, filter })
        .then((r) => {});
    } else {
      dispatch.internalPurchase
        .getInternalPurchaseByPage({ page, limit })
        .then((r) => {});
    }
  }, [page, limit, filter]);

  const pageHandler = useCallback(
    (page, pageSize) => {
      setPage(page);
      setLimit(pageSize);
    },
    [page, limit]
  );

  const onDeleteHandler = (data) => {
    return deleteConfirmationModal({
      title: COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE,
      content: COMMON_STRINGS.CRUD_MESSAGE.DELETE_CONFIRM,
      onOkHandler: () =>
        dispatch.internalPurchase.deletePurchase({ id: data?.id, page, limit }),
      onCancelHandler: () => {},
    });
  };

  const debouncedOnSearchText = useCallback(
    debounce((searchText) => {
      if (page !== PAGING.page) {
        setPage(PAGING.page);
        setLimit(PAGING.limit);
      }
      setFilter(searchText);
    }, 800),
    [page, limit]
  );

  const filterPurchase = () => {
    return (
      <div>
        <h3>Filter</h3>
        <Space split={<Divider type="vertical" />}>
          <Input
            key="input-category"
            allowClear
            style={{ width: 200, marginRight: 20 }}
            placeholder="Search"
            onChange={(e) => debouncedOnSearchText(e.target.value)}
          />
        </Space>
      </div>
    );
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <span>{moment(record.date).format("DD-MM-YYYY")}</span>
      ),
    },
    {
      title: "OGR No.",
      dataIndex: "ogrNo",
      key: "ogrNo",
    },
    {
      title: "PB No.",
      dataIndex: "pbNo",
      key: "pbNo",
    },
    {
      title: "PB Name",
      dataIndex: "pbName",
      key: "pbName",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space>
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.GET
            ) && (
              <a
                onClick={() =>
                  navigate(`edit/${record.id}`, { state: { data: record } })
                }
              >
                View
              </a>
            )}
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.PUT
            ) && (
              <a
                onClick={() =>
                  navigate(`edit/${record.id}`, { state: { data: record } })
                }
              >
                Edit
              </a>
            )}
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.DELETE
            ) && <a onClick={() => onDeleteHandler(record)}>Delete</a>}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <MyCard>
        <Appbar
          title="TNMSC"
          onButtonHandler={() => navigate(routes.ADD_OGR_ROUTE)}
          breadcrumbs={OgrBreadCrumb}
        />
        {filterPurchase()}
        <br />
        <Row>
          <Col span={24}>
            <Skeleton loading={isLoading}>
              <MyTable
                rows={internalPurchase}
                columns={columns}
                pagination={{
                  total,
                  current: page,
                  pageSize: limit,
                  onChange: pageHandler,
                }}
              />
            </Skeleton>
          </Col>
        </Row>
      </MyCard>
    </>
  );
}

export default InternalPurchase;
