import React from "react";
import "./styles.css";
import { TN_LOGO } from "../../Utils/Constants";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import MyError from "../../Components/MyError/MyError";
import { resetPasswordValidation } from "../../Utils/FormValidations";
import { errorToastr } from "../../Components/Toastr/MyToastr";
import { COMMON_STRINGS } from "../../Utils/Strings";
import { useDispatch } from "react-redux";
type Props = {};
let domain = window.location.hostname;
export default function ResetPassword(props: Props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let configName = domain === "localhost" ? "rgggh" : domain.split(".")[0];

  let pathArray = location.pathname.split("/");

  const formik = useFormik({
    initialValues: {
      token: pathArray[pathArray.length - 1],
      configName: configName,
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object(resetPasswordValidation),
    onSubmit: (values) => {},
  });

  const submitHandler = () => {
    let { password, confirmPassword } = formik.values;
    if (!formik.isValid || !formik.dirty) {
      return errorToastr(
        COMMON_STRINGS.MODEL_MODULE.RESET_PASSWORD,
        "Form is not valid",
        2
      );
    }
    if (password !== confirmPassword) {
      return errorToastr(
        COMMON_STRINGS.MODEL_MODULE.RESET_PASSWORD,
        "Password does not match",
        2
      );
    }
    dispatch.login.resetPassword({ data: formik.values, navigate });
  };

  return (
    <div className="main-container">
      <section className="area-login">
        <h1>Medical Inventory</h1>
        <div className="login">
          <div>
            <img alt="logo" src={TN_LOGO} />
          </div>
          <div>
            <Input.Password
              name="password"
              className="input"
              placeholder="New password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <MyError error={JSON.stringify(formik.errors.password)} />
            ) : null}
            <Input.Password
              name="confirmPassword"
              className="input"
              placeholder="Confirm password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <MyError error={JSON.stringify(formik.errors.confirmPassword)} />
            ) : null}
            <Button
              onClick={submitHandler}
              className="input"
              type="primary"
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </div>
          <a className="input" href="/login">
            Forgot password?
          </a>
        </div>
      </section>
    </div>
  );
}
