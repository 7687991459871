import React, { useCallback, useEffect, useState } from "react";
//@ts-ignore
import { Col, Row, Skeleton, Space } from "antd";
import { UsersBreadCrumb } from "./Constants";
import UserModal from "./Components/UserModal";
import MyCard from "../../Components/Card/MyCard";
import Appbar from "../../Components/Appbar/Appbar";
import MyTable from "../../Components/Table/MyTable";
import MyIcon from "../../Components/MyIcon/MyIcon";
import Palette from "../../Utils/Palette";
import { useDispatch, useSelector } from "react-redux";
import { isValidElement } from "../../Utils/helpers";
import {
  branchIdState,
  departmentIdState,
  userState,
} from "../../store/selectors";
import { MODULE, OPERATION } from "../../Utils/Constants";
import {
  crudBasedAuth,
  isAdmin,
  isSuperAdmin,
} from "../../Utils/Authorization";
import { routes } from "../../Container/Navigation/Constants";

type Props = {};

const isDepartmentPage = window.location.pathname.includes(
  routes.DEPARTMENT_USERS_ROUTE
);
function Users(props: Props) {
  let userSelector = userState();
  let branchIdSelector = branchIdState();
  let departmentIdSelector = departmentIdState();

  let [userPermissionSelector] =
    isValidElement(userSelector) &&
    userSelector?.users_role?.users_permissions.filter(
      (permission) =>
        permission.moduleName === MODULE.USERS &&
        permission.branchId === branchIdSelector
    );

  const dispatch = useDispatch();

  const { isLoading, users, total } = useSelector((state: any) => ({
    isLoading: state.users.isLoading,
    users: state.users.users,
    total: state.users.total,
  }));

  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [editForm, setEditForm] = useState(null);
  const [departmentModal, setDepartmentModal] = useState(false);

  useEffect(() => {
    return function cleanUp() {
      dispatch.departments.resetDepartments();
    };
  }, []);

  useEffect(() => {
    dispatch.users.getUsersByPage({ page, limit });
    dispatch.users.getAllUserRole({});
    dispatch.departments.getDepartmentsByPage({ page: 1, limit: 500 });
  }, [page, limit]);

  const toggleModal = useCallback(() => {
    setModalVisible(!modalVisible);
  }, [modalVisible]);

  const editHandler = useCallback(
    ({ ...record }) => {
      record["isDepartmentUser"] = !!record?.departmentId;
      setModalVisible(true);
      setEditForm(record);
    },
    [modalVisible, editForm]
  );

  const resetPasswordHandler = (data) => {
    let { email } = data;
    dispatch.users.resetPassword({ email });
  };

  const resetEditForm = () => {
    setEditForm(null);
  };

  const pageHandler = (page, pageSize) => {
    setPage(page);
    setLimit(pageSize);
  };

  const onAddUserButtonHandler = useCallback(
    (modalType) => {
      modalType === "department"
        ? setDepartmentModal(true)
        : setDepartmentModal(false);
      toggleModal();
    },
    [departmentModal, modalVisible]
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Role",
      dataIndex: "users_role.name",
      key: "users_role.name",
      render: (text, record) => {
        return (
          <>
            <span>Role: {record?.users_role?.name}</span>
            <br />
            {record?.department?.name && (
              <span>Department: {record?.department?.name}</span>
            )}
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (text, record) => {
        return record.isActive ? (
          <MyIcon color={Palette.green} iconName="fal fa-check-circle" />
        ) : (
          <MyIcon color={Palette.red} iconName="fal fa-times-circle" />
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space>
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.PUT
            ) && <a onClick={() => editHandler(record)}>Edit</a>}
            {/* {crudBasedAuth(userSelector?.users_role, userPermissionSelector, OPERATION.DELETE) && <a>Delete</a>} */}
            {crudBasedAuth(
              userSelector?.users_role,
              userPermissionSelector,
              OPERATION.PUT
            ) && (
              <a onClick={() => resetPasswordHandler(record)}>Reset Password</a>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <MyCard>
        <Appbar
          title="Users"
          showAddButton={
            isSuperAdmin(userSelector?.users_role?.name) ||
            isAdmin(userSelector?.users_role?.name)
          }
          onButtonHandler={() => onAddUserButtonHandler("admin")}
          breadcrumbs={UsersBreadCrumb}
          showSecondayButton={
            isSuperAdmin(userSelector?.users_role?.name) ||
            (isAdmin(userSelector?.users_role?.name) && !isDepartmentPage)
          }
          secondaryTitle="Department Users"
          secondaryButtonHandler={() => onAddUserButtonHandler("department")}
        />
        <br />
        <Row>
          <Col span={24}>
            <Skeleton loading={isLoading}>
              <MyTable
                rows={users}
                columns={columns}
                pagination={{
                  total,
                  current: page,
                  pageSize: limit,
                  onChange: pageHandler,
                }}
              />
            </Skeleton>
          </Col>
        </Row>
      </MyCard>
      {modalVisible && (
        <UserModal
          visible={modalVisible}
          toggleModal={toggleModal}
          editForm={editForm}
          resetEditForm={resetEditForm}
          page={page}
          limit={limit}
          departmentModal={departmentModal}
          departmentId={departmentIdSelector}
        />
      )}
    </>
  );
}

export default Users;
