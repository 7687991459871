import * as Yup from "yup";

const IND_MOBILE_REGEX = /^((0091)|(\+91)|0?)[789]{1}\d{9}$/;
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const departmentValidations = {
  name: Yup.string().trim().required("Department Name is required"),
  departmentNo: Yup.string().trim().required("Department No. is required"),
  isActive: Yup.boolean().required("Status is required"),
};

export const categoryValidations = {
  name: Yup.string().required("Category name is required"),
  isActive: Yup.boolean().required("Status is required"),
};

export const internalPurchaseValidations = {
  ogrNo: Yup.string().required("OGR No. is required"),
  date: Yup.string().required("Date is required"),
  pbNo: Yup.string().required("P.B No. is required"),
  pbName: Yup.string().required("P.B Name is required"),
  purchaseItems: Yup.array().of(
    Yup.object().shape({
      batchNo: Yup.string().required("Batch No. is required"),
      productId: Yup.string().required("Item name is required"),
      qty: Yup.string().required("Qty is required"),
      manufacturerName: Yup.string().required("Manufacturer is required"),
      manufacturerDate: Yup.string().required("Man. date is required"),
      expiryDate: Yup.string().required("Expiry date is required"),
    })
  ),
};

export const localPurchaseValidations = {
  date: Yup.string().required("Date is required"),
  dcNo: Yup.string().required("D.C No. is required"),
  supplierId: Yup.string().required("Supplier is required"),
  purchaseItems: Yup.array().of(
    Yup.object().shape({
      batchNo: Yup.string().required("Batch No. is required"),
      productId: Yup.string().required("Item name is required"),
      manufacturerName: Yup.string().required("Manufacturer is required"),
      manufacturerDate: Yup.string().required("Man. date is required"),
      expiryDate: Yup.string().required("Expiry date is required"),
    })
  ),
};

export const supplyValidations = {
  date: Yup.string().required("Date is required"),
  indentNo: Yup.string().required("Indent No. is required"),
  departmentId: Yup.string().required("Department is required"),
  supplyItems: Yup.array().of(
    Yup.object().shape({
      batchNo: Yup.string().required("Batch No. is required"),
      productsMetaId: Yup.number().required("Item name is required"),
      availableQty: Yup.number().required("Avail. Qty is required"),
      reqQty: Yup.number().required("Req Qty is required"),
      supplyQty: Yup.number().required("Supply Qty is required"),
    })
  ),
};

export const subStoreSupplyValidation = {
  date: Yup.string().required("Date is required"),
  pinNo: Yup.string().required("Pin No. is required"),
  supplyItems: Yup.array().of(
    Yup.object().shape({
      productId: Yup.string().required("Item name is required - productId"),
      productsMetaId: Yup.number().required(
        "Item name is required - productMetaId"
      ),
      supplyQty: Yup.number().required("Supply Qty is required"),
      supplyId: Yup.number().required("SupplyId is missing"),
    })
  ),
};

export const profileValidations = {
  email: Yup.string()
    .required("Email is required")
    .matches(EMAIL_REGEX, "Email is not valid"),
  name: Yup.string().required("Name is required"),
  mobile: Yup.string()
    .required("Mobile is required")
    .matches(IND_MOBILE_REGEX, "Phone no. is not valid"),
};

export const loginValidations = {
  email: Yup.string()
    .required("Email is required")
    .matches(EMAIL_REGEX, "Email is not valid"),
  password: Yup.string().required("Password is required"),
};

export const resetPasswordValidation = {
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string().required("Confirm password is required"),
};

export const forgotpasswordValidations = {
  email: Yup.string()
    .required("Email is required")
    .matches(EMAIL_REGEX, "Email is not valid"),
};

export const vendorValidations = {
  name: Yup.string().required("Supplier name is required"),
  address: Yup.string().required("Address is required"),
};

export const usersValidations = {
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .required("Email is required")
    .matches(EMAIL_REGEX, "Email is not valid"),
  mobile: Yup.string()
    .required("Mobile is required")
    .matches(IND_MOBILE_REGEX, "Phone no. is not valid"),
  isActive: Yup.boolean().required("Status is required"),
  usersRoleId: Yup.number().required("User Role is required"),
};

export const reportsValidations = {
  branchId: Yup.string().required("Branch is required"),
};

export const productsValidations = {
  categoryId: Yup.number().required("Category is required"),
  codeNo: Yup.string().required("Code No is required"),
  name: Yup.string().required("Item name is required"),
  isActive: Yup.boolean().required("Status is required"),
};

export const subStoreAdjStockValidation = {
  adjStock: Yup.number().required("Stock is required"),
};

export const productsMetaValidation = {
  codeNo: Yup.string().required("Code No. is required"),
  name: Yup.string().required("Item name is required"),
  batchNo: Yup.string().required("Batch No. is required"),
  qty: Yup.number().required("Qty is required"),
  manufacturerName: Yup.string().required("Man. name is required"),
  manufacturerDate: Yup.string().required("Man. date is required"),
  expiryDate: Yup.string().required("Exp. date is required"),
};
