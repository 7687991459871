import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
} from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import Appbar from "../../../../Components/Appbar/Appbar";
import MyCard from "../../../../Components/Card/MyCard";
import { localPurchaseValidations } from "../../../../Utils/FormValidations";
import {
  debounce,
  isValidArray,
  isValidElement,
  isValidForm,
  isValidString,
} from "../../../../Utils/helpers";
import { PurchaseNewBreadCrumb } from "../Constants";
import EditableTable from "../../../../Components/EditableTable/EditableTable";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../../Container/Navigation/Constants";
import { MODULE, PURCHASE_TYPES } from "../../../../Utils/Constants";
import { COMMON_STRINGS } from "../../../../Utils/Strings";
import { branchIdState, userState } from "../../../../store/selectors";
import {
  errorToastr,
  successToastr,
} from "../../../../Components/Toastr/MyToastr";

const { Option } = Select;

export default function LocalPurchaseForm() {
  const navigate = useNavigate();

  const location = useLocation();

  const dispatch = useDispatch();

  let userSelector = userState();
  let branchIdSelector = branchIdState();
  let [userPermissionSelector] =
    isValidElement(userSelector) &&
    userSelector?.users_role?.users_permissions.filter(
      (permission) =>
        permission.moduleName === MODULE.INVENTORY &&
        permission.branchId === branchIdSelector
    );

  const { vendorsFilter } = useSelector((state: any) => ({
    vendorsFilter: state.vendors.vendorsFilter,
  }));

  const { productsFilter } = useSelector((state: any) => ({
    productsFilter: state.products.productsFilter,
  }));

  const { crudLoading, isLoading, insurance } = useSelector((state: any) => ({
    crudLoading: state.localPurchase.crudLoading,
    isLoading: state.localPurchase.isLoading,
    insurance: state.localPurchase.insurance,
  }));

  const formik = useFormik({
    initialValues: {
      purchaseTypeId: PURCHASE_TYPES.EXTERNAL_PURCHASE,
      date: null,
      purchaseInvoiceNo: "",
      purchaseOrderNo: "",
      brNo: "",
      supplierId: null,
      dcNo: "",
      insuranceId: null,
      purchaseItems: [].concat(Object.assign({ itemNameRef: null })),
    },
    validationSchema: Yup.object(localPurchaseValidations),
    onSubmit: (values) => {},
  });

  const [isEdit, setIsEdit] = useState(
    isValidElement(location.state) ? true : false
  );
  const [searchVendorText, setSearchVendorText] = useState("");
  const [searchItemText, setSearchItemText] = useState("");
  const [addEditResponse, setAddEditResponse] = useState(null);

  useEffect(() => {
    if (isValidString(searchVendorText) && searchVendorText.length > 0) {
      dispatch.vendors.filterVendor({ filter: searchVendorText });
    } else {
      dispatch.vendors.filterVendor({});
    }
  }, [searchVendorText]);

  useEffect(() => {
    if (isValidString(searchItemText) && searchItemText.length > 0) {
      dispatch.products.filterProducts({ filter: searchItemText });
    } else {
      dispatch.products.filterProducts({});
    }
  }, [searchItemText]);

  useEffect(() => {
    const data = !!location.state
      ? JSON.parse(JSON.stringify(location.state))
      : null;
    if (isValidElement(data) && isValidElement(data.data)) {
      dispatch.localPurchase
        .getLocalPurchaseById({ id: data.data.id })
        .then((data) => {
          formik.setValues({
            purchaseTypeId: PURCHASE_TYPES.EXTERNAL_PURCHASE,
            date: moment(data.date),
            purchaseInvoiceNo: data.purchaseInvoiceNo,
            brNo: data.brNo,
            purchaseOrderNo: data.purchaseOrderNo,
            supplierId: data?.supplierId,
            insuranceId: isValidElement(data?.insuranceId)
              ? data.insuranceId
              : null,
            dcNo: data.dcNo,
            purchaseItems: data.purchase_transactions.map((transaction) => {
              let data = {};
              let isExits =
                Array.isArray(productsFilter) &&
                productsFilter.length > 0 &&
                productsFilter.find((product, index) => {
                  return product.id === transaction.productId;
                });

              if (!isExits) {
                dispatch.products.appendProductsFilter({
                  id: transaction.productId,
                  name: transaction.product.name,
                  codeNo: transaction.product.codeNo,
                  branchId: transaction.product.branchId,
                  isActive: transaction.product.isActive,
                  categoryId: transaction.product.categoryId,
                  price: transaction.products_metum.price,
                });
              }

              data["codeNo"] = transaction.product.codeNo;
              data["name"] = transaction.product.name;
              data["purchaseTransactionId"] = transaction.id;
              data["productMetaId"] = transaction.productsMetumId;
              data["qty"] = transaction.qty;
              data["productId"] = transaction.productId;
              data["batchNo"] = transaction.products_metum.batchNo;
              data["manufacturerName"] =
                transaction.products_metum.manufacturerName;
              data["manufacturerDate"] = moment(
                transaction.products_metum.manufacturerDate
              );
              data["expiryDate"] = moment(
                transaction.products_metum.expiryDate
              );
              data["price"] = transaction.products_metum.price;
              data["itemNameRef"] = null;

              return data;
            }),
          });
          formik.setFieldValue("id", data.id);
        });
    }
  }, [isEdit, crudLoading]);

  useEffect(() => {
    if (
      Array.isArray(formik.values.purchaseItems) &&
      formik.values.purchaseItems.length > 0
    ) {
      if (
        isValidElement(
          formik.values.purchaseItems[formik.values.purchaseItems.length - 1]
            .itemNameRef
        )
      ) {
        formik.values.purchaseItems[
          formik.values.purchaseItems.length - 1
        ].itemNameRef.focus();
      }
    } else {
      formik.setFieldValue(
        "purchaseItems",
        [].concat(Object.assign({ itemNameRef: null }))
      );
    }
  }, [formik.values.purchaseItems.length]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message = "Changes that you made may not be saved.";
    e.returnValue = message;
    return message;
  };

  const debouncedOnSearchText = useCallback(
    debounce((searchText) => {
      setSearchItemText(searchText);
    }, 800),
    [searchItemText]
  );

  const column = [
    {
      id: "sNo",
      label: "No.",
      render: (row, index) => {
        return index + 1;
      },
    },
    {
      id: "itemName",
      label: "Item Name",
      minWidth: 200,
      render: (row, index) => {
        return (
          <>
            <Select
              ref={(e) => {
                row["itemNameRef"] = e;
              }}
              placeholder="Select Item"
              value={formik.values.purchaseItems[index].productId}
              style={{
                minWidth: "100%",
                borderColor: isValidForm(formik, "productId", index) && "red",
              }}
              onBlur={formik.handleBlur}
              optionFilterProp="children"
              showSearch
              onChange={(value) => onSelectHandler("productId", value, index)}
              onSelect={(value, option) => {
                formik.setFieldValue(
                  `purchaseItems.${index}.codeNo`,
                  option.children[0]
                );
                formik.setFieldValue(
                  `purchaseItems.${index}.name`,
                  option.children[2]
                );
              }}
              onSearch={(search) => debouncedOnSearchText(search)}
            >
              {Array.isArray(productsFilter) &&
                productsFilter.length > 0 &&
                productsFilter.map((product) => {
                  return (
                    <Option key={product.id} value={product.id}>
                      {product.codeNo} - {product.name}
                    </Option>
                  );
                })}
            </Select>
          </>
        );
      },
    },
    {
      id: "batchNo",
      label: "Batch No",
      minWidth: 100,
      render: (row, index) => {
        return (
          <>
            <Input
              placeholder="Batch No."
              name={`purchaseItems.${index}.batchNo`}
              value={formik.values.purchaseItems[index].batchNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                borderColor: isValidForm(formik, "batchNo", index) && "red",
              }}
              onKeyPress={handleKeyPress}
            />
          </>
        );
      },
    },
    {
      id: "Price",
      label: "Price",
      minWidth: 100,
      render: (row, index) => {
        return (
          <>
            <Input
              placeholder="Price"
              name={`purchaseItems.${index}.price`}
              value={formik.values.purchaseItems[index].price}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                borderColor: isValidForm(formik, "price", index) && "red",
              }}
              onKeyPress={handleKeyPress}
            />
          </>
        );
      },
    },
    {
      id: "manufacturerName",
      label: "Manufacturer",
      minWidth: 200,
      render: (row, index) => {
        return (
          <>
            <Input
              placeholder="Manufacturer Name"
              name={`purchaseItems.${index}.manufacturerName`}
              value={formik.values.purchaseItems[index].manufacturerName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                borderColor:
                  isValidForm(formik, "manufacturerName", index) && "red",
              }}
              onKeyPress={handleKeyPress}
            />
          </>
        );
      },
    },
    {
      id: "manufacturerDate",
      label: "Man. Date",
      minWidth: 150,
      render: (row, index) => {
        return (
          <>
            <DatePicker
              name="manufacturerDate"
              picker="month"
              placeholder="Man. date"
              format="MM-YYYY"
              allowClear={false}
              value={formik.values.purchaseItems[index].manufacturerDate}
              onChange={(date, dateString) =>
                onDateHanlder(date, dateString, "manufacturerDate", index)
              }
              onBlur={formik.handleBlur}
              style={{
                borderColor:
                  isValidForm(formik, "manufacturerDate", index) && "red",
                width: 150,
              }}
            />
          </>
        );
      },
    },
    {
      id: "expiryDate",
      label: "Exp. Date",
      minWidth: 150,
      render: (row, index) => {
        return (
          <>
            <DatePicker
              name="expiryDate"
              picker="month"
              placeholder="Expiry date"
              format="MM-YYYY"
              allowClear={false}
              value={formik.values.purchaseItems[index].expiryDate}
              onChange={(date, dateString) =>
                onDateHanlder(date, dateString, "expiryDate", index)
              }
              onBlur={formik.handleBlur}
              style={{
                borderColor: isValidForm(formik, "expiryDate", index) && "red",
                width: 150,
              }}
            />
          </>
        );
      },
    },

    {
      id: "qty",
      label: "Qty",
      minWidth: 100,
      render: (row, index) => {
        return (
          <>
            <Input
              placeholder="Qty"
              type="number"
              name={`purchaseItems.${index}.qty`}
              value={formik.values.purchaseItems[index].qty}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{
                borderColor: isValidForm(formik, "batchNo", index) && "red",
              }}
              onKeyPress={handleKeyPress}
            />
          </>
        );
      },
    },
    {
      id: "action",
      label: "Action",
      textAlign: "center",
      render: (row, index) => {
        return (
          <>
            <Space size={2}>
              <Button type="primary" onClick={addRow}>
                +
              </Button>
              <Button
                type="primary"
                danger
                onClick={() => {
                  if (!!row.purchaseTransactionId) {
                    const { data } = isValidElement(location.state)
                      ? JSON.parse(JSON.stringify(location.state))
                      : null;
                    dispatch.localPurchase
                      .deleteTransaction({ id: row.purchaseTransactionId })
                      .then((r) => {
                        dispatch.localPurchase.getLocalPurchaseById({
                          id: data.id,
                        });
                      });
                  } else {
                    removeRow(index);
                  }
                }}
              >
                x
              </Button>
            </Space>
          </>
        );
      },
    },
  ];

  const onSelectHandler = (field, value, index) => {
    if (isNaN(index)) {
      formik.setFieldValue(field, value);
      return;
    }
    formik.setFieldValue(`purchaseItems.${index}.${field}`, value);
    dispatch.products.filterProducts({});
  };

  const onDateHanlder = (date, dateString, field, index) => {
    if (!index) {
      formik.setFieldValue(field, moment(date));
    }
    formik.setFieldValue(
      `purchaseItems.${index}.${field}`,
      date.startOf("month")
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      addRow();
    }
  };

  const addRow = () => {
    formik.setFieldValue(
      "purchaseItems",
      formik.values.purchaseItems.concat(Object.assign({}))
    );
  };

  const removeRow = (index) => {
    const originalState = formik.values.purchaseItems;
    if (isValidArray(originalState) && originalState.length > 1) {
      originalState.splice(index, 1);
    }
    formik.setFieldValue("purchaseItems", originalState);
  };

  const onSaveHandler = (e) => {
    e.preventDefault();
    if (!userPermissionSelector?.post || !userPermissionSelector?.put) {
      return errorToastr(
        COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
        "Invalid operation",
        2
      );
    }

    let data: any = { ...formik.values };
    if (!isValidElement(data?.insuranceId)) {
      data["insuranceId"] = null;
    }

    if (!isValidElement(data?.id)) {
      if (isValidElement(addEditResponse?.id)) {
        data["id"] = addEditResponse?.id;
      }
    }
    if (!isEdit) {
      dispatch.localPurchase
        .addLocalPurchase({ data: formik.values })
        .then((response) => {
          if (isValidElement(response?.id)) {
            setIsEdit(true);
            setAddEditResponse(response);
            return successToastr(
              COMMON_STRINGS.MODEL_MODULE.LOCAL_PURCHASE,
              COMMON_STRINGS.CRUD_MESSAGE.ADD_DATA("Local Purchase"),
              2
            );
          }
        });
    } else {
      dispatch.localPurchase.updateLocalPurchase({ data: data });
    }
  };

  const renderPurchaseDetails = () => {
    return (
      <>
        <Row>
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ marginTop: 5 }}>
            <h5>Date</h5>
            <DatePicker
              allowClear={false}
              format="DD-MM-YYYY"
              style={{ width: "100%" }}
              name="date"
              value={formik.values.date}
              onChange={(date, dateString) =>
                onDateHanlder(date, dateString, "date", null)
              }
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ marginTop: 5 }}>
            <h5>Purchase Invoice No</h5>
            <Input
              type="text"
              name="purchaseInvoiceNo"
              value={formik.values.purchaseInvoiceNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Purchase Invoice No."
            />
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ marginTop: 5 }}>
            <h5>Purchase Order No</h5>
            <Input
              type="text"
              name="purchaseOrderNo"
              value={formik.values.purchaseOrderNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Purchase Order No."
            />
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ marginTop: 5 }}>
            <h5>D.C No</h5>
            <Input
              type="text"
              name="dcNo"
              value={formik.values.dcNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="D.C No"
            />
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ marginTop: 5 }}>
            <h5>BR No</h5>
            <Input
              type="text"
              name="brNo"
              value={formik.values.brNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="BR No"
            />
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ marginTop: 5 }}>
            <h5>Select Supplier</h5>
            <Select
              showSearch
              value={formik.values.supplierId}
              optionFilterProp="children"
              placeholder="Select Supplier"
              style={{ width: "100%" }}
              onChange={(value) => onSelectHandler("supplierId", value, "null")}
              onSearch={(search) => setSearchVendorText(search)}
              allowClear
            >
              {Array.isArray(vendorsFilter) &&
                vendorsFilter.length > 0 &&
                vendorsFilter.map((vendor) => {
                  return (
                    <Option key={vendor.id} value={vendor.id}>
                      {vendor.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ marginTop: 5 }}>
            <h5>Insurance Purchase</h5>
            <Select
              showSearch
              value={formik.values.insuranceId}
              optionFilterProp="children"
              placeholder="Select Insurance Type"
              style={{ width: "100%" }}
              onChange={(value) =>
                onSelectHandler("insuranceId", value, "null")
              }
              allowClear
            >
              {isValidArray(insurance) &&
                insurance.map((itr: any) => {
                  return (
                    <Option key={itr.name} value={itr.value}>
                      {itr.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <MyCard>
      <Appbar
        breadcrumbs={PurchaseNewBreadCrumb(isEdit)}
        title={isEdit ? "Update Stock" : "New Stock"}
        buttonLoading={crudLoading}
        showAddButton={formik.dirty && formik.isValid}
        onButtonHandler={(e) => onSaveHandler(e)}
      />
      {renderPurchaseDetails()}
      <Row style={{ marginTop: "5%" }}>
        <Col span={24}>
          <Skeleton loading={isLoading || crudLoading}>
            {Array.isArray(formik.values.purchaseItems) &&
            formik.values.purchaseItems.length > 0 ? (
              <Skeleton loading={isLoading || crudLoading}>
                <EditableTable
                  columns={column}
                  rows={formik.values.purchaseItems}
                />
              </Skeleton>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3 style={{ textAlign: "center" }}>No Items found!</h3>
                <Button onClick={addRow} style={{ width: "20%" }}>
                  Add
                </Button>
              </div>
            )}
          </Skeleton>
        </Col>
      </Row>
    </MyCard>
  );
}
