import React from 'react'
import { useNavigate } from 'react-router-dom'
import './styles.css'
export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div className="col-sm-10 col-sm-offset-1  text-center">
              <div className="four_zero_four_bg" />
              <div className="contant_box_404">
                <h3>
                  Page not found - Look like you're lost
                </h3>
                <h4>the page you are looking for not avaible!</h4>
                <a href="" onClick={() => navigate('/')} className="link_404">Go to Home</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
