import React, { useEffect } from "react";
import { TN_LOGO } from "../../Utils/Constants";
import { Button, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { loginValidations } from "../../Utils/FormValidations";
import MyError from "../../Components/MyError/MyError";
import { errorToastr } from "../../Components/Toastr/MyToastr";
import { COMMON_STRINGS } from "../../Utils/Strings";
import "./styles.css";

type Props = {};
let domain = window.location.hostname;
export default function Login(props: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let configName = domain === "localhost" ? "rgggh" : domain.split(".")[0];
  const { isLoading, user } = useSelector((state: any) => ({
    user: state.login.user,
    isLoading: state.login.isLoading,
  }));

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      configName: configName,
    },
    validationSchema: Yup.object(loginValidations),
    onSubmit: (values) => {},
  });

  useEffect(() => {
    dispatch.login.loading({ isLoading: false });
  }, []);

  const onLoginHandler = (e) => {
    e.preventDefault();
    if (!formik.isValid || !formik.dirty) {
      return errorToastr(
        COMMON_STRINGS.MODEL_MODULE.LOGIN,
        "Enter email or password",
        2
      );
    }
    dispatch.login.login({ data: formik.values, navigate });
  };
  return (
    <div className="main-container">
      <section className="area-login">
        <h1>Medical Inventory</h1>
        <div className="login">
          <div>
            <img alt="" src={TN_LOGO} />
          </div>
          <div>
            <Input
              className="input"
              name="email"
              placeholder="Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <MyError error={JSON.stringify(formik.errors.email)} />
            ) : null}
            <Input.Password
              className="input"
              name="password"
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <MyError error={JSON.stringify(formik.errors.password)} />
            ) : null}
            <Button
              className="input"
              type="primary"
              style={{ width: "100%" }}
              onClick={onLoginHandler}
              loading={isLoading}
            >
              Submit
            </Button>
          </div>
          <a className="input" href="/forgot-password">
            Forgot password?
          </a>
        </div>
      </section>
    </div>
  );
}
