import React, { useEffect } from "react";
import "./styles.css";
import { TN_LOGO } from "../../Utils/Constants";
import { Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { forgotpasswordValidations } from "../../Utils/FormValidations";
import MyError from "../../Components/MyError/MyError";
import { errorToastr } from "../../Components/Toastr/MyToastr";
import { COMMON_STRINGS } from "../../Utils/Strings";
import { isValidElement, isValidString } from "../../Utils/helpers";

type Props = {};
let domain = window.location.hostname;
export default function ForgotPassword(props: Props) {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const location = useLocation();
  let configName = domain === "localhost" ? "rgggh" : domain.split(".")[0];
  const { crudLoading } = useSelector((state: any) => ({
    crudLoading: state.login.crudLoading,
  }));

  let email =
    isValidElement(location.state) &&
    isValidString(location.state["data"].email)
      ? location.state["data"].email
      : "";

  const formik = useFormik({
    initialValues: {
      configName: configName,
      email,
    },
    validationSchema: Yup.object(forgotpasswordValidations),
    onSubmit: (values) => {},
  });

  useEffect(() => {
    dispatch.login.crudLoading({ isLoading: false });
    return function cleanUp() {
      formik.resetForm();
    };
  }, []);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (!formik.isValid || !formik.dirty) {
      return errorToastr(
        COMMON_STRINGS.MODEL_MODULE.FORGOT_PASSWORD,
        "Email is required",
        2
      );
    }
    dispatch.login.forgotPassword({ data: formik.values, navigate });
  };

  return (
    <div className="main-container">
      <section className="area-login">
        <h1>Medical Inventory</h1>
        <div className="login">
          <div>
            <img alt="" src={TN_LOGO} />
          </div>
          <Input
            type="text"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Email"
          />
          {formik.touched.email && formik.errors.email ? (
            <MyError error={JSON.stringify(formik.errors.email)} />
          ) : null}
          <Button
            className="input"
            type="primary"
            style={{ width: "100%" }}
            onClick={onSubmitHandler}
            loading={crudLoading}
          >
            Submit
          </Button>
          <a className="input" href="/login">
            Click here to login?
          </a>
        </div>
      </section>
    </div>
  );
}
