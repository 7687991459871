import React, { FC } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import _ from "lodash";
import RestrictedRoute from "./Components/RestrictedRoute/RestrictedRoute";
import { routes } from "./Container/Navigation/Constants";
import Navigation from "./Container/Navigation/Navigation";
import { MainRoutes } from "./Container/Navigation/Routes";
import AppDecider from "./Pages/AppDecider/AppDecider";
import ForgotPassword from "./Pages/Login/ForgotPassword";
import Login from "./Pages/Login/Login";
import ResetPassword from "./Pages/Login/ResetPassword";
import { branchIdState, userState } from "./store/selectors";
import { isValidElement } from "./Utils/helpers";
import { PageNotFound } from "./Pages";

const App: FC<any> = () => {
  const userSelector = userState();
  const branchIdSelector = branchIdState();
  const { user, jwt } = useSelector((state: any) => ({
    user: state.login.user,
    jwt: state.login.jwt,
  }));

  let groupedPermissions =
    Array.isArray(userSelector?.users_role?.users_permissions) &&
    _.groupBy(
      userSelector?.users_role?.users_permissions.filter(
        (permission) => permission.branchId === branchIdSelector
      ),
      "moduleName"
    );

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route
            path={routes.FORGOT_PASSWORD_ROUTE}
            element={<ForgotPassword />}
          />
          <Route path={routes.LOGIN_ROUTE} element={<Login />} />
          <Route
            path={routes.RESET_PASSWORD_ROUTE}
            element={<ResetPassword />}
          />
          {/* Appdecider */}
          <Route
            path={routes.APP_DECIDER}
            element={
              <RestrictedRoute isAuth={!!user && !!jwt}>
                <AppDecider />
              </RestrictedRoute>
            }
          />
          <Route
            path="/"
            element={
              <RestrictedRoute isAuth={!!user && !!jwt}>
                <Navigation />
              </RestrictedRoute>
            }
          >
            {MainRoutes.map((route, index) => {
              if (
                isValidElement(userSelector) &&
                isValidElement(groupedPermissions[route.parent]) &&
                groupedPermissions[route.parent][0].get
              ) {
                return (
                  <Route
                    path={route.path}
                    element={route.component}
                    key={index + 1}
                  />
                );
              }
            })}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
