import React from 'react'
import { Form, Input } from 'antd'
import MyError from '../../../../../Components/MyError/MyError'
import { formItemLayout } from '../../../../../Utils/Constants'

type Props = {
    formik: any
}

export default function PurchaseForm(props: Props) {
    const { formik } = props;
    return (
        <>
            <Form {...formItemLayout} name="register" scrollToFirstError>
                <Form.Item label="Vendor Name">
                    <Input
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Vendor Name"
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <MyError error={formik.errors.name} />
                    ) : null}
                </Form.Item>
                <Form.Item label="Address">
                    <Input.TextArea
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Address"
                    />
                    {formik.touched.address && formik.errors.address ? (
                        <MyError error={formik.errors.address} />
                    ) : null}
                </Form.Item>
                <Form.Item label="Contact">
                    <Input
                        type="number"
                        name="mobile"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Contact"
                    />
                    {formik.touched.mobile && formik.errors.mobile ? (
                        <MyError error={formik.errors.mobile} />
                    ) : null}
                </Form.Item>
            </Form>
        </>
    )
}