import React, { useState, useEffect } from 'react';
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import "./Layout.css"
import { SideMenuRoutes, MainRoutes, SideMenuSubStoreRoutes } from './Routes';
import { isValidElement, isValidString } from '../../Utils/helpers';
import { TN_LOGO } from '../../Utils/Constants';
import { branchIdState, userState } from '../../store/selectors';
import _ from 'lodash';
import { routes } from './Constants';

const { Header, Sider, Content } = Layout;

// react-hooks/rules-of-hooks

const Navigation = () => {
  const userSelector = userState();
  const branchIdSelector = branchIdState();
  let groupedPermissions = Array.isArray(userSelector?.users_role?.users_permissions) && _.groupBy(userSelector?.users_role?.users_permissions.filter(permission => permission.branchId === branchIdSelector), 'moduleName');

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [, setCollapse] = useState(false);

  const { branchId, branchName } = useSelector((state: any) => ({
    branchName: state.login.branchName,
    branchId: state.login.branchId,
  }))

  useEffect(() => {
    window.innerWidth <= 760 ? setCollapse(true) : setCollapse(false);
  }, []);

  const logoutHandler = () => {
    dispatch.login.logout({});
    navigate(`login`)
  }

  const renderHeaderContent = () => {
    return (
      <>
        <div style={{ display: 'flex', flex: 3, paddingLeft: 20 }}>
          <Space>
            Medical Inventory |
            department: {branchName}
          </Space>
        </div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', paddingRight: 20 }}>
          <Space>
            <a onClick={() => navigate(routes.PROFILE_ROUTE)}>Profile</a>
            <a onClick={logoutHandler}>Logout</a>
          </Space>
        </div>
      </>
    )
  }

  const renderSideMenuMainRoutes = () => {
    return (
      <div style={{ height: "100vh", overflowY: "scroll" }} >
        <Menu theme="light" mode="inline"
          defaultSelectedKeys={[location.pathname || '/']}
          selectedKeys={[location.pathname || '/']}
        >
          <Menu.Item key={0} style={{ display: 'flex', flexDirection: 'column', height: 55 }} disabled={true}>
            <img alt="logo" src={TN_LOGO} height={40} width={50} />
          </Menu.Item>
          {SideMenuRoutes.map((route, index) => {
            if (isValidElement(userSelector) && isValidElement(groupedPermissions[route.name]) && groupedPermissions[route.name][0].get) {
              return (
                <>
                  {isValidString(route.path) && <Menu.Item
                    key={route.path}
                    icon={route.icon}>
                    <Link to={route.path}>
                      {route.sideMenuName}
                    </Link>
                  </Menu.Item>}
                  {Array.isArray(route.children) && (
                    <Menu.SubMenu title={route.sideMenuName} key={route?.name}>
                      {route.children.map(child => {
                        return (
                          <Menu.Item
                            key={child.path}
                            icon={child.icon}>
                            <Link to={child.path}>
                              {child.sideMenuName}
                            </Link>
                          </Menu.Item>
                        )
                      })}
                    </Menu.SubMenu>
                  )}
                </>
              )
            }
          })}
        </Menu>
      </div>
    )
  };

  const renderSideMenuSubStoreRoutes = () => {
    return (
      <div style={{ height: "100vh", overflowY: "scroll" }} >
        <Menu theme="light" mode="inline"
          defaultSelectedKeys={[location.pathname || '/']}
          selectedKeys={[location.pathname || '/']}
        >
          <Menu.Item key={0} style={{ display: 'flex', flexDirection: 'column', height: 55 }} disabled={true}>
            <img alt="logo" src={TN_LOGO} height={40} width={50} />
          </Menu.Item>
          {SideMenuSubStoreRoutes.map((route, index) => {
            if (isValidElement(userSelector) && isValidElement(groupedPermissions[route.name]) && groupedPermissions[route.name][0].get) {
              return (
                <>
                  {isValidString(route.path) && <Menu.Item
                    key={route.path}
                    icon={route.icon}>
                    <Link to={route.path}>
                      {route.sideMenuName}
                    </Link>
                  </Menu.Item>}
                  {Array.isArray(route.children) && (
                    <Menu.SubMenu title={route.sideMenuName} key={route?.name}>
                      {route.children.map(child => {
                        return (
                          <Menu.Item
                            key={child.path}
                            icon={child.icon}>
                            <Link to={child.path}>
                              {child.sideMenuName}
                            </Link>
                          </Menu.Item>
                        )
                      })}
                    </Menu.SubMenu>
                  )}
                </>
              )
            }
          })}
        </Menu>
      </div>
    )
  }

  const renderLayout = () => {
    return (
      <Content style={{ margin: '24px 16px 0' }}>
        <div className="site-layout-background" style={{ padding: 0, minHeight: "80vh", overflowX: 'auto' }}>
          {MainRoutes.map((route, index) => {
            return (
              <Routes key={index + 1}>
                <Route key={index + 1} path={route.path} element={route.component} />
              </Routes>
            )
          })}
        </div>
      </Content>
    )
  };

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={broken => {
        }}
        onCollapse={(collapsed, type) => {
        }}
        style={{ backgroundColor: '#fff' }}
      >
        <div className="logo" />
        {!!userSelector?.departmentId ? renderSideMenuSubStoreRoutes() : renderSideMenuMainRoutes()}
      </Sider>
      <Layout>
        <Header className="site-layout-sub-header-background" style={{ padding: 2, display: 'flex', alignItems: 'center' }}>
          {renderHeaderContent()}
        </Header>
        {renderLayout()}
        {/* <Footer style={{ textAlign: 'right' }}>©2022 Made with ♥ at AHM</Footer> */}
      </Layout>
    </Layout>
  );
}

export default React.memo(Navigation);