import { AutoComplete, Col, Divider, Input, Space, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  debounce,
  isValidArray,
  isValidElement,
  isValidString,
} from "../../Utils/helpers";
import MyCard from "../../Components/Card/MyCard";
import moment from "moment";
export default function Dashboard() {
  const dispatch = useDispatch();

  const { products } = useSelector((state: any) => ({
    products: state.products.products,
  }));
  const [search, setSearch] = useState(null);
  const [productId, setProductId] = useState(null);
  const [productDetail, setProductDetail] = useState(null);

  useEffect(() => {
    return function cleanUp() {
      dispatch.products.resetProducts();
      setProductId(null);
      setSearch("");
    };
  }, []);

  useEffect(() => {
    if (isValidElement(productId)) {
      dispatch.products.getProductsFromAllStore({ productId }).then((res) => {
        if (isValidElement(res)) {
          setProductDetail(res);
        }
      });
    }
  }, [productId]);

  useEffect(() => {
    if (isValidString(search)) {
      dispatch.products.getProductsByPage({
        page: 1,
        limit: 1000,
        filter: search,
        categoryId: null,
      });
    } else {
      dispatch.products.getProductsByPage({
        page: 1,
        limit: 10,
        filter: null,
        categoryId: null,
      });
    }
  }, [search]);

  const debouncedOnSearchText = useCallback(
    debounce((searchText) => {
      setSearch(searchText);
    }, 800),
    [search]
  );

  const onSelect = (value: string) => {
    if (isValidElement(value) && !!value) {
      setProductId(value);
    }
  };

  const renderMainStoreContent = () => {
    let content;
    if (productDetail?.main) {
      const {
        name,
        qty,
        codeNo,
        manufacturerDate,
        expiryDate,
        manufacturerName,
      } = productDetail?.main;
      content = () => {
        return (
          <>
            <Typography.Text strong>Total Stock: {qty}</Typography.Text>
            <br />
            <Typography.Text strong>
              {codeNo} - {name}
            </Typography.Text>
            <br />
            Manufacturer Name: {manufacturerName}
            <br />
            <Space split={<Divider type="vertical" />}>
              <Typography.Text>
                Manufacturer Date:{" "}
                {moment(manufacturerDate).format("DD-MM-YYYY")}
              </Typography.Text>
              <Typography.Text>
                Expiry Date: {moment(expiryDate).format("DD-MM-YYYY")}
              </Typography.Text>
            </Space>
          </>
        );
      };
    } else {
      content = () => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p style={{ color: "red" }}>*** No product found ***</p>
          </div>
        );
      };
    }
    return (
      <MyCard title="Main Store Stock" style={{ width: window.innerWidth / 2 }}>
        {content()}
      </MyCard>
    );
  };

  const renderSubStoreContent = () => {
    let content;
    if (
      isValidElement(productDetail?.main) &&
      isValidArray(productDetail?.sub)
    ) {
      const { name, codeNo, manufacturerDate, expiryDate, manufacturerName } =
        productDetail?.main;
      content = () => {
        return (
          <>
            {productDetail?.sub.map((subProduct) => {
              return (
                <div>
                  <Typography.Text strong>
                    Qty: {subProduct?.total}
                  </Typography.Text>
                  <br />
                  <Typography.Text strong>
                    {codeNo} - {name}
                  </Typography.Text>
                  <br />
                  <Typography.Text>
                    Department: {subProduct?.department?.name} -{" "}
                    {subProduct?.department?.departmentNo}
                  </Typography.Text>
                  <br />
                  {isValidElement(subProduct?.createdAt) ? (
                    <>
                      <Typography.Text>
                        Supplied At:{" "}
                        {moment(subProduct?.createdAt)?.format("DD-MM-YYYY")}
                      </Typography.Text>
                      <br />
                    </>
                  ) : null}
                  Manufacturer Name: {manufacturerName}
                  <br />
                  <Space split={<Divider type="vertical" />}>
                    <Typography.Text>
                      Manufacturer Date:{" "}
                      {moment(manufacturerDate).format("DD-MM-YYYY")}
                    </Typography.Text>
                    <Typography.Text>
                      Expiry Date: {moment(expiryDate).format("DD-MM-YYYY")}
                    </Typography.Text>
                  </Space>
                </div>
              );
            })}
          </>
        );
      };
    } else {
      content = () => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ color: "red" }}>*** No items found ***</p>
          </div>
        );
      };
    }

    return (
      <MyCard
        title="Sub Store Stock"
        style={{ width: window.innerWidth / 2, marginTop: 10 }}
      >
        {content()}
      </MyCard>
    );
  };

  const renderProductDetail = () => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          marginBottom: 16,
        }}
      >
        {renderMainStoreContent()}
        {renderSubStoreContent()}
      </div>
    );
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          marginTop: window.innerHeight / 9,
          alignItems: "center",
        }}
      >
        <AutoComplete
          autoFocus
          dropdownMatchSelectWidth={300}
          style={{ width: window.innerWidth <= 360 ? "100%" : "60%" }}
          options={
            Array.isArray(products)
              ? products.map((product) => {
                  return {
                    label: `${product.codeNo} - ${product.name}`,
                    value: product.id,
                  };
                })
              : []
          }
          onSelect={onSelect}
          notFoundContent="No item found"
          onSearch={debouncedOnSearchText}
        >
          <Input.Search size="large" placeholder="Search here..." enterButton />
        </AutoComplete>
        <span style={{ marginBottom: 10 }}>
          Note: Search here to find total stock for single item
        </span>
        {productDetail && renderProductDetail()}
      </div>
    </div>
  );
}
