import { DatePicker, Form, Input, Select } from "antd";
import React from "react";
import { formItemLayout } from "../../../../../Utils/Constants";
import MyError from "../../../../../Components/MyError/MyError";

export default function ProductMetaForm({ formik }: any) {

    const onDateHanlder = (date, dateString, field, index) => {
        formik.setFieldValue(field, date.startOf("month"));
    };
    
    return (
        <Form {...formItemLayout} name="register" scrollToFirstError>
            <Form.Item label="Code No.">
                <Input
                    type="text"
                    name="codeNo"
                    value={formik.values.codeNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Code No."
                />
                {formik.touched.codeNo && formik.errors.codeNo ? (
                    <MyError error={formik.errors.codeNo} />
                ) : null}
            </Form.Item>
            <Form.Item label="Item Name">
                <Input
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Item Name"
                />
                {formik.touched.name && formik.errors.name ? (
                    <MyError error={formik.errors.name} />
                ) : null}
            </Form.Item>
            <Form.Item label="Batch No.">
                <Input
                    type="text"
                    name="batchNo"
                    value={formik.values.batchNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Batch No."
                />
                {formik.touched.batchNo && formik.errors.batchNo ? (
                    <MyError error={formik.errors.batchNo} />
                ) : null}
            </Form.Item>
            <Form.Item label="Qty">
                <Input
                    type="text"
                    name="qty"
                    value={formik.values.qty}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Qty"
                />
                {formik.touched.qty && formik.errors.qty ? (
                    <MyError error={formik.errors.qty} />
                ) : null}
            </Form.Item>
            <Form.Item label="Price">
                <Input
                    type="text"
                    name="price"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Price"
                />
                {formik.touched.price && formik.errors.price ? (
                    <MyError error={formik.errors.price} />
                ) : null}
            </Form.Item>
            <Form.Item label="Manufacturer Name">
                <Input
                    type="text"
                    name="manufacturerName"
                    value={formik.values.manufacturerName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Manufacturer Name"
                />
                {formik.touched.manufacturerName && formik.errors.manufacturerName ? (
                    <MyError error={formik.errors.manufacturerName} />
                ) : null}
            </Form.Item>
            <Form.Item label="Manufacturer Date">
                <DatePicker
                    allowClear={false}
                    format="MM-YYYY"
                    style={{ width: "100%" }}
                    name="manufacturerDate"
                    value={formik.values.manufacturerDate}
                    onChange={(date, dateString) => onDateHanlder(date, dateString, "manufacturerDate", null)}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.manufacturerDate && formik.errors.manufacturerDate ? (
                    <MyError error={formik.errors.manufacturerDate} />
                ) : null}
            </Form.Item>
            <Form.Item label="Expiry Date">
                <DatePicker
                    allowClear={false}
                    format="MM-YYYY"
                    style={{ width: "100%" }}
                    name="expiryDate"
                    value={formik.values.expiryDate}
                    onChange={(date, dateString) => onDateHanlder(date, dateString, "expiryDate", null)}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.expiryDate && formik.errors.expiryDate ? (
                    <MyError error={formik.errors.expiryDate} />
                ) : null}
            </Form.Item>

        </Form>
    );
}
