import React from "react";
import "./supplyprint.css";
import moment from "moment";
import { connect } from "react-redux";
import { Layout, Table, Typography } from "antd";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import { isValidString } from "../../../Utils/helpers";
interface MyComponentProps {
  rows: any;
}

class SupplyPrint extends React.Component<any> {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount(): void {}
  render() {
    console.log(this.props);
    const { branchName, rows, department } = this.props;
    const { date, indentNo } = rows;
    return (
      <Layout style={layoutStyle}>
        <div style={{ textAlign: "center" }}>
          <Typography.Title level={3}>
            {branchName} Supply Items{" "}
          </Typography.Title>
          <Typography.Paragraph>Indent No: {indentNo}</Typography.Paragraph>
          <Typography.Paragraph>
            Date: {moment(date).format("DD-MM-YYYY")}
          </Typography.Paragraph>
          {isValidString(department?.name) ||
          isValidString(department?.departmentNo) ? (
            <Typography.Paragraph>
              Department: {department?.name} - {department?.departmentNo}
            </Typography.Paragraph>
          ) : null}
        </div>
        <div>
          <Table dataSource={[]} columns={[]} />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  branchId: state.login.branchId,
  branchName: state?.login?.branchName,
});

export default SupplyPrint;

const headerStyle: React.CSSProperties = {
  textAlign: "center",
  backgroundColor: "#fff",
};

const footerStyle: React.CSSProperties = {
  textAlign: "center",
  color: "#fff",
  backgroundColor: "#4096ff",
};

const layoutStyle = {
  padding: 10,
};

const contentStyle: React.CSSProperties = {
  textAlign: "center",
  color: "#fff",
  backgroundColor: "#0958d9",
};
