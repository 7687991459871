import { UsersPermissionsNetwork, UsersRolesNetwork } from "../../API/Networks";
import { errorToastr, successToastr } from "../../Components/Toastr/MyToastr";
import { jwtExpired } from "../../Utils/helpers";
import { COMMON_STRINGS } from "../../Utils/Strings";

const usersRoleModel = {
    state: {
        isLoading: false,
        crudLoading: false,
        error: null,
        userRoles: null,
        userPermissions: null
    },
    reducers: {
        loading: (state, { isLoading }) => {
            return { ...state, isLoading }
        },
        crudLoading: (state, { isLoading }) => {
            return { ...state, crudLoading: isLoading }
        },
        userRoles: (state, { userRoles }) => {
            return { ...state, userRoles, isLoading: false, error: null, crudLoading: false }
        },
        userPermissions: (state, { userPermissions }) => {
            return { ...state, userPermissions, isLoading: false, error: null, crudLoading: false }
        },
        error: (state, { error }) => {
            return { ...state, userRoles: null, userPermissions: null, isLoading: false, crudLoading: false, error: error.message }
        }
    },
    effects: (dispatch) => ({
        getAllUserRole: async (_, state) => {
            try {
                dispatch.userRoles.loading({ isLoading: false });
                const response = await UsersRolesNetwork.getAllUserRoles();
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.USERS_ROLES,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                }
                dispatch.userRoles.userRoles({ userRoles: response.data.data });
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.userRoles.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.USERS_ROLES, error.response.data.message, 2);
            }
        },
        getAllPermissions: async ({ data }, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                dispatch.userRoles.loading({ isLoading: false });
                const response = await UsersPermissionsNetwork.getAllUsersPermissions(data);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.USERS_PERMISSIONS,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                }
                dispatch.userRoles.userPermissions({ userPermissions: response.data.data });

            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.userRoles.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.USERS_PERMISSIONS, error.response.data.message, 2);
            }
        },
        updatePermissions: async ({ data }, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                dispatch.userRoles.crudLoading({ isLoading: false });
                const response = await UsersPermissionsNetwork.updateUsersPermissions(data);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.USERS_PERMISSIONS,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                }
                dispatch.userRoles.getAllPermissions({ data });
                return successToastr(COMMON_STRINGS.MODEL_MODULE.USERS_PERMISSIONS, COMMON_STRINGS.CRUD_MESSAGE.UPDATE_DATA("Permission"), 2)

            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.userRoles.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.USERS_PERMISSIONS, error.response.data.message, 2);
            }
        }
    })
};

export default usersRoleModel;