const AppbarStyles = {
    container: {
        backgroundColor: '#fff',
        height: '100px',
        display: 'flex'
    },
    leftContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        marginLeft: 10
    },
    rightContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row-reverse',
        alignItems: 'center'
    }
}

export default AppbarStyles;