import { Navigate } from "react-router";
import { routes } from "../../Container/Navigation/Constants";

const RestrictedRoute = ({ isAuth, children }) => {
    if (!isAuth) {
        return <Navigate to={routes.LOGIN_ROUTE} replace />;
    }
    return children;
};

export default RestrictedRoute;


