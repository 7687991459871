import React from "react";
//@ts-ignore
import { UserOutlined } from "@ant-design/icons";
import {
  Category,
  Product,
  Department,
  InternalPurchase,
  LocalPurchase,
  Vendor,
  Supply,
  SupplyForm,
  Users,
  InternalPuchaseForm,
  LocalPurchaseForm,
  UsersRoles,
  ProductMeta,
  ProductFilter,
  Profile,
} from "../../Pages";
import { routes } from "./Constants";
import MyIcon from "../../Components/MyIcon/MyIcon";
import UsersPermissions from "../../Pages/UsersRoles/Components/UsersPermissions";
import { MODULE } from "../../Utils/Constants";
import SubStores from "../../Pages/SubStores/SubStores";
import SubStoreProductDetails from "../../Pages/SubStores/Components/SubStoreProductDetails";
import SubStoreSupply from "../../Pages/SubStores/Supply/SubStoreSupply";
import SubStoreSupplyForm from "../../Pages/SubStores/Supply/Components/SubStoreSupplyForm";
import SubStoreProducts from "../../Pages/SubStores/SubStoreProducts/SubStoreProducts";
import Dashboard from "../../Pages/Dashboard/Dashboard";
import Reports from "../../Pages/Reports/Reports";
export const SideMenuRoutes: any = [
  {
    path: routes.DASHBOARD,
    exact: true,
    name: MODULE.DASHBOARD,
    sideMenuName: "Dashboard",
    component: <Dashboard />,
    icon: (
      <MyIcon
        iconName="fal fa-tachometer-alt-fastest"
        size={16}
        styles={{ padding: 5 }}
      />
    ),
  },
  {
    name: MODULE.MASTER,
    sideMenuName: "Master",
    icon: <UserOutlined />,
    nested: true,
    children: [
      {
        path: routes.CATEGORY_ROUTE,
        exact: true,
        name: "category",
        sideMenuName: "Category",
        component: <Category />,
        icon: (
          <MyIcon
            iconName="fal fa-boxes-alt"
            size={16}
            styles={{ padding: 5 }}
          />
        ),
      },
      {
        path: routes.PRODUCT_ROUTE,
        exact: true,
        name: "product",
        sideMenuName: "Product",
        component: <Product />,
        icon: (
          <MyIcon iconName="fal fa-syringe" size={16} styles={{ padding: 5 }} />
        ),
      },
      {
        path: routes.DEPARTMENT_ROUTE,
        exact: true,
        name: "department",
        sideMenuName: "Department",
        component: <Department />,
        icon: (
          <MyIcon
            iconName="fal fa-book-medical"
            size={16}
            styles={{ padding: 5 }}
          />
        ),
      },
    ],
  },
  {
    name: MODULE.INVENTORY,
    sideMenuName: "Inventory",
    icon: <UserOutlined />,
    nested: true,
    children: [
      {
        path: routes.VENDOR_ROUTE,
        exact: true,
        name: "vendor",
        sideMenuName: "Local Supplier",
        component: <Vendor />,
        icon: (
          <MyIcon iconName="fal fa-truck" size={16} styles={{ padding: 5 }} />
        ),
      },
      {
        path: routes.OGR_ROUTE,
        exact: true,
        name: "TNMSC",
        sideMenuName: "TNMSC",
        component: <InternalPurchase />,
        icon: (
          <MyIcon
            iconName="fal fa-warehouse-alt"
            size={16}
            styles={{ padding: 5 }}
          />
        ),
      },
      {
        path: routes.PURCHASE_ROUTE,
        exact: true,
        name: "purchase",
        sideMenuName: "Local Purchase",
        component: <LocalPurchase />,
        icon: (
          <MyIcon iconName="fal fa-store" size={16} styles={{ padding: 5 }} />
        ),
      },
    ],
  },
  {
    path: routes.DISPATCH_ROUTE,
    exact: true,
    name: MODULE.SUPPLY,
    sideMenuName: "Supply",
    component: <Supply />,
    icon: (
      <MyIcon iconName="fal fa-box-check" size={16} styles={{ padding: 5 }} />
    ),
  },
  {
    path: routes.REPORTS,
    exact: true,
    name: MODULE.REPORTS,
    sideMenuName: "Reports",
    component: <Reports />,
    icon: <MyIcon iconName="fal fa-file" size={16} styles={{ padding: 5 }} />,
  },
  {
    name: MODULE.USERS,
    sideMenuName: "Users Manage",
    icon: <UserOutlined />,
    nested: true,
    children: [
      {
        path: routes.USERS_ROUTE,
        exact: true,
        name: MODULE.USERS,
        sideMenuName: "Users",
        component: <Users />,
        icon: (
          <MyIcon iconName="fal fa-users" size={16} styles={{ padding: 5 }} />
        ),
      },
      {
        path: routes.USERS_ROLES_ROUTE,
        exact: true,
        name: MODULE.USERS_ROLES,
        sideMenuName: "Users Roles",
        component: <UsersRoles />,
        icon: (
          <MyIcon
            iconName="fal fa-user-lock"
            size={16}
            styles={{ padding: 5 }}
          />
        ),
      },
    ],
  },
];

export const SideMenuSubStoreRoutes: any = [
  {
    path: routes.DASHBOARD,
    exact: true,
    name: MODULE.DASHBOARD,
    sideMenuName: "Dashboard",
    component: <Dashboard />,
    icon: (
      <MyIcon
        iconName="fal fa-tachometer-alt-fastest"
        size={16}
        styles={{ padding: 5 }}
      />
    ),
  },
  {
    name: MODULE.MAIN_STORE,
    sideMenuName: "Main Store Inventory",
    icon: <UserOutlined />,
    nested: true,
    children: [
      {
        path: routes.MAIN_STORE_PENDING,
        exact: true,
        name: MODULE.MAIN_STORE,
        sideMenuName: "Pending",
        component: <SubStores />,
        icon: (
          <MyIcon
            iconName="fal fa-warehouse-alt"
            size={16}
            styles={{ padding: 5 }}
          />
        ),
      },
      {
        path: routes.MAIN_STORE_APPROVED,
        exact: true,
        name: MODULE.MAIN_STORE,
        sideMenuName: "Approved",
        component: <SubStores />,
        icon: (
          <MyIcon iconName="fal fa-store" size={16} styles={{ padding: 5 }} />
        ),
      },
      {
        path: routes.SUBSTORE_PRODUCTS,
        exact: true,
        name: MODULE.SUBSTORE_PRODUCTS,
        sideMenuName: "Products",
        component: <SubStoreProducts />,
        icon: (
          <MyIcon iconName="fal fa-syringe" size={16} styles={{ padding: 5 }} />
        ),
      },
    ],
  },
  {
    path: routes.SUB_STORE_SUPPLY,
    exact: true,
    name: MODULE.SUB_STORE_SUPPLY,
    sideMenuName: "Supply",
    component: <SubStoreSupply />,
    icon: (
      <MyIcon iconName="fal fa-box-check" size={16} styles={{ padding: 5 }} />
    ),
  },
  {
    path: routes.DEPARTMENT_USERS_ROUTE,
    exact: true,
    name: MODULE.DEPARTMENT_USERS,
    sideMenuName: "Department Users",
    component: <Users />,
    icon: <MyIcon iconName="fal fa-users" size={16} styles={{ padding: 5 }} />,
  },
  {
    path: routes.REPORTS,
    exact: true,
    name: MODULE.REPORTS,
    sideMenuName: "Reports",
    component: <Reports />,
    icon: <MyIcon iconName="fal fa-file" size={16} styles={{ padding: 5 }} />,
  },
];

export const MainRoutes: any = [
  {
    path: routes.DASHBOARD,
    exact: true,
    name: "dashboard",
    parent: MODULE.DASHBOARD,
    sideMenuName: "Dashboard",
    component: <Dashboard />,
  },
  {
    path: routes.CATEGORY_ROUTE,
    exact: true,
    name: "category",
    parent: MODULE.MASTER,
    sideMenuName: "Category",
    component: <Category />,
  },
  {
    path: routes.PRODUCT_ROUTE,
    exact: true,
    name: "product",
    parent: MODULE.MASTER,
    sideMenuName: "Product",
    component: <Product />,
  },
  {
    path: routes.PRODUCT_META_ROUTE,
    exact: true,
    name: "product",
    parent: MODULE.MASTER,
    sideMenuName: "Product",
    component: <ProductMeta />,
  },
  {
    path: routes.PRODUCT_FILTER,
    exact: true,
    name: "product",
    parent: MODULE.MASTER,
    sideMenuName: "Product",
    component: <ProductFilter />,
  },
  {
    path: routes.DEPARTMENT_ROUTE,
    exact: true,
    name: "department",
    parent: MODULE.MASTER,
    sideMenuName: "Department",
    component: <Department />,
  },
  {
    path: routes.USERS_ROUTE,
    exact: true,
    name: "users",
    parent: MODULE.USERS,
    sideMenuName: "Users",
    component: <Users />,
  },
  {
    path: routes.USERS_ROLES_ROUTE,
    exact: true,
    name: "users-roles",
    parent: MODULE.USERS,
    sideMenuName: "Users Roles",
    component: <UsersRoles />,
  },
  {
    path: routes.USERS_PERMISSIONS_ROUTE,
    exact: true,
    parent: MODULE.USERS,
    name: "users-permissions",
    sideMenuName: "Users Permissions",
    component: <UsersPermissions />,
  },
  {
    path: routes.OGR_ROUTE,
    exact: true,
    parent: MODULE.INVENTORY,
    name: "TNMSC",
    sideMenuName: "TNMSC",
    component: <InternalPurchase />,
  },
  {
    path: routes.ADD_OGR_ROUTE,
    exact: true,
    name: "ogr",
    parent: MODULE.INVENTORY,
    sideMenuName: "TNMSC",
    component: <InternalPuchaseForm />,
  },
  {
    path: routes.EDIT_OGR_ROUTE,
    exact: true,
    name: "ogr",
    parent: MODULE.INVENTORY,
    sideMenuName: "TNMSC",
    component: <InternalPuchaseForm />,
  },
  {
    path: routes.PURCHASE_ROUTE,
    exact: true,
    name: "purchase",
    parent: MODULE.INVENTORY,
    sideMenuName: "Local Purchase",
    component: <LocalPurchase />,
  },
  {
    path: routes.ADD_PURCHASE_ROUTE,
    exact: true,
    name: "purchase",
    parent: MODULE.INVENTORY,
    sideMenuName: "Local Purchase",
    component: <LocalPurchaseForm />,
  },
  {
    path: routes.EDIT_PURCHASE_ROUTE,
    exact: true,
    name: "purchase",
    parent: MODULE.INVENTORY,
    sideMenuName: "Local Purchase",
    component: <LocalPurchaseForm />,
  },
  {
    path: routes.VENDOR_ROUTE,
    exact: true,
    name: "vendor",
    parent: MODULE.INVENTORY,
    sideMenuName: "Local Vendor",
    component: <Vendor />,
  },
  {
    path: routes.DISPATCH_ROUTE,
    exact: true,
    name: "dispatch",
    parent: MODULE.SUPPLY,
    sideMenuName: "Dispatch",
    component: <Supply />,
  },
  {
    path: routes.ADD_DISPATCH_ROUTE,
    exact: true,
    name: "dispatch",
    parent: MODULE.SUPPLY,
    sideMenuName: "Add Dispatch",
    component: <SupplyForm />,
  },
  {
    path: routes.EDIT_DISPATCH_ROUTE,
    exact: true,
    name: "dispatch",
    parent: MODULE.SUPPLY,
    sideMenuName: "Edit Dispatch",
    component: <SupplyForm />,
  },
  {
    path: routes.PROFILE_ROUTE,
    exact: true,
    name: "profile",
    parent: MODULE.MASTER,
    sideMenuName: "Profile",
    component: <Profile />,
  },
  {
    path: routes.MAIN_STORE_PENDING,
    exact: true,
    name: MODULE.MAIN_STORE,
    parent: MODULE.MAIN_STORE,
    sideMenuName: "Main Store",
    component: <SubStores />,
  },
  {
    path: routes.MAIN_STORE_APPROVED,
    exact: true,
    name: MODULE.MAIN_STORE,
    parent: MODULE.MAIN_STORE,
    sideMenuName: "Main Store",
    component: <SubStores />,
  },
  {
    path: routes.MAIN_STORE_PENDING_DETAIL,
    exact: true,
    name: MODULE.MAIN_STORE,
    parent: MODULE.MAIN_STORE,
    component: <SubStoreProductDetails />,
  },
  {
    path: routes.MAIN_STORE_APPROVED_DETAIL,
    exact: true,
    name: MODULE.MAIN_STORE,
    parent: MODULE.MAIN_STORE,
    component: <SubStoreProductDetails />,
  },
  {
    path: routes.SUB_STORE_SUPPLY,
    exact: true,
    name: MODULE.SUB_STORE_SUPPLY,
    parent: MODULE.SUB_STORE_SUPPLY,
    component: <SubStoreSupply />,
  },
  {
    path: routes.SUB_STORE_SUPPLY_FORM_ADD,
    exact: true,
    name: MODULE.SUB_STORE_SUPPLY,
    parent: MODULE.SUB_STORE_SUPPLY,
    component: <SubStoreSupplyForm />,
  },
  {
    path: routes.SUB_STORE_SUPPLY_FORM_EDIT,
    exact: true,
    name: MODULE.SUB_STORE_SUPPLY,
    parent: MODULE.SUB_STORE_SUPPLY,
    component: <SubStoreSupplyForm />,
  },
  {
    path: routes.DEPARTMENT_USERS_ROUTE,
    exact: true,
    name: MODULE.DEPARTMENT_USERS,
    parent: MODULE.DEPARTMENT_USERS,
    component: <Users />,
  },
  {
    path: routes.SUBSTORE_PRODUCTS,
    exact: true,
    name: MODULE.SUBSTORE_PRODUCTS,
    parent: MODULE.SUBSTORE_PRODUCTS,
    component: <SubStoreProducts />,
  },
  {
    path: routes.REPORTS,
    exact: true,
    name: MODULE.REPORTS,
    parent: MODULE.REPORTS,
    component: <Reports />,
  },
];
