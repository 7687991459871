import React, { useCallback, useEffect, useState } from "react";
import MyCard from "../../Components/Card/MyCard";
import Appbar from "../../Components/Appbar/Appbar";
import { isAdmin, isSuperAdmin } from "../../Utils/Authorization";
import { ReportsBreadCrumb } from "./Constants";
import {
  branchIdState,
  departmentIdState,
  userState,
} from "../../store/selectors";
import { isValidElement } from "../../Utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { MODULE } from "../../Utils/Constants";
import { Col, Row, Skeleton, Space, Typography } from "antd";
import MyTable from "../../Components/Table/MyTable";
import ReportsModal from "./Components/MicroComponents/ReportsModal";
import MyIcon from "../../Components/MyIcon/MyIcon";
import Palette from "../../Utils/Palette";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function Reports() {
  let userSelector = userState();
  let branchIdSelector = branchIdState();
  let departmentIdSelector = departmentIdState();

  let [userPermissionSelector] =
    isValidElement(userSelector) &&
    userSelector?.users_role?.users_permissions.filter(
      (permission) =>
        permission.moduleName === MODULE.REPORTS &&
        permission.branchId === branchIdSelector
    );
  const dispatch = useDispatch();

  const { isLoading, reports, total } = useSelector((state: any) => ({
    isLoading: state.reports.isLoading,
    reports: state.reports.reports,
    total: state.reports.total,
  }));

  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [editForm, setEditForm] = useState(null);

  const formik = useFormik({
    initialValues: {
      categoryId: null,
      productId: null,
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {},
  });

  useEffect(() => {
    dispatch.reports.getAllReportsByPage({ page, limit });
  }, [page, limit]);

  const pageHandler = (page, pageSize) => {
    setPage(page);
    setLimit(pageSize);
  };

  const toggleModal = useCallback(() => {
    setModalVisible(!modalVisible);
    if (!modalVisible) {
      formik.resetForm();
    }
  }, [modalVisible]);

  const renderReportsModal = () => {
    return (
      <ReportsModal
        formik={formik}
        visible={modalVisible}
        handleSubmit={() => {
          dispatch.reports
            .addReports({ page, limit, exportFilters: { ...formik.values } })
            .then((r) => {
              toggleModal();
              formik.resetForm();
            });
        }}
        toggleModal={toggleModal}
      />
    );
  };

  const columns = [
    {
      title: "Export (as)",
      dataIndex: "exportType",
      key: "exportType",
      render: (text, record) => {
        return (
          <Typography.Text>
            {record?.exportType ? record?.exportType.toUpperCase() : null}
          </Typography.Text>
        );
      },
    },
    {
      title: "Request Time",
      dataIndex: "processedOn",
      key: "processedOn",
      render: (text, record) => {
        return (
          <Typography.Text>
            {moment(record?.processedOn).format("DD-MM-YYYY hh:mm A")}
          </Typography.Text>
        );
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (text, record) => {
        return (
          <Typography.Text style={{ textTransform: "capitalize" }}>
            {record?.remarks}
          </Typography.Text>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (text, record) => {
        return record.isActive ? (
          <MyIcon color={Palette.green} iconName="fal fa-check-circle" />
        ) : (
          <MyIcon color={Palette.red} iconName="fal fa-times-circle" />
        );
      },
    },
    {
      title: "Completed Time",
      dataIndex: "completedOn",
      key: "completedOn",
      render: (text, record) => {
        return (
          <Typography.Text>
            {record?.completedOn
              ? moment(record?.completedOn).format("DD-MM-YYYY hh:mm A")
              : null}
          </Typography.Text>
        );
      },
    },
    {
      title: "Download",
      dataIndex: "download",
      key: "download",
      render: (text, record) => {
        return (
          <a href={record?.uri ? record?.uri : null} download>
            <Space>
              <MyIcon color={Palette.lightGrey} iconName="fal fa-download" />
              <Typography.Text>
                {record?.uri ? record?.uri.split("/")[5] : null}
              </Typography.Text>
            </Space>
          </a>
        );
      },
    },
  ];

  return (
    <MyCard>
      <Appbar
        title="Reports"
        showAddButton={
          isSuperAdmin(userSelector?.users_role?.name) ||
          isAdmin(userSelector?.users_role?.name)
        }
        onButtonHandler={toggleModal}
        breadcrumbs={ReportsBreadCrumb}
      />
      <br />
      <Row>
        <Col span={24}>
          <Skeleton loading={isLoading}>
            <MyTable
              rows={reports}
              columns={columns}
              pagination={{
                total,
                current: page || 1,
                pageSize: limit || 10,
                onChange: pageHandler,
              }}
            />
          </Skeleton>
        </Col>
      </Row>
      <>{modalVisible ? renderReportsModal() : null}</>
    </MyCard>
  );
}
