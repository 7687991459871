import { DepartmentNetwork, ProductsNetwork } from "../../../API/Networks";
import {
  errorToastr,
  successToastr,
} from "../../../Components/Toastr/MyToastr";
import { isValidString, jwtExpired } from "../../../Utils/helpers";
import { COMMON_STRINGS } from "../../../Utils/Strings";

const departmentModel = {
  state: {
    isLoading: false,
    crudLoading: false,
    error: null,
    departments: null,
    departmentFilter: null,
    total: 0,
  },
  reducers: {
    loading: (state, { isLoading }) => {
      return { ...state, isLoading };
    },
    crudLoading: (state, { isLoading }) => {
      return { ...state, crudLoading: isLoading };
    },
    departments: (state, { departments, total }) => {
      return {
        ...state,
        departments,
        total,
        isLoading: false,
        error: null,
        crudLoading: false,
      };
    },
    departmentFilter: (state, { departmentFilter }) => {
      return {
        ...state,
        departmentFilter,
        isLoading: false,
        error: null,
        crudLoading: false,
      };
    },
    error: (state, { error }) => {
      return {
        ...state,
        departments: null,
        total: 0,
        isLoading: false,
        crudLoading: false,
        error: error.message,
      };
    },
    resetDepartments: (state) => {
      return {
        isLoading: false,
        crudLoading: false,
        error: null,
        departments: null,
        departmentFilter: null,
        total: 0,
      };
    },
  },
  effects: (dispatch) => ({
    getDepartmentsByPage: async ({ page, limit, filter }, state) => {
      try {
        let { branchId } = state.login;
        dispatch.departments.loading({ isLoading: true });
        const response = await DepartmentNetwork.getDepartmentsByPage(
          page,
          limit,
          branchId,
          filter
        );
        if (response.status !== 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.DEPARTMENTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        const { rows: departments, count: total } = response.data.data;
        dispatch.departments.departments({ departments, total });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.departments.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.DEPARTMENTS,
          error.response.data.message,
          2
        );
      }
    },
    addDepartment: async ({ data, page, limit }, state) => {
      try {
        let { branchId } = state.login;
        data["branchId"] = branchId;
        dispatch.departments.crudLoading({ isLoading: true });
        const response = await DepartmentNetwork.addDepartment(data);
        if (response.status != 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.DEPARTMENTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.departments.getDepartmentsByPage({ page, limit });
        return successToastr(
          COMMON_STRINGS.MODEL_MODULE.DEPARTMENTS,
          COMMON_STRINGS.CRUD_MESSAGE.ADD_DATA("Department"),
          2
        );
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.departments.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.DEPARTMENTS,
          error.response.data.message,
          2
        );
      }
    },
    updateDepartment: async ({ data, page, limit }, state) => {
      try {
        let { branchId } = state.login;
        data["branchId"] = branchId;
        dispatch.departments.crudLoading({ isLoading: true });
        const response = await DepartmentNetwork.updateDepartment(data);
        if (response.status != 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.DEPARTMENTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.departments.getDepartmentsByPage({ page, limit });
        return successToastr(
          COMMON_STRINGS.MODEL_MODULE.DEPARTMENTS,
          COMMON_STRINGS.CRUD_MESSAGE.UPDATE_DATA("Department"),
          2
        );
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.departments.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.DEPARTMENTS,
          error.response.data.message,
          2
        );
      }
    },
    filterDepartment: async ({ filter }, state) => {
      try {
        let { branchId } = state.login;
        let response;
        dispatch.departments.loading({ isLoading: true });
        if (isValidString(filter) && filter.length > 0) {
          response = await DepartmentNetwork.filterDepartment(filter, branchId);
          if (response.status !== 200) {
            return errorToastr(
              COMMON_STRINGS.MODEL_MODULE.DEPARTMENTS,
              COMMON_STRINGS.API_ERROR.SERVER_FAILED,
              2
            );
          }
          dispatch.departments.departmentFilter({
            departmentFilter: response.data.data,
          });
        } else {
          response = await DepartmentNetwork.getDepartmentsByPage(
            1,
            1000,
            branchId
          );
          if (response.status !== 200) {
            return errorToastr(
              COMMON_STRINGS.MODEL_MODULE.DEPARTMENTS,
              COMMON_STRINGS.API_ERROR.SERVER_FAILED,
              2
            );
          }
          dispatch.departments.departmentFilter({
            departmentFilter: response.data.data.rows,
          });
        }
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.departments.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.DEPARTMENTS,
          error?.response?.data?.message,
          2
        );
      }
    },
    deleteDepartment: async ({ data, page, limit }, state) => {
      try {
        let { branchId } = state.login;
        data["branchId"] = branchId;
        const response = await DepartmentNetwork.deleteDepartment(data);
        if (response.status != 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.DEPARTMENTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.departments.getDepartmentsByPage({ page, limit });
        return successToastr(
          COMMON_STRINGS.MODEL_MODULE.DEPARTMENTS,
          COMMON_STRINGS.CRUD_MESSAGE.DELETE_DATA("Department"),
          2
        );
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.products.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.DEPARTMENTS,
          error?.response?.data.message,
          2
        );
      }
    },
  }),
};

export default departmentModel;
