import React from "react";
import { Breadcrumb, Button, Space } from "antd";
import "./Appbar.css";
import AppbarStyles from "./AppbarStyles";
import { Link } from "react-router-dom";

type Props = {
    title?: string,
    onButtonHandler?: any,
    showAddButton?: boolean,
    breadcrumbs: any,
    buttonLoading?: boolean,
    showSecondayButton?: boolean,
    secondaryButtonHandler?: any,
    secondaryTitle?: string
}

function Appbar(props: Props) {
    const {
        title,
        onButtonHandler,
        showSecondayButton = false,
        secondaryButtonHandler,
        secondaryTitle,
        showAddButton = true,
        breadcrumbs,
        buttonLoading = false
    } = props;
    return (
        <div style={AppbarStyles.container}>
            <div style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                marginLeft: 10
            }}>
                <h5>{title}</h5>
                <Breadcrumb className="breadcrumb">
                    {breadcrumbs.map((breadcrumb, index) => {
                        return (
                            <Breadcrumb.Item key={index}>
                                <Link to={breadcrumb.path}>
                                    {breadcrumb.label}
                                </Link>
                            </Breadcrumb.Item>
                        )
                    })}
                </Breadcrumb>
            </div>
            {(showAddButton || showSecondayButton) && (
                <div style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row-reverse',
                    alignItems: 'center'
                }}>
                    <Space style={{ marginRight: 10 }}>
                        {showSecondayButton && <Button onClick={secondaryButtonHandler}>{secondaryTitle}</Button>}
                        {showAddButton && <Button onClick={onButtonHandler} loading={buttonLoading}>{title}</Button>}
                    </Space>
                </div>
            )}
        </div>
    )
};

Appbar.defaultProps = {
    showAddButton: true,
}

export default Appbar;