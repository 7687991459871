import React, { useCallback, useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import UserForm from './MicroComponents/UserForm'
import MyModal from '../../../Components/Modal/MyModal';
import { isValidElement } from '../../../Utils/helpers';
import { usersValidations } from '../../../Utils/FormValidations';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
    visible: boolean,
    toggleModal: () => void,
    editForm?: any,
    resetEditForm?: any,
    page?: Number,
    limit?: Number,
    departmentId?: any,
    departmentModal: boolean,
}

function UserModal(props: Props) {
    const dispatch = useDispatch();

    const { visible, toggleModal, editForm, resetEditForm, page, limit, departmentId, departmentModal } = props;

    const { crudLoading } = useSelector((state: any) => ({
        crudLoading: state.users.crudLoading
    }))

    const [isEdit, setIsEdit] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            mobile: "",
            isActive: true,
            usersRoleId: null,
            isDepartmentUser: departmentModal,
            departmentId: departmentId
        },
        validationSchema: Yup.object(usersValidations),
        onSubmit: (values) => {
            console.log(values);
        },
    });

    useEffect(() => {
        if (isValidElement(editForm)) {
            formik.setValues(editForm);
            setIsEdit(true)
        }
        return function cleanUp() {
            formik.resetForm();
            resetEditForm();
            setIsEdit(false)
        }
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        if (isValidElement(editForm)) {
            dispatch.users.updateUser({ data: formik.values, page, limit })
                .then(r => {
                    toggleModal();
                })
        } else {
            dispatch.users.addUser({ data: formik.values, page, limit })
                .then(r => {
                    toggleModal();
                })
        }
    }, [formik.values]);

    return (
        <MyModal
            title="Users"
            visible={visible}
            onSubmitHandler={handleSubmit}
            onCancelHandler={toggleModal}
            okButtonDisabled={!(formik.dirty && formik.isValid)}
            buttonLoading={crudLoading}
        >
            <UserForm formik={formik} isEdit={isEdit} departmentId={departmentId} departmentModal={departmentModal} />
        </MyModal>
    )
}

export default UserModal