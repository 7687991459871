import { SubStoreNetwork } from "../../API/Networks";
import { errorToastr, successToastr } from "../../Components/Toastr/MyToastr";
import { jwtExpired } from "../../Utils/helpers";
import { COMMON_STRINGS } from "../../Utils/Strings";

const subStoreModel = {
  state: {
    isLoading: false,
    subStorePurchases: null,
    subStorePurchaseDetails: null,
    total: 0,
    error: null,
    crudLoading: false,
    subStoreSupplies: null,
    subStoreSupplyDetails: null,
    subStoreProducts: null,
    subStoreProductsLoading: false,
  },
  reducers: {
    loading: (state, { isLoading }) => {
      return { ...state, isLoading };
    },
    crudLoading: (state, { crudLoading }) => {
      return { ...state, crudLoading };
    },
    subStorePurchases: (state, { subStorePurchases, total }) => {
      return {
        ...state,
        subStorePurchases,
        total,
        isLoading: false,
        error: null,
      };
    },
    subStorePurchaseDetails: (state, { subStorePurchaseDetails }) => {
      return {
        ...state,
        subStorePurchaseDetails,
        isLoading: false,
        error: null,
      };
    },
    subStoreSupplyDetails: (state, { subStoreSupplyDetails }) => {
      return { ...state, subStoreSupplyDetails, isLoading: false, error: null };
    },
    subStoreSupplies: (state, { subStoreSupplies, total }) => {
      return {
        ...state,
        subStoreSupplies,
        total,
        isLoading: false,
        crudLoading: false,
        error: null,
      };
    },
    error: (state, { error }) => {
      return {
        ...state,
        isLoading: false,
        crudLoading: false,
        subStorePurchases: null,
        error,
      };
    },
    resetPurchaseDetails: (state) => {
      return {
        ...state,
        isLoading: false,
        error: null,
        subStorePurchaseDetails: null,
      };
    },
    resetSubStoresSupplies: (state) => {
      return {
        ...state,
        isLoading: false,
        error: null,
        subStoreSupplyDetails: null,
        total: 0,
        subStoreSupplies: null,
      };
    },
    subStoreProductLoading: (state, { isLoading }) => {
      return { ...state, subStoreProductsLoading: isLoading };
    },
    subStoreProducts: (state, { subStoreProducts }) => {
      return { ...state, subStoreProductsLoading: false, subStoreProducts };
    },
    appendSubStoreProducts: (state, product) => {
      let updatedSubStoreProducts = state.subStoreProducts.concat({
        ...product,
      });
      return { ...state, subStoreProducts: updatedSubStoreProducts };
    },
  },
  effects: (dispatch) => ({
    getSubStorePurchase: async ({ page, limit, status }, state) => {
      try {
        let { branchId, departmentId } = state.login;
        dispatch.subStores.loading({ isLoading: true });
        const response: any = await SubStoreNetwork.getSubStoreByPage(
          page,
          limit,
          branchId,
          status,
          departmentId
        );
        if (response.status !== 200) {
          dispatch.subStores.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        const { rows: subStorePurchases, count: total } = response.data.data;
        dispatch.subStores.subStorePurchases({ subStorePurchases, total });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.subStores.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          error.response.data.message,
          2
        );
      }
    },
    getSubStoreById: async ({ id }, state) => {
      try {
        let { branchId, departmentId } = state.login;
        dispatch.subStores.loading({ isLoading: true });
        const response: any = await SubStoreNetwork.getSubStoreById(
          id,
          branchId,
          departmentId
        );
        if (response.status !== 200) {
          dispatch.subStores.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }

        dispatch.subStores.subStorePurchaseDetails({
          subStorePurchaseDetails: response.data.data,
        });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.subStores.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          error.response.data.message,
          2
        );
      }
    },
    makeApprovalStatus: async ({ id, data }, state) => {
      try {
        let { branchId, departmentId } = state.login;
        dispatch.subStores.crudLoading({ crudLoading: false });
        let response = await SubStoreNetwork.makeApproval(
          id,
          data,
          departmentId,
          branchId
        );
        if (response.status !== 200) {
          dispatch.subStores.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }

        await dispatch.subStores.getSubStoreById({ id });
        return successToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          COMMON_STRINGS.CRUD_MESSAGE.UPDATE_DATA("Status"),
          2
        );
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.subStores.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          error.response.data.message,
          2
        );
      }
    },
    getSubStoreSupplies: async ({ page, limit }, state) => {
      try {
        let { branchId, departmentId } = state.login;
        dispatch.subStores.loading({ isLoading: true });
        let response: any = await SubStoreNetwork.getSubStoreSuppliesByPage(
          page,
          limit,
          departmentId,
          branchId
        );
        if (response.status !== 200) {
          dispatch.subStores.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        const { rows: subStoreSupplies, count: total } = response.data.data;
        dispatch.subStores.subStoreSupplies({ subStoreSupplies, total });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.subStores.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          error.response.data.message,
          2
        );
      }
    },
    getSubStoreSupplyById: async ({ id }, state) => {
      try {
        let { branchId, departmentId } = state.login;
        dispatch.subStores.loading({ isLoading: true });
        let response: any = await SubStoreNetwork.getSubStoresSupplyId(
          id,
          departmentId,
          branchId
        );
        if (response.status !== 200) {
          dispatch.subStores.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.subStores.loading({ isLoading: false });
        return response?.data?.data;
        dispatch.subStores.subStoreSupplyDetails({
          subStoreSupplyDetails: response.data.data,
        });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.subStores.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          error.response.data.message,
          2
        );
      }
    },
    getSubStoreProductsForDropdown: async ({ filter }, state) => {
      try {
        let { branchId, departmentId } = state.login;
        let data = {};
        data["filter"] = filter;
        dispatch.subStores.subStoreProductLoading({ isLoading: true });
        let response: any = await SubStoreNetwork.subStoreProductFilter(
          data,
          departmentId,
          branchId
        );
        if (response.status !== 200) {
          dispatch.subStores.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }

        dispatch.subStores.subStoreProducts({
          subStoreProducts: response.data.data,
        });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.subStores.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          error?.response?.data?.message,
          2
        );
      }
    },
    getSubStorePurchaseTransactionDetail: async (data, state) => {
      try {
        let { branchId, departmentId } = state.login;
        let response: any =
          await SubStoreNetwork.subStorePurchaseTransactionById(
            data,
            departmentId,
            branchId
          );
        if (response.status !== 200) {
          dispatch.subStores.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        return response.data.data;
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.subStores.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          error?.response?.data?.message,
          2
        );
      }
    },
    addSubStoreSupplyTransaction: async ({ data }, state) => {
      try {
        let { branchId, departmentId } = state.login;
        data["branchId"] = branchId;
        data["departmentId"] = departmentId;
        dispatch.subStores.crudLoading({ isLoading: true });
        let response: any = await SubStoreNetwork.addSubStoreSupplyTransaction(
          data,
          departmentId,
          branchId
        );
        if (response.status !== 200) {
          dispatch.subStores.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.subStores.crudLoading({ isLoading: false });
        return successToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          COMMON_STRINGS.CRUD_MESSAGE.ADD_DATA("Supply"),
          2
        );
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.subStores.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          error?.response?.data?.message,
          2
        );
      }
    },
    updateSubStoreSupplyTransaction: async ({ data }, state) => {
      try {
        let { branchId, departmentId } = state.login;
        data["branchId"] = branchId;
        data["departmentId"] = departmentId;
        dispatch.subStores.crudLoading({ isLoading: false });
        let response: any =
          await SubStoreNetwork.updateSubStoreSupplyTransaction(
            data,
            departmentId,
            branchId
          );
        if (response.status !== 200) {
          dispatch.subStores.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.subStores.crudLoading({ isLoading: false });
        return successToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          COMMON_STRINGS.CRUD_MESSAGE.UPDATE_DATA("Supply"),
          2
        );
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.subStores.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          error?.response?.data?.message,
          2
        );
      }
    },
    deleteSubSupplyTransaction: async ({ id }, state) => {
      try {
        let { branchId, departmentId } = state.login;
        let data = {};
        data["id"] = id;
        dispatch.subStores.crudLoading({ isLoading: false });
        let response: any =
          await SubStoreNetwork.deleteSubStoreSupplyTransaction(
            data,
            departmentId,
            branchId
          );

        if (response.status !== 200) {
          dispatch.subStores.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.subStores.crudLoading({ isLoading: true });
        return successToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          COMMON_STRINGS.CRUD_MESSAGE.DELETE_DATA("Sub Store Transaction"),
          2
        );
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.subStores.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          error?.response?.data?.message,
          2
        );
      }
    },
    deleteSubStoreSupply: async ({ id, page, limit }, state) => {
      try {
        let { branchId, departmentId } = state.login;
        let data = {};
        data["id"] = id;
        dispatch.subStores.crudLoading({ isLoading: true });
        let response: any = await SubStoreNetwork.deleteSubStoresSupply(
          data,
          departmentId,
          branchId
        );
        if (response.status !== 200) {
          dispatch.subStores.error({ error: "Server failed to load" });
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.subStores.getSubStoreSupplies({ page, limit });
        return successToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          COMMON_STRINGS.CRUD_MESSAGE.DELETE_DATA("Sub Store Supply"),
          2
        );
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.subStores.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.SUBSTORE,
          error?.response?.data?.message,
          2
        );
      }
    },
  }),
};

export default subStoreModel;
