import React, { useCallback, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import MyModal from "../../../../Components/Modal/MyModal";
import VendorForm from "./MicroComponents/VendorForm";
import { vendorValidations } from "../../../../Utils/FormValidations";
import { isValidElement } from "../../../../Utils/helpers";
import { useDispatch } from "react-redux";

type Props = {
  visible: boolean;
  toggleModal: () => void;
  resetEditForm?: any;
  editForm?: any;
  page?: Number;
  limit?: Number;
};

function VendorModal(props: Props) {
  const { visible, toggleModal, editForm, resetEditForm, page, limit } = props;
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      mobile: "",
    },
    validationSchema: Yup.object(vendorValidations),
    onSubmit: (values) => {
      console.log(values);
    },
  });
  useEffect(() => {
    if (isValidElement(editForm)) {
      formik.setValues(editForm);
    }
    return function cleanUp() {
      formik.resetForm();
      resetEditForm();
    };
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (isValidElement(editForm)) {
        dispatch.vendors
          .updateVendor({ data: formik.values, page, limit })
          .then((r) => {
            toggleModal();
          })
          .catch((err) => toggleModal());
      } else {
        dispatch.vendors
          .addVendor({ data: formik.values, page, limit })
          .then((r) => {
            toggleModal();
          })
          .catch((err) => toggleModal());
      }
    },
    [formik.values]
  );

  return (
    <MyModal
      title="Vendors"
      visible={visible}
      onSubmitHandler={handleSubmit}
      onCancelHandler={toggleModal}
      okButtonDisabled={!(formik.isValid && formik.dirty)}
    >
      <VendorForm formik={formik} />
    </MyModal>
  );
}

export default VendorModal;
