import { USER_ROLE } from "./Constants";

export const crudBasedAuth = (userRole, userPermission, operation) => {
    switch (userRole?.name) {
        case USER_ROLE.SUPER:
            return userPermission[operation]
        case USER_ROLE.ADMIN:
            return userPermission[operation]
        case USER_ROLE.EDITOR:
            return userPermission[operation]
        case USER_ROLE.VIEWER:
            return userPermission[operation]
        default:
            return false
    }
};

export const isSuperAdmin = (role) => {
    return role === USER_ROLE.SUPER
};

export const isAdmin = (role) => {
    return role === USER_ROLE.ADMIN
}

export const isEditor = (role) => {
    return role === USER_ROLE.EDITOR
}

