import React, { useState } from "react";
import { Form, Input, Select, Radio } from "antd";
import { formItemLayout, USER_ROLE } from "../../../../Utils/Constants";
import MyError from "../../../../Components/MyError/MyError";
import { useSelector } from "react-redux";
import { userState } from "../../../../store/selectors";
import { isSuperAdmin } from "../../../../Utils/Authorization";
import departmentModel from "../../../Master/Department/departmentModel";

const { Option } = Select;
type Props = {
  formik: any;
  isEdit: boolean;
  departmentId?: any;
  departmentModal: boolean;
};

export default function UserForm(props: Props) {
  let userSelector = userState();
  const { formik, isEdit, departmentId, departmentModal } = props;

  const [search, setSearch] = useState("");

  const { userRoles } = useSelector((state: any) => ({
    userRoles: state.users.userRoles,
  }));

  const { departments } = useSelector((state: any) => ({
    departments: state.departments.departments,
  }));

  const onSelectHandler = (field, value) => {
    if (field === "isDepartmentUser") {
      if (!value) {
        formik.setFieldValue("departmentId", departmentId);
      }
    }
    formik.setFieldValue(field, value);
  };

  const filterOption = (
    input: string,
    option?: { children: string; value: number }
  ) => {
    return (option?.children ?? "").toLowerCase().includes(input.toLowerCase());
  };

  const renderDepartmentDropdown = () => {
    return (
      <Form.Item label="Select Department">
        <Select
          value={formik.values.departmentId}
          placeholder="Select role"
          onChange={(value) => onSelectHandler("departmentId", value)}
          showSearch
          filterOption={filterOption}
          onSearch={setSearch}
        >
          {departments.map((department) => {
            return (
              <Option key={department.id} value={department.id}>
                {department?.name}
              </Option>
            );
          })}
        </Select>
        {formik.touched.departmentId && formik.errors.departmentId ? (
          <MyError error={formik.errors.departmentId} />
        ) : null}
      </Form.Item>
    );
  };

  return (
    <>
      <Form {...formItemLayout} name="register" scrollToFirstError>
        <Form.Item label="Name">
          <Input
            type="text"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Name"
          />
          {formik.touched.name && formik.errors.name ? (
            <MyError error={formik.errors.name} />
          ) : null}
        </Form.Item>
        <Form.Item label="Email">
          <Input
            type="text"
            name="email"
            disabled={isEdit}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Email"
          />
          {formik.touched.email && formik.errors.email ? (
            <MyError error={formik.errors.email} />
          ) : null}
        </Form.Item>
        <Form.Item label="Mobile">
          <Input
            type="number"
            name="mobile"
            value={formik.values.mobile}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Mobile"
          />
          {formik.touched.mobile && formik.errors.mobile ? (
            <MyError error={formik.errors.mobile} />
          ) : null}
        </Form.Item>
        {/* {!departmentId && departmentModal && <Form.Item label="Department User">
                    <Radio.Group onChange={(e) => onSelectHandler("isDepartmentUser", e.target.value)} value={formik.values.isDepartmentUser}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                    {formik.touched.isDepartmentUser && formik.errors.isDepartmentUser ? (
                        <MyError error={formik.errors.isDepartmentUser} />
                    ) : null}
                </Form.Item>} */}
        {!departmentId &&
          departmentModal &&
          Array.isArray(departments) &&
          formik.values.isDepartmentUser &&
          renderDepartmentDropdown()}
        <Form.Item label="Select Role">
          <Select
            value={formik.values.usersRoleId}
            placeholder="Select role"
            onChange={(value) => onSelectHandler("usersRoleId", value)}
            disabled={isEdit && formik.values.usersRoleId == 1}
          >
            {userRoles.map((role) => {
              if (
                isSuperAdmin(userSelector?.users_role?.name) &&
                !departmentModal
              ) {
                return (
                  <Option key={role.id} value={role.id}>
                    {role.name}
                  </Option>
                );
              } else if (role.name !== USER_ROLE.SUPER) {
                return (
                  <Option key={role.id} value={role.id}>
                    {role.name}
                  </Option>
                );
              }
            })}
          </Select>
          {formik.touched.usersRoleId && formik.errors.usersRoleId ? (
            <MyError error={formik.errors.usersRoleId} />
          ) : null}
        </Form.Item>

        <Form.Item label="Active">
          <Radio.Group
            onChange={(e) => onSelectHandler("isActive", e.target.value)}
            value={formik.values.isActive}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
          {formik.touched.isActive && formik.errors.isActive ? (
            <MyError error={formik.errors.isActive} />
          ) : null}
        </Form.Item>
      </Form>
    </>
  );
}
