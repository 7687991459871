import React from "react";
import { Form, Input, Radio } from "antd";
import MyError from "../../../../../Components/MyError/MyError";
import { formItemLayout } from "../../../../../Utils/Constants";

type Props = {
  formik: any;
};

export default function DepartmentForm(props: Props) {
  const { formik } = props;
  return (
    <>
      <Form {...formItemLayout} name="register" scrollToFirstError>
        <Form.Item label="Department Name">
          <Input
            type="text"
            name="name"
            value={formik?.values?.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Department Name"
          />
          {formik.touched.name && formik.errors.name ? (
            <MyError error={formik.errors.name} />
          ) : null}
        </Form.Item>
        <Form.Item label="Department No.">
          <Input
            type="text"
            name="departmentNo"
            value={formik?.values?.departmentNo}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Department Name"
          />
          {formik.touched.departmentNo && formik.errors.departmentNo ? (
            <MyError error={formik.errors.departmentNo} />
          ) : null}
        </Form.Item>
        <Form.Item label="Status">
          <Radio.Group
            name="isActive"
            value={formik.values.isActive}
            onChange={formik.handleChange}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
          {formik.touched.isActive && formik.errors.isActive ? (
            <MyError error={formik.errors.isActive} />
          ) : null}
        </Form.Item>
      </Form>
    </>
  );
}
