const appDeciderStyles = {
    mainContainer: {
        display: 'flex',
        flexDirection:'column',
        flex: 1,
        height: "100vh",
        justifyContent: 'center',
        alignItems: 'center',
        
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: window.innerHeight / 2,
        overflowY: 'scroll'
    },
    childContainer: {
        display: 'flex',
        flexDirection: 'column',
    }
}

export default appDeciderStyles;