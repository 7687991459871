import { baseURL } from "../../API/api";
import {
  ReportsNetwork,
  UsersNetwork,
  UsersRolesNetwork,
} from "../../API/Networks";
import { errorToastr, successToastr } from "../../Components/Toastr/MyToastr";
import { isValidElement, jwtExpired } from "../../Utils/helpers";
import { COMMON_STRINGS } from "../../Utils/Strings";

const reportsModel = {
  state: {
    isLoading: false,
    crudLoading: false,
    error: null,
    reports: null,
    total: 0,
  },
  reducers: {
    loading: (state, { isLoading }) => {
      return { ...state, isLoading };
    },
    crudLoading: (state, { isLoading }) => {
      return { ...state, crudLoading: isLoading };
    },
    reports: (state, { reports, total }) => {
      return {
        ...state,
        reports,
        total,
        isLoading: false,
        error: null,
        crudLoading: false,
      };
    },
    error: (state, { error }) => {
      return {
        ...state,
        reports: null,
        total: 0,
        isLoading: false,
        crudLoading: false,
        error: error.message,
      };
    },
  },
  effects: (dispatch) => ({
    getAllReportsByPage: async ({ page, limit }, state) => {
      try {
        let { branchId } = state.login;
        dispatch.reports.loading({ isLoading: true });
        const response = await ReportsNetwork.getReportByPage(
          page,
          limit,
          branchId
        );
        if (response.status !== 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.REPORTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        const { rows: reports, count: total } = response.data.data;
        dispatch.reports.reports({ reports, total });
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.reports.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.REPORTS,
          error.response.data.message,
          2
        );
      }
    },
    addReports: async ({ page, limit, exportFilters }, state) => {
      try {
        let { branchId } = state.login;
        dispatch.reports.crudLoading({ isLoading: true });
        let response = await ReportsNetwork.addReports({
          branchId,
          fiters:exportFilters,
        });
        if (response.status !== 200) {
          return errorToastr(
            COMMON_STRINGS.MODEL_MODULE.REPORTS,
            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
            2
          );
        }
        dispatch.reports.crudLoading({ isLoading: false });
        dispatch.reports.getAllReportsByPage({ page, limit });
        return successToastr(
          COMMON_STRINGS.MODEL_MODULE.REPORTS,
          COMMON_STRINGS.CRUD_MESSAGE.ADD_DATA("Reports"),
          2
        );
      } catch (error) {
        jwtExpired(error, dispatch);
        dispatch.reports.error({ error });
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.REPORTS,
          error.response.data.message,
          2
        );
      }
    },
  }),
};

export default reportsModel;
