import { routes } from "../../Container/Navigation/Constants";

export const SubStoresBreadCrumb = (status: string) => [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: status === SUBSTORE_STATUS.PENDING ? "Main Store Pending" : "Main Store Approved", path: status === SUBSTORE_STATUS.PENDING ? routes.MAIN_STORE_PENDING : routes.MAIN_STORE_APPROVED }
];

export const SubStoreProductsBreadCrumb = [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: "Products", path: routes.SUBSTORE_PRODUCTS },
]

export const SubStoresDetailBreadCrumb = (status: string) => [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: status === SUBSTORE_STATUS.PENDING ? "Main Store Pending" : "Main Store Approved", path: status === SUBSTORE_STATUS.PENDING ? routes.MAIN_STORE_PENDING : routes.MAIN_STORE_APPROVED },
    { label: "Product Detail", path: "" }
];

export const SubStoresSupplyBreadCrumb = [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: "Supply", path: routes.SUB_STORE_SUPPLY },
];

export const SubStoresSupplyFormBreadCrumb = (isEdit) => [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: "Supply", path: routes.SUB_STORE_SUPPLY },
    { label: isEdit ? "Edit Supply" : "Add Supply", path: "" }

]

export const SUBSTORE_STATUS = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED'
}