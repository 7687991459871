import { DatePicker, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { formItemLayout } from "../../../Utils/Constants";
import MyError from "../../../Components/MyError/MyError";
import { useDispatch, useSelector } from "react-redux";
import { isValidString } from "../../../Utils/helpers";

const { RangePicker } = DatePicker;
const { Option } = Select;

function ReportsForm({ formik }) {
  const dispatch = useDispatch();
  const categories = useSelector((state: any) => state.categories.categories);

  const [categorySearch, setCategorySearch] = useState("");
  const [productSearch, setProductSearch] = useState("");
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (isValidString(categorySearch)) {
      dispatch.categories.getCategoriesByPage({
        page: 1,
        limit: 200,
        filter: categorySearch,
      });
    } else {
      dispatch.categories.getCategoriesByPage({ page: 1, limit: 200 });
    }
  }, [categorySearch]);

  async function getProducts() {
    let response = await dispatch.products.filterProductForReports({
      filter: productSearch,
      categoryId: formik.values.categoryId,
    });
    setProducts(response);
  }

  useEffect(() => {
    getProducts();
  }, [productSearch, formik.values.categoryId]);

  const renderDate = () => {
    return (
      <Form.Item label="Date Range">
        <RangePicker
          value={[formik.values.fromDate, formik.values.toDate]}
          onChange={(dates, dateString) => {
            let start = dates[0];
            let end = dates[1];
            formik.setFieldValue("fromDate", start);
            formik.setFieldValue("toDate", end);
          }}
        />
      </Form.Item>
    );
  };

  const renderCategories = () => {
    return (
      <Form.Item label="Categories">
        <Select
          showSearch
          placeholder="Select Category"
          value={formik.values.categoryId}
          optionFilterProp="children"
          onBlur={formik.handleBlur}
          onChange={(value) => {
            formik.setFieldValue("categoryId", value);
          }}
          onSearch={(search) => {
            setCategorySearch(search);
          }}
        >
          {Array.isArray(categories) &&
            categories.length > 0 &&
            categories.map((category) => {
              return (
                <Option key={category.id} value={category.id}>
                  {category.name}
                </Option>
              );
            })}
        </Select>
        {formik.touched.categoryId && formik.errors.categoryId ? (
          <MyError error={formik.errors.categoryId} />
        ) : null}
      </Form.Item>
    );
  };

  const renderProducts = () => {
    return (
      <Form.Item label="Products">
        <Select
          showSearch
          placeholder="Select Product"
          value={formik.values.productId}
          optionFilterProp="children"
          onBlur={formik.handleBlur}
          onChange={(value) => {
            formik.setFieldValue("productId", value);
          }}
          onSearch={(search) => {
            setProductSearch(search);
          }}
        >
          {Array.isArray(products) &&
            products.length > 0 &&
            products.map((product) => {
              return (
                <Option key={product.id} value={product.id}>
                  {product.name} - {product.codeNo}
                </Option>
              );
            })}
        </Select>
        {formik.touched.productId && formik.errors.productId ? (
          <MyError error={formik.errors.productId} />
        ) : null}
      </Form.Item>
    );
  };

  return (
    <>
      <Form {...formItemLayout} name="register" scrollToFirstError>
        {renderDate()}
        {renderCategories()}
        {renderProducts()}
      </Form>
    </>
  );
}

export default React.memo(ReportsForm);
