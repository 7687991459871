import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Appbar from "../../../../Components/Appbar/Appbar";
import MyCard from "../../../../Components/Card/MyCard";
import EditableTable from "../../../../Components/EditableTable/EditableTable";
import { isValidElement, isValidSupplyForm } from "../../../../Utils/helpers";
import { SubStoresSupplyFormBreadCrumb } from "../../Constants";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { subStoreSupplyValidation } from "../../../../Utils/FormValidations";
import "./index.css";
import { errorToastr } from "../../../../Components/Toastr/MyToastr";
import { COMMON_STRINGS } from "../../../../Utils/Strings";
import { routes } from "../../../../Container/Navigation/Constants";
const { Option } = Select;

export default function SubStoreSupplyForm() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { subStoreProducts, crudLoading } = useSelector((state: any) => ({
    subStoreProducts: state.subStores.subStoreProducts,
    crudLoading: state.subStores.crudLoading,
  }));

  const { departmentFilter } = useSelector((state: any) => ({
    departmentFilter: state.departments.departmentFilter,
  }));

  const departmentId = useSelector((state: any) => state?.login?.departmentId);

  const [isEdit, setIsEdit] = useState(
    isValidElement(location.pathname) && location.pathname.includes("view")
      ? true
      : false
  );
  const [searchText, setSearchText] = useState("");
  const [purchaseTransaction, setPurchaseTransaction] = useState(null);
  const [searchDepartment, setSearchDepartment] = useState("");

  const formik = useFormik({
    initialValues: {
      date: moment(),
      pinNo: "",
      assignDepartmentId: null,
      supplyItems: [].concat(Object.assign({ itemNameRef: null })),
    },
    validationSchema: Yup.object(subStoreSupplyValidation),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  useEffect(() => {
    dispatch.subStores.getSubStoreProductsForDropdown({ filter: searchText });
  }, [searchText]);

  useEffect(() => {
    if (searchDepartment.length > 0) {
      dispatch.departments.filterDepartment({ filter: searchDepartment });
    } else {
      dispatch.departments.filterDepartment({});
    }
  }, [searchDepartment]);

  useEffect(() => {
    let data = !!location.state
      ? JSON.parse(JSON.stringify(location.state))
      : null;
    if (isValidElement(data) && isValidElement(data?.data)) {
      if (data?.data?.id) {
        dispatch.subStores
          .getSubStoreSupplyById({ id: data?.data?.id })
          .then((subStoreSupply) => {
            let {
              sub_stores_supplies_transactions,
              pinNo,
              date,
              assignDepartmentId,
            } = subStoreSupply;
            formik.setValues({
              date: moment(date),
              pinNo: pinNo,
              assignDepartmentId: assignDepartmentId,
              supplyItems:
                Array.isArray(sub_stores_supplies_transactions) &&
                sub_stores_supplies_transactions.length > 0
                  ? sub_stores_supplies_transactions.map(
                      (sub_stores_supplies_transaction: any) => {
                        let data = {};
                        let isItemExistsInDropdown =
                          Array.isArray(subStoreProducts) &&
                          subStoreProducts.length > 0 &&
                          subStoreProducts.find((product) => {
                            return (
                              product.id ===
                              sub_stores_supplies_transaction.subStoresPurchaseTransactionId
                            );
                          });
                        if (!isItemExistsInDropdown) {
                          dispatch.subStores
                            .getSubStorePurchaseTransactionDetail({
                              id: sub_stores_supplies_transaction.subStoresPurchaseTransactionId,
                            })
                            .then((subStorePurchaseTransaction: any) => {
                              dispatch.subStores.appendSubStoreProducts({
                                ...subStorePurchaseTransaction,
                              });
                            });
                        }
                        data["productId"] =
                          sub_stores_supplies_transaction.productId;
                        data["productsMetaId"] =
                          sub_stores_supplies_transaction.productsMetumId;
                        data["supplyId"] =
                          sub_stores_supplies_transaction.supplyId;
                        data["subStorePurchaseTransactionId"] =
                          sub_stores_supplies_transaction.subStoresPurchaseTransactionId;
                        data["batchNo"] =
                          sub_stores_supplies_transaction.products_meta.batchNo;
                        data["totalQty"] =
                          sub_stores_supplies_transaction.totalQty;
                        data["supplyQty"] =
                          sub_stores_supplies_transaction.supplyQty;
                        data["reqQty"] = sub_stores_supplies_transaction.reqQty;
                        data["subStoreSupplyTransactionId"] =
                          sub_stores_supplies_transaction.id;
                        return data;
                      }
                    )
                  : [],
            });
          });
      }
    }
  }, [isEdit, crudLoading]);

  useEffect(() => {
    let data = isValidElement(purchaseTransaction)
      ? JSON.parse(JSON.stringify(purchaseTransaction))
      : null;
    if (!!data) {
      formik.setFieldValue(
        `supplyItems.${data.index}.productId`,
        data.productId
      );
      formik.setFieldValue(
        `supplyItems.${data.index}.productsMetaId`,
        data.productsMetumId
      );
      formik.setFieldValue(`supplyItems.${data.index}.supplyId`, data.supplyId);
      formik.setFieldValue(
        `supplyItems.${data.index}.subStorePurchaseTransactionId`,
        data?.id
      );
      formik.setFieldValue(
        `supplyItems.${data.index}.batchNo`,
        data?.products_metum?.batchNo
      );
      formik.setFieldValue(
        `supplyItems.${data.index}.totalQty`,
        data?.totalQty
      );
      formik.setFieldValue(`supplyItems.${data.index}.qty`, data?.qty);
    }
  }, [purchaseTransaction]);

  function checkItemAlreadyExistsInSupplyItems(value) {
    if (
      Array.isArray(formik.values.supplyItems) &&
      formik.values.supplyItems.length > 0
    ) {
      let foundItem = formik.values.supplyItems.find(
        (supplyItem) => supplyItem.subStorePurchaseTransactionId === value
      );
      return !!foundItem;
    } else {
      return false;
    }
  }

  const onSelectHandler = (field, value, index) => {
    if (isNaN(index)) {
      formik.setFieldValue(field, value);
      return;
    }

    if (field === "subStorePurchaseTransactionId") {
      let checkItemExists = Array.isArray(formik.values.supplyItems)
        ? checkItemAlreadyExistsInSupplyItems(value)
        : false;
      if (checkItemExists) {
        formik.setFieldValue(
          `supplyItems.${index}.error`,
          "Item Already Exists in the above list"
        );
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.SUPPLY,
          COMMON_STRINGS.ERROR.ITEM_EXISTS,
          2
        );
      }
      formik.setFieldValue(`supplyItems.${index}.${field}`, value);
      formik.setFieldValue(`supplyItems.${index}.error`, null);
      dispatch.subStores
        .getSubStorePurchaseTransactionDetail({ id: value, index })
        .then((purchaseTransaction: any) => {
          let data = { ...purchaseTransaction };
          data["index"] = index;
          setPurchaseTransaction(data);
          dispatch.subStores.getSubStoreProductsForDropdown({});
        });
    }
  };

  const onDateHanlder = (date, dateString, field, index) => {
    if (!index) {
      formik.setFieldValue(field, moment(date));
      return;
    }
    formik.setFieldValue(`supplyItems.${index}.${field}`, date);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      addRow();
    }
  };

  const addRow = () => {
    formik.setFieldValue(
      "supplyItems",
      formik.values.supplyItems.concat(Object.assign({}))
    );
    setPurchaseTransaction(null);
  };

  const removeRow = (index) => {
    const originalState = formik.values.supplyItems;
    originalState.splice(index, 1);
    formik.setFieldValue("supplyItems", originalState);
  };

  const onSaveHandler = () => {
    if (!isEdit) {
      dispatch.subStores
        .addSubStoreSupplyTransaction({ data: formik.values })
        .then((r) => {
          return navigate(routes.SUB_STORE_SUPPLY);
        });
    } else {
      let data: any = formik.values;
      data["id"] = location.state["data"]["id"];
      dispatch.subStores
        .updateSubStoreSupplyTransaction({ data })
        .then((r) => navigate(routes.SUB_STORE_SUPPLY));
    }
  };

  const column = [
    {
      id: "sNo",
      label: "No.",
      key: "id",
      render: (row, index) => {
        return index + 1;
      },
    },
    {
      id: "productsMetaId",
      label: "Products",
      minWidth: 500,
      key: "productsMetaId",
      render: (row, index) => {
        return (
          <>
            <Select
              ref={(e) => {
                row["itemNameRef"] = e;
              }}
              style={{
                minWidth: "100%",
                borderColor:
                  isValidSupplyForm(
                    formik,
                    "subStorePurchaseTransactionId",
                    index
                  ) && "red",
              }}
              placeholder="Select Item"
              value={
                formik.values.supplyItems[index].subStorePurchaseTransactionId
              }
              onBlur={formik.handleBlur}
              optionFilterProp="children"
              showSearch
              onChange={(value) =>
                onSelectHandler("subStorePurchaseTransactionId", value, index)
              }
              onSearch={(search) => setSearchText(search)}
              disabled={row?.subStoreSupplyTransactionId}
            >
              {Array.isArray(subStoreProducts) &&
                subStoreProducts.map((subStoreProduct) => {
                  return (
                    <Select.Option
                      key={subStoreProduct.id}
                      value={subStoreProduct.id}
                    >
                      {subStoreProduct?.products_metum?.codeNo} -{" "}
                      {subStoreProduct?.products_metum?.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </>
        );
      },
    },
    // {
    //   id: "batchNo",
    //   label: "Batch No",
    //   key: "batchNo",
    //   minWidth: 120,
    //   render: (row, index) => {
    //     return (
    //       <p style={{ color: "#000", marginTop: 5, textAlign: "center" }}>
    //         {!!formik.values.supplyItems[index].batchNo
    //           ? formik.values.supplyItems[index].batchNo
    //           : "-"}
    //       </p>
    //     );
    //   },
    // },
    {
      id: "totalQty",
      label: "Total Stock",
      key: "totalQty",
      minWidth: 120,
      render: (row, index) => {
        return (
          <p style={{ color: "#000", marginTop: 5, textAlign: "center" }}>
            {!!formik.values.supplyItems[index].totalQty
              ? formik.values.supplyItems[index].totalQty
              : "-"}
          </p>
        );
      },
    },
    {
      id: "reqQty",
      label: "Req. Qty",
      minWidth: 120,
      key: "reqQty",
      render: (row, index) => {
        return (
          <>
            {!isEdit &&
            formik.values.supplyItems[index].supplyQty >
              formik.values.supplyItems[index].totalQty ? (
              <p style={{ color: "red", marginTop: 5, textAlign: "center" }}>
                over stock
              </p>
            ) : (
              <Input
                placeholder="Req Qty"
                type="number"
                name={`supplyItems.${index}.reqQty`}
                value={formik.values.supplyItems[index].reqQty}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{
                  borderColor:
                    isValidSupplyForm(formik, "reqQty", index) && "red",
                }}
                onKeyPress={handleKeyPress}
                disabled={row.subStoreSupplyTransactionId}
              />
            )}
          </>
        );
      },
    },
    // {
    //   id: "stockInBatch",
    //   label: "Stock in batch",
    //   key: "stockInBatch",
    //   minWidth: 120,
    //   render: (row, index) => {
    //     return (
    //       <p style={{ color: "#000", marginTop: 5, textAlign: "center" }}>
    //         {!!formik.values.supplyItems[index].qty
    //           ? formik.values.supplyItems[index].qty
    //           : "-"}
    //       </p>
    //     );
    //   },
    // },
    {
      id: "supplyQty",
      label: "Supply Qty",
      minWidth: 120,
      key: "supplyQty",
      render: (row, index) => {
        return (
          <>
            {!isEdit &&
            formik.values.supplyItems[index].supplyQty >
              formik.values.supplyItems[index].totalQty ? (
              <p style={{ color: "red", marginTop: 5, textAlign: "center" }}>
                over stock
              </p>
            ) : (
              <Input
                placeholder="Supply Qty"
                type="number"
                name={`supplyItems.${index}.supplyQty`}
                value={formik.values.supplyItems[index].supplyQty}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{
                  borderColor:
                    isValidSupplyForm(formik, "supplyQty", index) && "red",
                }}
                onKeyPress={handleKeyPress}
                disabled={row.subStoreSupplyTransactionId}
              />
            )}
          </>
        );
      },
    },
    {
      id: "action",
      label: "Action",
      textAlign: "center",
      render: (row, index) => {
        return (
          <>
            <Space size={2}>
              <Button type="primary" onClick={addRow}>
                +
              </Button>
              <Button
                type="primary"
                danger
                onClick={() => {
                  if (!!row.subStoreSupplyTransactionId) {
                    const { data } = isValidElement(location.state)
                      ? JSON.parse(JSON.stringify(location.state))
                      : null;
                    dispatch.subStores
                      .deleteSubSupplyTransaction({
                        id: row.subStoreSupplyTransactionId,
                      })
                      .then((r) => {
                        window.location.reload();
                      });
                  } else {
                    removeRow(index);
                  }
                }}
              >
                x
              </Button>
            </Space>
          </>
        );
      },
    },
  ];

  const renderPurchaseDetails = () => {
    return (
      <>
        <Row>
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ margin: 5 }}>
            <h5>Date</h5>
            <DatePicker
              allowClear={false}
              format="DD-MM-YYYY"
              style={{ width: "100%" }}
              name="date"
              value={formik.values.date}
              onChange={(date, dateString) =>
                onDateHanlder(date, dateString, "date", null)
              }
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ margin: 5 }}>
            <h5>Pin No</h5>
            <Input
              type="text"
              name="pinNo"
              placeholder="Pin No."
              value={formik.values.pinNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ margin: 5 }}>
            <h5>Select Department</h5>
            <Select
              showSearch
              optionFilterProp="children"
              value={formik.values.assignDepartmentId}
              placeholder="Select Department"
              style={{ width: "100%" }}
              onChange={(value) =>
                onSelectHandler("assignDepartmentId", value, "null")
              }
              onSearch={setSearchDepartment}
            >
              {Array.isArray(departmentFilter) &&
                departmentFilter.length > 0 &&
                departmentFilter
                  .filter((department) => department.id !== departmentId)
                  .map((department) => {
                    return (
                      <Option key={department?.id} value={department?.id}>
                        {department?.departmentNo} - {department?.name}
                      </Option>
                    );
                  })}
            </Select>
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
        </Row>
      </>
    );
  };

  const renderDipatchTable = () => {
    return (
      <Row style={{ marginTop: "5%" }}>
        <Col span={24}>
          <div
            style={{
              marginBottom: 10,
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={addRow}>+</Button>
          </div>
          <EditableTable rows={formik.values.supplyItems} columns={column} />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <MyCard>
        <Appbar
          breadcrumbs={SubStoresSupplyFormBreadCrumb(isEdit)}
          title="Save"
          showAddButton={formik.dirty && formik.isValid}
          onButtonHandler={onSaveHandler}
        />
        <Skeleton loading={false}>{renderPurchaseDetails()}</Skeleton>
        {renderDipatchTable()}
      </MyCard>
    </>
  );
}
