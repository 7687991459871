import { routes } from "../../Container/Navigation/Constants";

export const DispatchBreadCrumb = [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: "Supply", path: routes.DISPATCH_ROUTE }
];

export const DispatchAddBreadCrumb = (isEdit) => [
    { label: "Dashboard", path: routes.BASE_ROUTE },
    { label: "Supply", path: routes.DISPATCH_ROUTE },
    { label: "Issue form", path: !isEdit && routes.ADD_DISPATCH_ROUTE }
];

export const SUBSTORE_STATUS = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED'
}