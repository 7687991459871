import axios from "axios";
import { store } from "../store/store";
import { URL } from "./Constants";

const isProd =
  window.location.href.includes("medicalinventory.in") ||
  window.location.href.includes("web.app");

export const baseURL = axios.create({
  baseURL: isProd ? URL.production : URL.production,
});

baseURL.interceptors.request.use(function (config) {
  const { jwt } = store.getState().login;
  config.headers.Authorization = `Bearer ${jwt}`;
  return config;
});

// baseURL.interceptors.response.use(
//     (response) => successHandler(response),
//     (error) => errorHandler(error)
// );

// const successHandler = (response) => {
//     return response;
// };

// const errorHandler = (error) => {
//     if (isValidElement(error?.response?.data?.message)) {
//         return Promise.reject(error.response.data.message);
//     } else if (isValidElement(error?.response?.data)) {
//         return Promise.reject(error.response.data);
//     } else if (isValidElement(error)) {
//         return Promise.reject(error);
//     } else {
//         return Promise.reject(error.message)
//     }
// }

// baseURL.defaults.headers.common["Authorization"] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjU0MzU3ODQ0fQ.kj1yHT2K-4fIq88JMjRpnilZ2mOvqRtPUyoqG__LuOE`
