const Palette = {
    white: '#ffffff',
    lightGrey: '#d3d3d3',
    grey: '#f7f7f7',
    greyUnit: '#F7FAFD',
    greyHeaderText: '#c0c0c0',
    lightTextColorGrey: '#3E4A59',
    textGreyColor: '#555555',
    backgroundGrey: '#F2F2F2',
    dividerGrey: '#EEEEEE',
    docsBlue: '#2475E0',
    pdfRed: '#DA4736',
    lighterGrey: '#e6e6e6',
    black: '#333333',
    suvaGrey: '#888888',
    green: '#63C156',
    whiteSmoke: '#F7F7F7',
    persianRed: '#D12F2F',
    ashColor: '#CCCCCC',
    blue: '#017aff',
    darkRed: '#b52424',
    eclipse: '#3a3a3a',
    lightBlue: '#198ebc',
    lightRedShade: '#F64F4B',
    lightGrayShade: '#F2F2F2',
    darkGrayShade: '#C4C4C4',
    lightBrown: '#F49A38',
    lightYellowShade: '#FFE36E',
    limeColor: '#B0D657',
    ashGrey: '#efeff5',
    lightGold: '#F5A623',
    greyShadow: '#999999',
    crayola: '#323643',
    lightRed: '#ffefef',
    gallery: '#EEEEEE',
    silver: '#BBBBBB',
    aliceBlue: '#FAFBFD',
    outrageousOrange: '#FE561F',
    suluGreen: '#D1EA8C',
    alizarinRed: '#D82927',
    warning: '#F0AD4E',
    carrotOrange: '#F47925',
    mediumGray: '#ADADAD',
    gunmetal: '#242A37',
    shadowGrey: '#000000',
    icon_purple: '#9D7BD2',
    icon_green: '#A3C05F',
    textBlue: '#198EBC',
    ratingYellow: '#F7D823',
    ratingGreen: '#387C2E',
    mongoose: '#AF9E80',
    tacha: '#D4C76D',
    aquablue: '#127DCB',
    lightBlack: '#31394D',
    primaryColor: '#57B864',
    dark_grey: '#212121',
    statusBarColor: '#0E1C24',
    red: '#FF0000',
    tabGrey: '#AAAAAA',
    lightWhite: '#EDEDF3',
    springGreen: '#32FF83',
    transparent: 'transparent',
    underlineColor: '#CACACA',
    yellow: '#FFFF00',
    gondola: '#363636',
    malachite: '#14CB53',
    middleGrey: '#777777',
    veryLightWhite: '#F5F5F5',
    WhiteSmoke: '#EAEAEA',
    darkBlack: '#000',
    viking: '#42ADBB',
    PaleOyster: '#9E8D6E',
    PurpleHeart: '#5827B7',
    neroGrey: '#252525',
    DarkOrange: '#E67D3B',
    BittersweetRed: '#FF665A',
    paleBlue: '#65bcd8',
    chipBlack: '#151515',
    putty: '#d2c572',
    chipBg: '#EFEFEF',
    violet: '#9881CD',
    bigFoodieOrange: '#ED7656',
    mediumBlack: '#4C4C4C',
    progressRed: '#EA332F',
    dullGrey: '#EDEDED',
    //Chart Colors
    robinsEggBlue: '#00CBBB',
    creamCan: '#F2C24C',
    violetRed: '#FB497C',
    atlantis: '#78BE2B',
    cerulean: '#0CA6BE',
    tahitiGold: '#EC7F06',
    pictonBlue: '#4CBBF2',
    rockSpray: '#BE4C0C',
    rotti: '#BEAC3C',
    stromboli: '#36615E',
    pelorous: '#3AC3C3',
    cabaret: '#D84976',
    placeHolderColor: '#AF9E80',
    errorColor: '#BA3431',
    dotColor: '#dcdcdc',
    lightgray: '#e5e5e5',
    cardGrey: '#F9F9F9',
    dimGray: '#5F5F5F',
    gableGreen: '#162832',
    lightTextgrey: '#b9b9b9',
    tornGrey: '#f1f1f1',
    lightBlackTransparent: '#00000011',
    buttonGrey: '#cdcdcd',
    takeawaySelectGreen: '#5bae75',
    modalBackgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderGreen: '#BFCEC7',
    darkGreen: '#5BAE75',
    tinRed: '#C61A1A',
    lightGreyShadow: '#231F20',
    addOnBorder: '#607195',
    itemBorder: '#48C374',
    modifier: '#E5BA76',
    darkCarrotRed: '#F36F64',
    fernGreen: '#5EB379',
    unFocusGray: 'rgba(81,97,115,0.37)',
    checkBoxFillColor: '#5EB379',
    skyBlue: '#198EBC',
    orderTypeOptionGreen: '#5EB379',
    borderShadow: '#606470',
    solitude: '#DFE6ED',
    aliceblueLight: '#F2F5F8',
    linkwater: '#C9D6E3',
    headerGrey: '#EEEEEE',
    lightGreyShade: '#DDDDDD',
    lightGreen: '#58A871',
    dullMustardBg: '#F8F5EE',
    menButtonColorOne: '#41A3C5',
    menButtonColorSecond: '#378DAB',
    pastelGreen: '#CAE8C4',
    greyOffSet: '#F6F9FD',
    tintRed: '#c61a1a',
    darkGrey: '#9C9696',
    aquaGreen: '#5BAE75',
    crustaRed: '#f36f63',
    pureBlack: '#000000',
    shineGray: '#D2D2D2',
    dividerGray: '#BCBCBC',
    liteGreen: '#c1dbc5',
    progressGreen: '#55A46E',
    lighterGreen: '#F1F9F3',
    ghostWhite: '#FDFBF8',
    deepLightGreen: '#9CD9AF',
    veryLightBlack: '#293529',
    navyBlue: '#157CD6'
};
export default Palette;
