import { InternalPurchaseNetwork } from "../../../API/Networks";
import { errorToastr, successToastr } from "../../../Components/Toastr/MyToastr";
import { PURCHASE_TYPES } from "../../../Utils/Constants";
import { jwtExpired } from "../../../Utils/helpers";
import { COMMON_STRINGS } from "../../../Utils/Strings";

const internalPurchaseModel = {
    state: {
        isLoading: false,
        crudLoading: false,
        error: null,
        internalPurchase: null,
        total: 0,
        purchase: null
    },
    reducers: {
        loading: (state, { isLoading }) => {
            return { ...state, isLoading }
        },
        crudLoading: (state, { isLoading }) => {
            return { ...state, crudLoading: isLoading }
        },
        internalPurchase: (state, { internalPurchase, total }) => {
            return { ...state, internalPurchase, total, isLoading: false, error: null, crudLoading: false, }
        },
        purchase: (state, { purchase }) => {
            return { ...state, purchase, isLoading: false, error: null, crudLoading: false }
        },
        error: (state, { error }) => {
            return { ...state, internalPurchase: null, total: 0, isLoading: false, crudLoading: false, error: error.message }
        },
        resetState: (state, { }) => {
            return {
                isLoading: false,
                crudLoading: false,
                error: null,
                internalPurchase: null,
                total: 0,
                purchase: null
            }
        }
    },
    effects: (dispatch) => ({
        getInternalPurchaseByPage: async ({ page, limit, filter }, state) => {
            try {
                let { branchId } = state.login;
                dispatch.internalPurchase.loading({ isLoading: true });
                const response = await InternalPurchaseNetwork.getAllIPByPage(page, limit, branchId, PURCHASE_TYPES.INTERNAL_PURCHASE, filter);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                }
                const { rows: internalPurchase, count: total } = response.data.data;
                dispatch.internalPurchase.internalPurchase({ internalPurchase, total });
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.internalPurchase.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE, error.response.data.message, 2)
            }
        },
        addInternalPurchase: async ({ data }, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                dispatch.internalPurchase.crudLoading({ isLoading: true });
                const response = await InternalPurchaseNetwork.addIP(data);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2);
                }
                dispatch.internalPurchase.crudLoading({ isLoading: false });
                return response?.data?.data;
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.internalPurchase.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE, error.response.data.message, 2)
            }
        },
        updateInternalPurchase: async ({ data }, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                dispatch.internalPurchase.crudLoading({ isLoading: true });
                const response = await InternalPurchaseNetwork.updateIP(data);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                }
                dispatch.internalPurchase.crudLoading({ isLoading: false });
                return successToastr(COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE, COMMON_STRINGS.CRUD_MESSAGE.UPDATE_DATA("TNMSC Purchase"), 2)
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.internalPurchase.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE, error.response.data.message, 2)
            }
        },
        getInternalPurchaseById: async ({ id }, state) => {
            try {
                let { branchId } = state.login;
                dispatch.internalPurchase.loading({ isLoading: true });
                const response = await InternalPurchaseNetwork.getPurchaseById(id, branchId, PURCHASE_TYPES.INTERNAL_PURCHASE);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                dispatch.internalPurchase.purchase({ purchase: response.data.data });
                return response.data.data
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.internalPurchase.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE, error.response.data.message, 2)
            }
        },
        deleteTransaction: async ({ id }, state) => {
            try {
                let { branchId } = state.login;
                dispatch.internalPurchase.crudLoading({ isLoading: true });
                const response = await InternalPurchaseNetwork.deleteTransaction(id, branchId);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                dispatch.internalPurchase.crudLoading({ isLoading: false });
                return successToastr(COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE, COMMON_STRINGS.CRUD_MESSAGE.DELETE_DATA("Transaction"), 2)
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.internalPurchase.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE, error.response.data.message, 2)
            }
        },
        deletePurchase: async ({ id, page, limit }, state) => {
            try {
                let { branchId } = state.login;
                dispatch.internalPurchase.crudLoading({ isLoading: true });
                const response = await InternalPurchaseNetwork.deletePurchaseById(id, branchId);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                dispatch.internalPurchase.getInternalPurchaseByPage({ page, limit });
                return successToastr(COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE, COMMON_STRINGS.CRUD_MESSAGE.DELETE_DATA("TNMSC Purchase"), 2)
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.internalPurchase.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.INTERNAL_PURCHASE, error.response.data.message, 2)
            }
        }
    })
};

export default internalPurchaseModel;