import { useFormik } from "formik";
import React, { useEffect } from "react";
import MyModal from "../../../Components/Modal/MyModal";
import * as Yup from 'yup';
import { Form, Input } from "antd";
import { formItemLayout } from "../../../Utils/Constants";
import MyError from "../../../Components/MyError/MyError";
import { useDispatch, useSelector } from "react-redux";
import { subStoreAdjStockValidation } from "../../../Utils/FormValidations";

type Props = {
    visible: boolean,
    onCancelHandler: () => void,
    editForm?: any
    resetEditForm?: any
    page?: Number
    limit?: Number
}
export default function SubStoreStockAdjModal({ visible, onCancelHandler, editForm, resetEditForm, filter }: any) {
    const dispatch = useDispatch();
    const crudLoading = useSelector((state: any) => state.subStoresProducts.crudLoading);

    const formik = useFormik({
        initialValues: {
            adjStock: ""
        },
        validationSchema: Yup.object(subStoreAdjStockValidation),
        onSubmit: (values) => {
            console.log(values);
        },
    });

    useEffect(() => {
        return function cleanUp() {
            formik.resetForm();
            resetEditForm();
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch.subStoresProducts.adjustSubStoreStock({ id: editForm?.id, adjStock: formik.values.adjStock, filter })
        .then(r => {
            onCancelHandler()
        })
    }

    const renderForm = () => {
        return (
            <Form {...formItemLayout} name="register" scrollToFirstError>
                <Form.Item label="Adj. Stock">
                    <Input
                        min={1}
                        type="number"
                        name="adjStock"
                        value={formik.values.adjStock}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Adj. Stock"
                    />
                    {formik.touched.adjStock && formik.errors.adjStock ? (
                        <MyError error={formik.errors.adjStock} />
                    ) : null}
                </Form.Item>
            </Form>
        )
    }

    return (
        <MyModal
            title="Adj. Stock"
            visible={visible}
            onSubmitHandler={handleSubmit}
            onCancelHandler={onCancelHandler}
            okButtonDisabled={!(formik.isValid && formik.dirty)}
            buttonLoading={crudLoading}>
            {renderForm()}
        </MyModal>
    );
}
