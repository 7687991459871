import { CategoriesNetwork } from "../../../API/Networks";
import { errorToastr, successToastr } from "../../../Components/Toastr/MyToastr";
import { isValidString, jwtExpired } from "../../../Utils/helpers";
import { COMMON_STRINGS } from "../../../Utils/Strings";

const categoriesModel = {
    state: {
        isLoading: false,
        crudLoading: false,
        error: null,
        categories: null,
        total: 0,
        categoriesFilter: null
    },
    reducers: {
        loading: (state, { isLoading }) => {
            return { ...state, isLoading }
        },
        crudLoading: (state, { isLoading }) => {
            return { ...state, crudLoading: isLoading }
        },
        categoriesFilter: (state, { categoriesFilter }) => {
            return { ...state, categoriesFilter: categoriesFilter, isLoading: false, error: null, crudLoading: false }
        },
        categories: (state, { categories, total }) => {
            return { ...state, categories, total, isLoading: false, error: null, crudLoading: false, }
        },
        error: (state, { error }) => {
            return { ...state, categories: null, categoriesFilter: null, total: 0, isLoading: false, crudLoading: false, error: error.message }
        },
        resetState: (state,) => {
            return {
                isLoading: false,
                crudLoading: false,
                error: null,
                categories: null,
                total: 0
            }
        }
    },
    effects: (dispatch) => ({
        getCategoriesByPage: async ({ page, limit, filter }, state) => {
            try {
                let { branchId } = state.login;
                dispatch.categories.loading({ isLoading: true });
                const response = await CategoriesNetwork.getCategoriesByPage(page, limit, branchId, filter);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.CATEGORIES,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                }
                const { rows: categories, count: total } = response.data.data
                dispatch.categories.categories({ categories, total });
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.categories.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.CATEGORIES, error.response.data.message, 2)
            }
        },
        filterCategories: async ({ filter }, state) => {
            try {
                let { branchId } = state.login;
                let response;
                if (isValidString(filter)) {
                    response = await CategoriesNetwork.filterCategories(filter, branchId);
                    if (response.status !== 200) {
                        return errorToastr(
                            COMMON_STRINGS.MODEL_MODULE.CATEGORIES,
                            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                            2)
                    }
                    dispatch.categories.categoriesFilter({ categoriesFilter: response.data.data });
                } else {
                    response = await CategoriesNetwork.getCategoriesByPage(1, 25, branchId);
                    if (response.status !== 200) {
                        return errorToastr(
                            COMMON_STRINGS.MODEL_MODULE.CATEGORIES,
                            COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                            2)
                    }
                    dispatch.categories.categoriesFilter({ categoriesFilter: response.data.data.rows });
                }
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.categories.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.CATEGORIES, error.response.data.message, 2)
            }
        },
        addCategory: async ({ data, page, limit }, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                dispatch.categories.crudLoading({ isLoading: true });
                const response = await CategoriesNetwork.addCategory(data);
                if (response.status != 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.CATEGORIES,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                dispatch.categories.getCategoriesByPage({ page, limit })
                return successToastr(COMMON_STRINGS.MODEL_MODULE.CATEGORIES, COMMON_STRINGS.CRUD_MESSAGE.ADD_DATA("Categories"), 2)
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.categories.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.CATEGORIES, error.response.data.message, 2)
            }
        },
        updateCategory: async ({ data, page, limit }, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                dispatch.categories.crudLoading({ isLoading: true })
                const response = await CategoriesNetwork.updateCategory(data);
                if (response.status != 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.CATEGORIES,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                dispatch.categories.getCategoriesByPage({ page, limit })
                return successToastr(COMMON_STRINGS.MODEL_MODULE.CATEGORIES, COMMON_STRINGS.CRUD_MESSAGE.UPDATE_DATA("Categories"), 2)
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.categories.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.CATEGORIES, error.response.data.message, 2)
            }
        },
        deleteCategory: async ({ data, page, limit }, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                dispatch.categories.crudLoading({ isLoading: true })
                const response = await CategoriesNetwork.deleteCategory(data);
                if (response.status != 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.CATEGORIES,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                dispatch.categories.getCategoriesByPage({ page, limit });
                return successToastr(COMMON_STRINGS.MODEL_MODULE.CATEGORIES, COMMON_STRINGS.CRUD_MESSAGE.DELETE_DATA("Categories"), 2)
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.categories.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.CATEGORIES, error.response.data.message, 2)
            }
        }
    }),
    persist: {
        whitelist: ['categories', 'total'],
        blacklist: ['isLoading', 'error', 'crudLoading']
    },
};

export default categoriesModel;