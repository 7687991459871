import React, { useCallback, useEffect } from "react";
import MyModal from "../../../../Components/Modal/MyModal";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { isValidElement } from "../../../../Utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import ProductMetaForm from "./MicroComponents/ProductMetaForm";
import { productsMetaValidation } from "../../../../Utils/FormValidations";
import moment from "moment";

export default function ProductMetaModal(props: any) {
    const dispatch = useDispatch();

    const { visible, toggleModal, editForm, resetEditForm, page, limit, filter,state } = props;
    const crudLoading = useSelector((state: any) => state.products.crudLoading);


    const formik = useFormik({
        initialValues: {
            codeNo: "",
            name: "",
            batchNo: "",
            qty: "",
            price: "",
            manufacturerDate: null,
            expiryDate: null,
            manufacturerName: "",
            productId: "",
            branchId: "",
            isActive: true,
            productsMetaId: "",
            purchaseTypeId: null
        },
        validationSchema: Yup.object(productsMetaValidation),
        onSubmit: (values) => {
            console.log(values);
        },
    });

    useEffect(() => {
        formik.setValues({
            codeNo: state?.codeNo,
            name: state?.name,
            batchNo: isValidElement(editForm?.batchNo) ? editForm?.batchNo : "",
            qty: isValidElement(editForm?.qty) ? editForm?.qty : "",
            price: isValidElement(editForm?.price) ? editForm?.price : "",
            manufacturerDate: isValidElement(editForm) ? moment(editForm?.manufacturerDate) : "",
            manufacturerName: isValidElement(editForm?.manufacturerName) ? editForm?.manufacturerName : "",
            expiryDate: isValidElement(editForm?.expiryDate) ? moment(editForm?.expiryDate) : "",
            isActive: true,
            productId: state?.id,
            branchId: state?.branchId,
            productsMetaId: isValidElement(editForm?.id) ? editForm?.id : "",
            purchaseTypeId: isValidElement(editForm?.purchaseTypeId) ? editForm?.purchaseTypeId : 1
        })
        return function cleanUp() {
            formik.resetForm();
            resetEditForm();
        }
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        if (isValidElement(editForm)) {
            dispatch.products.updateProductsMeta({ data: formik.values, page, limit, filter })
                .then(r => {
                    toggleModal()
                })
                .catch(error => {
                    toggleModal()
                })
        } else {
        dispatch.products.addProductsMeta({ data: formik.values, page, limit, filter })
                .then(r => {
                    toggleModal()
                })
                .catch(error => {
                    toggleModal()
                })
        }
    }, [formik.values]);

    return (
        <MyModal
            title="Product"
            visible={visible}
            onSubmitHandler={handleSubmit}
            onCancelHandler={toggleModal}
            okButtonDisabled={!(formik.isValid && formik.dirty)}
            buttonLoading={crudLoading}
        >
            <ProductMetaForm formik={formik} />
        </MyModal>
    );
}
