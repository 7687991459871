import { Checkbox, Col, Row, Skeleton } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Appbar from '../../../Components/Appbar/Appbar';
import MyCard from '../../../Components/Card/MyCard';
import MyTable from '../../../Components/Table/MyTable';
import { isValidElement } from '../../../Utils/helpers';
import { UsersPermissionsBreadCrumb } from '../Constants';

export default function UsersPermissions() {
  const dispatch = useDispatch();
  const location = useLocation();
  let data = JSON.parse(JSON.stringify(location.state));

  const { isLoading, userPermissions } = useSelector((state: any) => ({
    isLoading: state.userRoles.isLoading,
    userPermissions: state.userRoles.userPermissions
  }));

  const [checkboxValue, setCheckboxValue] = useState(null)

  useEffect(() => {
    data['usersRoleId'] = data.id
    dispatch.userRoles.getAllPermissions({ data })
  }, []);

  useEffect(() => {
    if (isValidElement(checkboxValue)) {
      dispatch.userRoles.updatePermissions({ data: checkboxValue })
        .then(r => {
          setCheckboxValue(null)
        })
    }
    return function cleanUp() {
      setCheckboxValue(null)
    }
  }, [checkboxValue]);

  const onCheckboxHandler = useCallback(async (e, record, type) => {
    const { checked } = e.target;
    let data = JSON.parse(JSON.stringify(record));
    data[type] = checked;
    setCheckboxValue(data)
  }, [checkboxValue]);


  const column = [
    {
      title: 'Module',
      dataIndex: 'moduleName',
      key: 'moduleName'
    },
    {
      title: 'Create',
      dataIndex: 'post',
      key: 'post',
      align: 'center',
      render: (text, record) => {
        return <Checkbox checked={record.post} onChange={(e) => onCheckboxHandler(e, record, "post")} />
      }
    },
    {
      title: 'Update',
      dataIndex: 'put',
      key: 'put',
      align: 'center',
      render: (text, record) => {
        return <Checkbox checked={record.put} onChange={(e) => onCheckboxHandler(e, record, "put")} />
      }
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      align: 'center',
      render: (text, record) => {
        return <Checkbox checked={record.delete} onChange={(e) => onCheckboxHandler(e, record, "delete")} />
      }
    },
    {
      title: 'View',
      dataIndex: 'get',
      key: 'get',
      align: 'center',
      render: (text, record) => {
        return <Checkbox checked={record.get} onChange={(e) => onCheckboxHandler(e, record, "get")} />
      }
    }
  ];
  return (
    <>
      <MyCard>
        <Appbar
          title="Users Roles"
          showAddButton={false}
          breadcrumbs={UsersPermissionsBreadCrumb}
        />
        <br />
        <Row>
          <Col span={24}>
            <Skeleton loading={isLoading}>
              <MyTable rows={userPermissions} columns={column} />
            </Skeleton>
          </Col>
        </Row>
      </MyCard>
    </>

  )
}
