export const PURCHASE_TYPES = {
	INTERNAL_PURCHASE: 1,
	EXTERNAL_PURCHASE: 2
};

export const PAGING = {
	page: 1,
	limit: 100
}

export const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};

export const fullFormItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 2 },
		md: { span: 2 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 12 },
		md: { span: 12 },

	},
}

export const USER_ROLE = {
	SUPER: 'Super',
	ADMIN: "Admin",
	EDITOR: "Editor",
	VIEWER: "Viewer"
};

export const MODULE = {
	DASHBOARD: 'dashboard',
	MASTER: 'master',
	INVENTORY: "inventory",
	SUPPLY: 'supply',
	SUB_STORE_INVENTORY: 'sub_store_inventory',
	USERS: 'users',
	DEPARTMENT_USERS: 'department_users',
	SUB_STORE: 'sub_store',
	MAIN_STORE: 'main_store',
	SUB_STORE_SUPPLY: 'sub_store_supply',
	USERS_ROLES: 'users-roles',
	USERS_PERMISSION: 'users_permissions',
	SUBSTORE_PRODUCTS: 'substore_products',
	REPORTS: 'reports'
};


export const OPERATION = {
	POST: 'post',
	PUT: 'put',
	DELETE: 'delete',
	GET: 'get'
}


export const TN_LOGO = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bf/TamilNadu_Seal_With_English_Caption.svg/1200px-TamilNadu_Seal_With_English_Caption.svg.png"