import { baseURL } from "../../API/api";
import { UsersNetwork, UsersRolesNetwork } from "../../API/Networks";
import { errorToastr, successToastr } from "../../Components/Toastr/MyToastr";
import { isValidElement, jwtExpired } from "../../Utils/helpers";
import { COMMON_STRINGS } from "../../Utils/Strings";

const usersModel = {
    state: {
        isLoading: false,
        crudLoading: false,
        error: null,
        profile: null,
        user: null,
        total: 0,
        userRoles: null,
        userRolesError: null
    },
    reducers: {
        loading: (state, { isLoading }) => {
            return { ...state, isLoading }
        },
        crudLoading: (state, { isLoading }) => {
            return { ...state, crudLoading: isLoading }
        },
        users: (state, { users, total }) => {
            return { ...state, users, total, isLoading: false, error: null, crudLoading: false, }
        },
        profile: (state, { profile }) => {
            return { ...state, profile, isLoading: false, error: null, crudLoading: false }
        },
        userRoles: (state, { userRoles }) => {
            return { ...state, userRoles, isLoading: false, error: null, crudLoading: false }
        },
        error: (state, { error }) => {
            return { ...state, users: null, total: 0, isLoading: false, crudLoading: false, error: error.message }
        }
    },
    effects: (dispatch) => ({
        getUsersByPage: async ({ page, limit }, state) => {
            try {
                let { branchId, departmentId } = state.login;
                dispatch.users.loading({ isLoading: true });
                const response = await UsersNetwork.getUsersByPage(page, limit, branchId, departmentId);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.USERS,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                }
                const { rows: users, count: total } = response.data.data
                dispatch.users.users({ users, total });
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.users.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.USERS, error.response.data.message, 2)
            }
        },
        addUser: async ({ data, page, limit }, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                dispatch.users.crudLoading({ isLoading: true });
                const response = await UsersNetwork.addUser(data);
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.USERS,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                }
                dispatch.users.getUsersByPage({ page, limit })
                return successToastr(COMMON_STRINGS.MODEL_MODULE.USERS, COMMON_STRINGS.CRUD_MESSAGE.ADD_DATA("User"), 2)

            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.users.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.USERS, error.response.data.message, 2)
            }
        },
        updateUser: async ({ data, page, limit }, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                dispatch.users.crudLoading({ isLoading: true });
                const response = await UsersNetwork.updateUser(data);
                if (response.status !== 200) {
                    dispatch.users.crudLoading({ isLoading: false });
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.USERS,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                }
                dispatch.users.getUsersByPage({ page, limit })
                return successToastr(COMMON_STRINGS.MODEL_MODULE.USERS, COMMON_STRINGS.CRUD_MESSAGE.UPDATE_DATA("User"), 2)
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.users.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.USERS, error.response.data.message, 2)
            }
        },
        getAllUserRole: async (_, state) => {
            try {
                dispatch.users.loading({ isLoading: false });
                const response = await UsersRolesNetwork.getAllUserRoles();
                if (response.status !== 200) {
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.USERS_ROLES,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                }
                dispatch.users.userRoles({ userRoles: response.data.data });
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.users.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.USERS_ROLES, error.response.data.message, 2)
            }
        },
        getUserById: async (data, state) => {
            try {
                dispatch.users.loading({ isLoading: true });
                const response = await UsersNetwork.getUserById(data);
                if (response.status !== 200) {
                    dispatch.users.loading({ isLoading: false });
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.PROFILE,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                }
                dispatch.users.profile({ profile: isValidElement(response.data.data[0]) ? response.data.data[0] : null });
                return response.data.data[0]
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.users.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.PROFILE, error.response.data.message, 2)
            }
        },
        updatePassword: async (data, state) => {
            try {
                dispatch.users.crudLoading({ isLoading: true });
                const response = await UsersNetwork.updatePassword(data);
                if (response.status !== 200) {
                    dispatch.users.crudLoading({ isLoading: false });
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.PROFILE,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                };
                dispatch.users.crudLoading({ isLoading: false });
                return successToastr(COMMON_STRINGS.MODEL_MODULE.PROFILE, COMMON_STRINGS.CRUD_MESSAGE.UPDATE_DATA("Password"), 2)
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.users.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.PROFILE, error.response.data.message, 2)
            }
        },
        updateProfile: async (data, state) => {
            try {
                let { branchId } = state.login;
                data['branchId'] = branchId;
                dispatch.users.crudLoading({ isLoading: true });
                const response = await UsersNetwork.updateUser(data);
                if (response.status !== 200) {
                    dispatch.users.crudLoading({ isLoading: false });
                    return errorToastr(
                        COMMON_STRINGS.MODEL_MODULE.PROFILE,
                        COMMON_STRINGS.API_ERROR.SERVER_FAILED,
                        2)
                }
                dispatch.users.crudLoading({ isLoading: false });
                return successToastr(COMMON_STRINGS.MODEL_MODULE.PROFILE, COMMON_STRINGS.CRUD_MESSAGE.UPDATE_DATA("Profile"), 2)
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.users.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.PROFILE, error.response.data.message, 2)
            }
        },
        resetPassword: async (data, state) => {
            try {
                dispatch.users.loading({ isLoading: true });
                data['configName'] = state.login?.user?.configName
                const response = await baseURL.post("/login/reset-password-link", data);
                if (response.status !== 200) {
                    dispatch.users.loading({ isLoading: false });
                    return errorToastr(COMMON_STRINGS.MODEL_MODULE.USERS, COMMON_STRINGS.API_ERROR.SERVER_FAILED, 2)
                }
                dispatch.users.loading({ isLoading: false });
                successToastr(COMMON_STRINGS.MODEL_MODULE.USERS, "Reset password link has been sent to email", 2);
            } catch (error) {
                jwtExpired(error, dispatch);
                dispatch.users.error({ error });
                return errorToastr(COMMON_STRINGS.MODEL_MODULE.PROFILE, error.response.data.message, 2)
            }
        }
    })
};

export default usersModel;