import React, { useCallback, useEffect } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import MyModal from '../../../../Components/Modal/MyModal'
import DepartmentForm from './MicroComponents/DepartmentForm'
import { departmentValidations } from '../../../../Utils/FormValidations';
import { isValidElement } from '../../../../Utils/helpers';
import { useDispatch } from 'react-redux';

type Props = {
    visible: boolean,
    toggleModal: () => void,
    editForm?: any,
    resetEditForm?: any,
    page?: Number,
    limit?: Number
}

function DepartmentModal(props: Props) {
    const { visible, toggleModal, editForm, resetEditForm, page, limit } = props;
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            name: "",
            departmentNo: "",
            isActive: true,
        },
        validationSchema: Yup.object(departmentValidations),
        onSubmit: (values) => {
            console.log(values);
        },
    });

    useEffect(() => {
        if (isValidElement(editForm)) {
            formik.setValues(editForm);
        }
        return function cleanUp() {
            formik.resetForm();
            resetEditForm();
        }
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        if (isValidElement(editForm)) {
            dispatch.departments.updateDepartment({ data: formik.values, page, limit })
                .then(r => { toggleModal() })
                .catch(err => toggleModal())
        } else {
            dispatch.departments.addDepartment({ data: formik.values, page, limit })
                .then(r => { toggleModal() })
                .catch(err => toggleModal())
        }
    }, [formik.values]);

    return (
        <MyModal
            title="Department"
            visible={visible}
            onSubmitHandler={handleSubmit}
            onCancelHandler={toggleModal}
            okButtonDisabled={!(formik.isValid && formik.dirty)}
        >
            <DepartmentForm formik={formik} />
        </MyModal>
    )
}

export default DepartmentModal