import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Row,
  Skeleton,
  Select,
  Space,
  InputNumber,
  Form,
  Input,
  Switch,
} from "antd";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Appbar from "../../../Components/Appbar/Appbar";
import MyCard from "../../../Components/Card/MyCard";
import MyTable from "../../../Components/Table/MyTable";
import { debounce, findExpiryProduct, lowStock } from "../../../Utils/helpers";
import { PostDays, ProductFilterBreadCrumb } from "./Constants";
import { useDebounceEffect } from "../../../hooks/useDebounce";
import { PAGING } from "../../../Utils/Constants";

const { Option } = Select;

export default function ProductFilter() {
  const dispatch = useDispatch();

  const { isLoading, stockFilter, total } = useSelector((state: any) => ({
    isLoading: state.products.isLoading,
    stockFilter: state.products.stockFilter,
    total: state.products.total,
  }));

  const [page, setPage] = useState(PAGING.page);
  const [limit, setLimit] = useState(PAGING.limit);

  const formik = useFormik({
    initialValues: {
      postDay: null,
      qty: "",
      qtyStatus: false,
      filter: "",
      categoryId: null,
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  useEffect(() => {
    let data = {};
    if (formik.values.postDay) {
      data["postDay"] = formik.values.postDay;
    }
    if (formik.values.filter) {
      data["filter"] = formik.values.filter;
    }
    if (formik.values.qtyStatus) {
      data["qtyStatus"] = formik.values.qtyStatus;
    }

    dispatch.products.stockFilterByPage({ page, limit, data });
  }, [
    page,
    limit,
    formik.values.postDay,
    formik.values.filter,
    formik.values.qtyStatus,
  ]);

  const pageHandler = useCallback(
    (page, pageSize) => {
      setPage(page);
      setLimit(pageSize);
    },
    [page, limit]
  );

  const onSelectHandler = (value, type) => {
    if (page !== PAGING.page) {
      setPage(PAGING.page);
      setLimit(PAGING.limit);
    }
    formik.setFieldValue(type, value);
  };

  const debouncedOnSearchText = useCallback(
    debounce((searchText) => {
      if (page !== PAGING.page) {
        setPage(PAGING.page);
        setLimit(PAGING.limit);
      }
      formik.setFieldValue("filter", searchText);
    }, 800),
    [formik.values.filter]
  );

  const renderFilter = () => {
    return (
      <>
        <Form
          layout="inline"
          className="components-table-demo-control-bar"
          style={{ marginBottom: 16 }}
        >
          <Form.Item label="Search">
            <Input
              placeholder="Search"
              name="filter"
              onChange={(e) => debouncedOnSearchText(e.target.value)}
            />
          </Form.Item>

          {/* <Form.Item label="Hide Stocks">
            <Switch
              defaultChecked={false}
              onChange={(value) => onSelectHandler(value, "qtyStatus")}
            />
          </Form.Item> */}

          <Form.Item label="Post Expiry">
            <Select
              placeholder="Select Post Expiry"
              value={formik.values.postDay}
              allowClear
              onChange={(value) => onSelectHandler(value, "postDay")}
            >
              {PostDays.map((day) => {
                return <Option value={day.value}>{day.label}</Option>;
              })}
            </Select>
          </Form.Item>
        </Form>
      </>
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (text, record) => {
        return (
          <span>
            {record.codeNo} - {record.name}
          </span>
        );
      },
    },
    {
      title: "Batch No.",
      dataIndex: "batchNo",
      key: "batchNo",
      width: 100,
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: 100,
      onCell: (record, rowIndex) => {
        return {
          style: {
            backgroundColor: lowStock(record.qty) && "red",
          },
        };
      },
    },
    {
      title: "Manufacturer Name",
      dataIndex: "manufacturerName",
      key: "manufacturerName",
      width: 150,
    },
    {
      title: "Manufacturer Date",
      dataIndex: "manufacturerDate",
      key: "manufacturerDate",
      width: 200,
      onCell: (record, rowIndex) => {
        return {
          style: {
            backgroundColor: findExpiryProduct(record.expiryDate) && "red",
          },
        };
      },
      render: (text, record) => {
        return (
          <span>{moment(record.manufacturerDate).format("DD-MM-YYYY")}</span>
        );
      },
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      width: 200,
      onCell: (record, rowIndex) => {
        return {
          style: {
            backgroundColor: findExpiryProduct(record.expiryDate) && "red",
          },
        };
      },
      render: (text, record) => {
        return <span>{moment(record.expiryDate).format("DD-MM-YYYY")}</span>;
      },
    },
  ];

  return (
    <>
      <MyCard>
        <Appbar
          title="Product Filter"
          showAddButton={false}
          breadcrumbs={ProductFilterBreadCrumb}
        />
        <Row>
          <Col span={24}>
            {renderFilter()}
            <Skeleton loading={isLoading}>
              <MyTable
                rows={stockFilter}
                columns={columns}
                pagination={{
                  total,
                  current: page,
                  pageSize: limit,
                  onChange: pageHandler,
                }}
              />
            </Skeleton>
          </Col>
        </Row>
      </MyCard>
    </>
  );
}
