import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
//@ts-ignore
import {
  Button,
  Space,
  Select,
  Input,
  DatePicker,
  Row,
  Col,
  Skeleton,
  Typography,
} from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import MyCard from "../../../Components/Card/MyCard";
import Appbar from "../../../Components/Appbar/Appbar";
import { DispatchAddBreadCrumb, SUBSTORE_STATUS } from "../Constants";
import EditableTable from "../../../Components/EditableTable/EditableTable";
import {
  debounce,
  isApproved,
  isValidElement,
  isValidSupplyForm,
  onlyValidKeyValuesFromObj,
} from "../../../Utils/helpers";
import { supplyValidations } from "../../../Utils/FormValidations";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../Container/Navigation/Constants";
import { errorToastr } from "../../../Components/Toastr/MyToastr";
import { COMMON_STRINGS } from "../../../Utils/Strings";
import { branchIdState, userState } from "../../../store/selectors";
import { MODULE } from "../../../Utils/Constants";
import ReactToPrint from "react-to-print";
import SupplyPrint from "./SupplyPrint";
const { Option } = Select;

function SupplyForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let userSelector = userState();
  let branchIdSelector = branchIdState();
  const componentRef = useRef();

  let [userPermissionSelector] =
    isValidElement(userSelector) &&
    userSelector?.users_role?.users_permissions.filter(
      (permission) =>
        permission.moduleName === MODULE.SUPPLY &&
        permission.branchId === branchIdSelector
    );

  const { isLoading, crudLoading } = useSelector((state: any) => ({
    isLoading: state.supply.isLoading,
    crudLoading: state.supply.crudLoading,
  }));

  const { productMetaFilter } = useSelector((state: any) => ({
    productMetaFilter: state.products.productMetaFilter,
  }));

  const { departmentFilter } = useSelector((state: any) => ({
    departmentFilter: state.departments.departmentFilter,
  }));

  const formik = useFormik({
    initialValues: {
      date: moment(),
      indentNo: "",
      departmentId: null,
      supplyItems: [].concat(Object.assign({ itemNameRef: null })),
    },
    validationSchema: Yup.object(supplyValidations),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const [isEdit, setIsEdit] = useState(
    isValidElement(location.state) ? true : false
  );
  const [searchText, setSearchText] = useState("");
  const [searchDepartment, setSearchDepartment] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [productMeta, setProductMeta] = useState(null);
  const [subStorePurchase, setSubStorePurchase] = useState<any>(null);

  useEffect(() => {
    if (searchText.length > 0) {
      dispatch.products
        .getProductsMetaByFilter({ filter: searchText })
        .then((r) => {});
    } else {
      dispatch.products.getProductsMetaByFilter({}).then((r) => {});
    }
    return function cleanup() {
      // dispatch.products.resetProductMeta();
      setIsEdit(false);
      // formik.resetForm();
    };
  }, [searchText]);

  function getSupplyData(data) {
    const { supply, supply_transactions } = data;
    formik.setValues({
      date: moment(supply.date),
      indentNo: supply.indentNo,
      departmentId: supply.departmentId,
      supplyItems:
        Array.isArray(supply_transactions) && supply_transactions.length > 0
          ? supply_transactions.map((transaction) => {
              let data = {};
              let isExits =
                Array.isArray(productMetaFilter) &&
                productMetaFilter.length > 0 &&
                productMetaFilter.find((productMeta, index) => {
                  return productMeta.id === transaction.productsMetumId;
                });

              if (!isExits) {
                dispatch.products.appendProductMetaFilter({
                  id: transaction.products_metum.id,
                  batchNo: transaction.products_metum.batchNo,
                  qty: transaction.products_metum.qty,
                  name: transaction.products_metum.name,
                  codeNo: transaction.products_metum.codeNo,
                });
              }

              data["supplyTransactionId"] = transaction.id;
              data["productId"] = transaction.productId;
              data["productsMetaId"] = transaction.productsMetumId;
              data["batchNo"] = transaction.products_metum.batchNo;
              data["totalQty"] = transaction.totalQty;
              data["availableQty"] = transaction.availableQty;
              data["reqQty"] = transaction.reqQty;
              data["supplyQty"] = transaction.supplyQty;
              data["itemNameRef"] = null;
              data["status"] =
                Array.isArray(supply?.sub_stores_purchases) &&
                supply?.sub_stores_purchases[0].status
                  ? supply?.sub_stores_purchases[0].status
                  : SUBSTORE_STATUS.PENDING;
              return data;
            })
          : [],
    });
    formik.setFieldValue("id", data.id);
  }

  function getSubStorePurchaseBySupplyData(data) {
    if (onlyValidKeyValuesFromObj(data)) {
      setSubStorePurchase({ ...data });
    }
  }
  useEffect(() => {
    let data = !!location.state
      ? JSON.parse(JSON.stringify(location.state))
      : null;
    if (isValidElement(data) && isValidElement(data.data)) {
      dispatch.supply.getSupplyById({ id: data.data.id }).then(getSupplyData);

      dispatch.supply
        .getSubStorePurchaseBySupply(data.data.id)
        .then(getSubStorePurchaseBySupplyData);
    }
  }, [isEdit, crudLoading]);

  useEffect(() => {
    let data = !!location.state
      ? JSON.parse(JSON.stringify(location.state))
      : null;
    if (isValidElement(data) && isValidElement(data.data)) {
      dispatch.supply
        .getSubStorePurchaseBySupply(data.data.id)
        .then(getSubStorePurchaseBySupplyData);
    }
  }, []);

  useEffect(() => {
    if (searchDepartment.length > 0) {
      dispatch.departments.filterDepartment({ filter: searchDepartment });
    } else {
      dispatch.departments.filterDepartment({});
    }
  }, [searchDepartment]);

  useEffect(() => {
    let data = isValidElement(productMeta)
      ? JSON.parse(JSON.stringify(productMeta))
      : null;
    if (!!data) {
      formik.setFieldValue(
        `supplyItems.${data.index}.productId`,
        data.productId
      );
      formik.setFieldValue(`supplyItems.${data.index}.productsMetaId`, data.id);
      formik.setFieldValue(`supplyItems.${data.index}.availableQty`, data.qty);
      formik.setFieldValue(`supplyItems.${data.index}.batchNo`, data.batchNo);
      formik.setFieldValue(`supplyItems.${data.index}.totalQty`, data.totalQty);
    }
  }, [productMeta]);

  useEffect(() => {
    if (
      Array.isArray(formik.values.supplyItems) &&
      formik.values.supplyItems.length > 0
    ) {
      if (
        formik.values.supplyItems[formik.values.supplyItems.length - 1]
          ?.itemNameRef
      ) {
        formik.values.supplyItems[
          formik.values.supplyItems.length - 1
        ].itemNameRef.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.supplyItems.length]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message = "Changes that you made may not be saved.";
    e.returnValue = message;
    return message;
  };

  function checkItemAlreadyExistsInSupplyItems(value) {
    if (
      Array.isArray(formik.values.supplyItems) &&
      formik.values.supplyItems.length > 0
    ) {
      let foundItem = formik.values.supplyItems.find(
        (supplyItem) => supplyItem.productsMetaId === value
      );
      return !!foundItem;
    } else {
      return false;
    }
  }

  const onSelectHandler = (field, value, index) => {
    if (isNaN(index)) {
      formik.setFieldValue(field, value);
      return;
    }

    if (field === "productId") {
      formik.setFieldValue(`supplyItems.${index}.${field}`, value);
      dispatch.products.filterProducts({});
      dispatch.products.getProductMetaByProduct({ id: value, index });
      return;
    }

    if (field === "productsMetaId") {
      let checkItemExists = Array.isArray(formik.values.supplyItems)
        ? checkItemAlreadyExistsInSupplyItems(value)
        : false;
      if (checkItemExists) {
        formik.setFieldValue(
          `supplyItems.${index}.error`,
          "Item Already Exists in the above list"
        );
        return errorToastr(
          COMMON_STRINGS.MODEL_MODULE.SUPPLY,
          COMMON_STRINGS.ERROR.ITEM_EXISTS,
          3
        );
      }
      formik.setFieldValue(`supplyItems.${index}.${field}`, value);
      formik.setFieldValue(`supplyItems.${index}.error`, null);
      dispatch.products
        .getProductMetaById({ id: value, index })
        .then((product) => {
          setProductMeta(product);
          dispatch.products.getProductsMetaByFilter({});
        });
      return;
    }
  };

  const onDateHanlder = (date, dateString, field, index) => {
    if (!index) {
      formik.setFieldValue(field, moment(date));
      return;
    }
    formik.setFieldValue(`supplyItems.${index}.${field}`, date);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      addRow();
    }
  };

  const addRow = () => {
    formik.setFieldValue(
      "supplyItems",
      formik.values.supplyItems.concat(Object.assign({}))
    );
    setProductMeta(null);
  };

  const removeRow = (index) => {
    const originalState = formik.values.supplyItems;
    originalState.splice(index, 1);
    formik.setFieldValue("supplyItems", originalState);
  };

  const debouncedOnSearchText = useCallback(
    debounce((searchText) => {
      setSearchText(searchText);
    }, 800),
    []
  );

  const column = [
    {
      id: "sNo",
      label: "No.",
      key: "id",
      render: (row, index) => {
        return index + 1;
      },
    },
    {
      id: "productsMetaId",
      label: "Products",
      minWidth: 200,
      key: "productsMetaId",
      render: (row, index) => {
        return (
          <>
            {Array.isArray(productMetaFilter) && (
              <Select
                ref={(e) => {
                  row["itemNameRef"] = e;
                }}
                placeholder="Select Item"
                value={formik.values.supplyItems[index].productsMetaId}
                style={{
                  minWidth: "100%",
                  marginTop: !!formik.values.supplyItems[index].error ? 20 : 0,
                  borderColor:
                    isValidSupplyForm(formik, "productsMetaId", index) && "red",
                }}
                onBlur={formik.handleBlur}
                optionFilterProp="children"
                showSearch
                onChange={(value) =>
                  onSelectHandler("productsMetaId", value, index)
                }
                onSearch={(search) => debouncedOnSearchText(search)}
                disabled={isApproved(subStorePurchase?.status)}
              >
                {productMetaFilter.length > 0 &&
                  productMetaFilter.map((productMeta) => {
                    return (
                      <Option key={productMeta.id} value={productMeta.id}>
                        {productMeta.codeNo} - {productMeta.name}
                        <br />
                        Batch - {productMeta.batchNo}
                        <br />
                        Qty - {productMeta.qty}
                      </Option>
                    );
                  })}
              </Select>
            )}
          </>
        );
      },
    },
    {
      id: "batchNo",
      label: "Batch No",
      key: "batchNo",
      minWidth: 100,
      render: (row, index) => {
        return (
          <>
            {/* <Input
                            placeholder="Batch No."
                            name={`supplyItems.${index}.batchNo`}
                            value={formik.values.supplyItems[index].batchNo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            style={{ borderColor: isValidSupplyForm(formik, 'batchNo', index) && "red" }}
                            onKeyPress={handleKeyPress}
                            disabled
                        /> */}
            <Typography.Text
              style={{
                borderColor:
                  isValidSupplyForm(formik, "batchNo", index) && "red",
              }}
            >
              {formik.values.supplyItems[index].batchNo}
            </Typography.Text>
          </>
        );
      },
    },
    {
      id: "totalQty",
      label: "Total Stock",
      key: "totalQty",
      minWidth: 100,
      render: (row, index) => {
        return (
          <>
            {/* <Input
                            placeholder="Total Qty"
                            type="number"
                            name={`supplyItems.${index}.totalQty`}
                            value={formik.values.supplyItems[index].totalQty}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            style={{ borderColor: isValidSupplyForm(formik, 'totalQty', index) && "red" }}
                            onKeyPress={handleKeyPress}
                            disabled
                        /> */}
            <Typography.Text
              style={{
                borderColor:
                  isValidSupplyForm(formik, "totalQty", index) && "red",
              }}
            >
              {formik.values.supplyItems[index].totalQty}
            </Typography.Text>
          </>
        );
      },
    },
    {
      id: "availableQty",
      label: "Stock in batch",
      key: "availableQty",
      minWidth: 100,
      render: (row, index) => {
        return (
          <>
            {/* <Input
                            placeholder="Available Qty"
                            type="number"
                            name={`supplyItems.${index}.availableQty`}
                            value={formik.values.supplyItems[index].availableQty}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            style={{ borderColor: isValidSupplyForm(formik, 'availableQty', index) && "red" }}
                            onKeyPress={handleKeyPress}
                            disabled
                        /> */}
            <Typography.Text
              style={{
                borderColor:
                  isValidSupplyForm(formik, "availableQty", index) && "red",
              }}
            >
              {formik.values.supplyItems[index].availableQty}
            </Typography.Text>
          </>
        );
      },
    },
    {
      id: "requestedQty",
      label: "Req. Qty",
      minWidth: 100,
      key: "requestedQty",
      render: (row, index) => {
        return (
          <>
            {!isEdit &&
            formik.values.supplyItems[index].reqQty >
              formik.values.supplyItems[index].availableQty ? (
              <p style={{ color: "red", marginTop: 5, textAlign: "center" }}>
                over stock
              </p>
            ) : (
              <Input
                placeholder="Requested Qty"
                type="number"
                name={`supplyItems.${index}.reqQty`}
                value={formik.values.supplyItems[index].reqQty}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{
                  borderColor:
                    isValidSupplyForm(formik, "reqQty", index) && "red",
                }}
                onKeyPress={handleKeyPress}
                disabled={isApproved(subStorePurchase?.status)}
              />
            )}
          </>
        );
      },
    },
    {
      id: "supplyQty",
      label: "Supply Qty",
      minWidth: 100,
      key: "supplyQty",
      render: (row, index) => {
        return (
          <>
            {!isEdit &&
            formik.values.supplyItems[index].supplyQty >
              formik.values.supplyItems[index].availableQty ? (
              <p style={{ color: "red", marginTop: 5, textAlign: "center" }}>
                over stock
              </p>
            ) : (
              <Input
                placeholder="Supply Qty"
                type="number"
                name={`supplyItems.${index}.supplyQty`}
                value={formik.values.supplyItems[index].supplyQty}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{
                  borderColor:
                    isValidSupplyForm(formik, "supplyQty", index) && "red",
                }}
                onKeyPress={handleKeyPress}
                disabled={isApproved(subStorePurchase?.status)}
              />
            )}
          </>
        );
      },
    },
    {
      id: "action",
      label: "Action",
      textAlign: "center",
      render: (row, index) => {
        return (
          <>
            <Space size={2}>
              <Button type="primary" onClick={addRow}>
                +
              </Button>
              {row?.status !== SUBSTORE_STATUS.APPROVED && (
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    if (!!row.supplyTransactionId) {
                      const { data } = isValidElement(location.state)
                        ? JSON.parse(JSON.stringify(location.state))
                        : null;
                      dispatch.supply
                        .deleteSupplyTransaction({
                          id: row.supplyTransactionId,
                        })
                        .then((r) => {
                          // dispatch.supply.getSupplyById({ id: data.id })
                        });
                    } else {
                      removeRow(index);
                    }
                  }}
                >
                  x
                </Button>
              )}
            </Space>
          </>
        );
      },
    },
  ];

  const onSaveHandler = (e) => {
    e.preventDefault();
    if (!userPermissionSelector?.post || !userPermissionSelector?.put) {
      return errorToastr(
        COMMON_STRINGS.MODEL_MODULE.SUPPLY,
        "Invalid operation",
        2
      );
    }
    if (!isEdit) {
      dispatch.supply.addSupply({ data: formik.values }).then((r) => {
        return navigate(routes.DISPATCH_ROUTE);
      });
    } else {
      let data: any = formik.values;
      data["id"] = location.state["data"]["id"];
      dispatch.supply
        .updateSupply({ data })
        .then((r) => navigate(routes.DISPATCH_ROUTE));
    }
  };

  const renderPurchaseDetails = () => {
    return (
      <>
        <Row>
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ marginTop: 5 }}>
            <h5>Date</h5>
            <DatePicker
              allowClear={false}
              format="DD-MM-YYYY"
              style={{ width: "100%" }}
              name="date"
              value={formik.values.date}
              onChange={(date, dateString) =>
                onDateHanlder(date, dateString, "date", null)
              }
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ marginTop: 5 }}>
            <h5>Intent No</h5>
            <Input
              type="text"
              name="indentNo"
              value={formik.values.indentNo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Intent No."
            />
          </Col>
          <Col xs={0} sm={0} md={1} lg={1} xl={1} />
          <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ marginTop: 5 }}>
            <h5>Select Department</h5>
            <Select
              showSearch
              optionFilterProp="children"
              value={formik.values.departmentId}
              placeholder="Select Department"
              style={{ width: "100%" }}
              onChange={(value) => {
                let department = departmentFilter.find(
                  (department) => department?.id == value
                );
                if (isValidElement(department)) {
                  setSelectedDepartment(department);
                }
                onSelectHandler("departmentId", value, "null");
              }}
              onSearch={(search) => setSearchDepartment(search)}
            >
              {Array.isArray(departmentFilter) &&
                departmentFilter.length > 0 &&
                departmentFilter.map((department) => {
                  return (
                    <Option key={department?.id} value={department?.id}>
                      {department?.departmentNo} - {department?.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
        </Row>
      </>
    );
  };

  const renderDipatchTable = () => {
    return (
      <Row style={{ marginTop: "5%" }}>
        {isApproved(subStorePurchase?.status) && (
          <p style={{ color: "red" }}>
            Note: The Item has been approved by sub store, cannot be edited
          </p>
        )}
        <Col span={24}>
          {Array.isArray(formik.values.supplyItems) &&
          formik.values.supplyItems.length > 0 ? (
            <Skeleton loading={isLoading}>
              <EditableTable
                rows={formik.values.supplyItems}
                columns={column}
              />
            </Skeleton>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h3 style={{ textAlign: "center" }}>No Items found!</h3>
              <Button onClick={addRow} style={{ width: "20%" }}>
                Add
              </Button>
            </div>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <>
      <MyCard>
        <Appbar
          breadcrumbs={DispatchAddBreadCrumb(isEdit)}
          showAddButton={formik.dirty && formik.isValid}
          onButtonHandler={onSaveHandler}
          title="Save"
        />
        <Skeleton loading={isLoading}>{renderPurchaseDetails()}</Skeleton>
        {renderDipatchTable()}
        <div style={{ display: "none" }}>
          <SupplyPrint
            ref={componentRef}
            rows={{ ...formik.values }}
            department={selectedDepartment}
            productMeta={productMetaFilter}
          />
        </div>
        {/* <ReactToPrint
          trigger={() => <button>Print this!</button>}
          content={() => componentRef.current}
        /> */}
      </MyCard>
    </>
  );
}

export default React.memo(SupplyForm);
