import React from 'react';
import { isValidNumber, isValidString } from '../../Utils/helpers';

type Props = {
    iconName: string,
    size?: number,
    color?: string,
    styles?: any
}

const MyIcon = ({ iconName, size, color, styles }: Props) => {
    return (
        <>
            <i
                className={iconName}
                style={{
                    fontSize: isValidNumber(size) ? size : null,
                    color: isValidString(color) ? color : null,
                    ...styles
                }}
            />
        </>
    );
};

MyIcon.defaultProps = {
    size: 22
}

export default MyIcon;