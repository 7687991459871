import moment from "moment";
import { COMMON_STRINGS } from "./Strings";

export const isValidElement = (data) => {
  return data !== null && data !== undefined;
};
export const isValidNumber = (data) => {
  return isValidString(data) && !isNaN(data);
};

export function isValidArray(data) {
  return Array.isArray(data) && data.length !== 0
}

export const isValidString = (data) => {
  return data !== null && data !== undefined && data !== "";
};

export const isEmptyArray = (data) => {
  return Array.isArray(data) && data.length === 0;
};

export const isNonEmptyString = (data) => {
  return data !== null && data !== undefined && data.toString().length > 0;
};

export const isValidForm = (formik, field, index) => {
  return formik.touched.purchaseItems &&
    formik.touched.purchaseItems[0] &&
    formik.touched.purchaseItems[0][field] &&
    formik.errors.purchaseItems &&
    formik.errors.purchaseItems[index] &&
    isValidString(formik.errors.purchaseItems[index][field])
};

export const isValidSupplyForm = (formik, field, index) => {
  return formik.touched.supplyItems &&
    formik.touched.supplyItems[0] &&
    formik.touched.supplyItems[0][field] &&
    formik.errors.supplyItems &&
    formik.errors.supplyItems[index] &&
    isValidString(formik.errors.supplyItems[index][field])
};



export const jwtExpired = (error, dispatch) => {
  if (isValidElement(error?.response)) {
    if (error?.response?.data?.message === COMMON_STRINGS.API_ERROR.JWT_EXPIRED) {
      dispatch.login.logout({});
    };
  }
  return false
};

export const lowStock = (qty, thresholdStock, type = "unit") => {
  if (type === "percent") {
    return (qty / 100) <= thresholdStock
  }
  if (!!thresholdStock) {
    return qty <= thresholdStock
  };
  return qty <= 0;
}

export const findExpiryProduct = (expiryDate, thresholdDay) => {
  if (!!thresholdDay) {
    return moment().subtract(thresholdDay, 'days').startOf("month") >= moment(expiryDate)
  }
  return moment().startOf("month") >= moment(expiryDate)
};

export const expiryWithColor = (expiryDate) => {
  if (moment().startOf("month") >= moment(expiryDate)) {
    return 'red'
  }
};

export const onlyValidKeyValuesFromObj = (data) => {
  let keys = Object.keys(data);
  if (keys.length > 0) {
    let obj = {}
    for (let key of keys) {
      if (isValidElement(data[key]) || isValidString(data[key]) || !!data[key]) {
        obj[key] = data[key]
      }
    };
    return obj
  }
  return {}
};

export const isPending = (data) => {
  return data?.toLowerCase() === "pending"
};

export const isApproved = (data) => {
  return data?.toLowerCase() === "approved"
};

export function debounce(func, wait) {
  let timeout;
  return function(...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}